 import React, { useEffect, useState, ReactNode, useRef } from 'react';
import './style.css';
import { ChevronDown } from 'react-feather';
import PhoneInput from 'react-phone-input-2';
import axios from 'axios';
import * as Icon from 'react-feather';
import { Select, TimePicker } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import { AutoComplete } from 'primereact/autocomplete';
import 'react-phone-input-2/lib/style.css';
//import countryRegionData from 'country-region-data/dist/data-umd';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import countries from 'world-countries/countries.json';
import { GoogleMap, useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import { Modal, Button } from 'react-bootstrap';
import en from '../../../en/en';
import dayjs, { Dayjs } from 'dayjs';
import { PhoneInputComponentProps } from '../../../core/models/interface';
import { RootState } from '../../../core/data/redux/store';
import { useSelector } from 'react-redux';
 
interface InputWithIconProps {
    maxLength?: number;
    placeholder?: string;
    label?: string;
    errorMessage?: string;
    onChange?: (value: string) => void;
    icon?: ReactNode; // Accept any React component as an icon
}
interface MapWithIconProps {
    onLocationSelect?: (lat: number, lng: number) => void;
    icon?: ReactNode; // Accept any React component as an icon
}

interface MapWithIconeditProps {
    onLocationSelect?: (lat: number, lng: number) => void;
    icon?: ReactNode; // Accept any React component as an icon
    savedLat?: number; // Latitude from DB
    savedLng?: number; // Longitude from DB
    savedAddress?: string; // Address from DB
}

interface MapWithIconSearchProps {
    /* maxLength?: number;
    placeholder?: string;
    label?: string;
    errorMessage?: string;
    onChange?: (value: string) => void; */
    icon?: ReactNode; // Accept any React component as an icon
    incomingLocation?: { lat: number, lng: number } | undefined;
    incomingLocationPlaceName?: string | undefined;
    onLocationSelect?: (location: { lat: number, lng: number }) => void;
}
interface GoogleMapModalProps {
    show: boolean;
    onHide: () => void;
    onLocationSelect: (location: { lat: number, lng: number }) => void;
}

interface TimePickerComponentPropsEdit {
    use12Hours: boolean;
    label: string;
    value: string;
    onChange: (time: string) => void;
}

interface TKN_InputPlaceholderProps {
    placeholderText: string | undefined;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    maxLength?: number;
    value?: string;
}

interface TKN_EmailInputProps {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value?: string;
    error?: string;
    placeholder?: string;
    maxLength?: number;
}

type TextInputProps = {
    maxLength?: number;
    placeholder?: string;
    label?: any;
    errorMessage?: string;
    //onChange?: (isEmpty: boolean) => void;
    onChange?: (value: string) => void;
};
type TextInputrequiredProps = {
    maxLength?: number;
    placeholder?: string;
    label?: string;
    errorMessage?: string;
    onChange?: (value: string) => void;
    value?: string;
};

type DropdownInputvalueProps = {
    placeholderText: string;
    options: Option[];
    onSelect?: (selectedItem: Option) => void;
    categoryDropdownError?: any;
    descriptionError?: any;
    handleSubmit?: () => void;
    isDisabled?: boolean;
};
type DropdownInputvalueeditProps = {
    placeholderText: string;
    options: Option[];
    onSelect?: (selectedItem: Option) => void;
    categoryDropdownError?: any;
    descriptionError?: any;
    handleSubmit?: () => void;
    isDisabled?: boolean;
    selectedValue?: string;
};

type DropdownProps = {
    placeholderText: string;
    options: Option[];
    onSelect?: (selectedItem: Option) => void;
    categoryDropdownError?: any;
    descriptionError?: any;
    handleSubmit?: () => void;
    isDisabled?: boolean;
    handleOptionSelect: (option: Option) => void;
    value: any;
};

type DropdownInputProps = {
    placeholderText: string;
    options?: string[] | any;
    //options: { label: string, value: number }[];
    value?: string;
    onSelect?: (selectedItem: string) => void | undefined;
    onChange?: (value: string) => void;
    categoryDropdownError?: any;
    dropdownError?: any;
    handleSubmit?: () => void;
    required?: boolean;
};

interface TKN_PasswordInputProps {
    label: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    serverError?: any;
    required?: boolean;
}

interface TextareaPlaceholderProps {
    placeholderText: string | undefined;
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    maxLength?: any;
    value?: string;
    descriptionInputError?: any;
    required?: boolean;
}

interface OTPInputProps {
    onChange: (otp: string) => void;
}

type TKN_DateOfBirthInputProps = {
    onChange: (date: any) => void;
};

interface TimePickerComponentProps {
    use12Hours?: boolean;
    label?: string;
    value?: string;
    onChange?: (time: string) => void;
    required?: boolean;
}

interface DayPickerComponentProps {
    label: string;
}
/* =========================================== */
interface TKN_PhoneInputComponentProps {
    onChange: (phonenumber: string, dialCode: string, countryname: string, countryInfo: CountryDetailsType | null) => void;
    required?: boolean;
    error?: string;
    value?: string;
}
type CountryDetailsType = {
    cca2: string;
    currency: string[];
    callingCode: string[];
    region: string;
    subregion: string;
    flag: string;
    name: string;
};

/* type TKN_PhoneInputComponentProps = {
   onChange: (phone: string, dialCode: string, localNum: string, countryDetails: any) => void;
   required?: boolean;
 /* type TKN_PhoneInputComponentProps = {
    onChange: (phone: string, dialCode: string, localNum: string, countryDetails: any) => void;
    required?: boolean;
};  */
/* =========================================== */

interface SearchBarProps {
    placeholderText: string;
    icon: keyof typeof Icon;
    style?: React.CSSProperties;
    className?: string;
    iconclassName?: string;
    value?: string;
    onChangeText?: ((text: string) => void) | undefined;
}

type Option = {
    label: string;
    value: number;
};


interface EmailInputProps {
    label?: string;
    placeholder?: string;
    maxLength?: number;
    errorMessages?: {
        charLimit?: string;
        invalidEmail?: string;
    };
    validateEmail?: boolean;
    onChange?: (value: string) => void;
    required?: boolean;
    value?: string;
}
type DropdownInputEditProps = {
    placeholderText: string;
    options: string[] | any;
    onSelect?: (selectedItem: string) => void | undefined;
    categoryDropdownError?: any;
    dropdownError?: any;
    handleSubmit?: () => void;
    value?: any;
    initialValue?: any;
    onChange?: (value: any) => void;
};

interface TKN_InputPlaceholderEditProps {
    placeholderText: string;
    value?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    readOnly?: boolean;
    className?: any;
    maxLength?: number;
    style?: React.CSSProperties;
}


interface TKN_PhoneInputComponentEditProps {
    onChange: (phonenumber: string, dialCode: string, countryname: string, countryInfo: CountryDetailsType | null) => void;
    required?: boolean;
    error?: string;
    inputvalue?: string;
    readOnly?: boolean;
}


const TKN_TimePickerComponentEdit: React.FC<TimePickerComponentProps> = ({ use12Hours, label, value, onChange, required }) => {
    const timeValue = value ? dayjs(value, use12Hours ? 'h:mm A' : 'HH:mm') : null;

    const handleChange = (date: Dayjs | null, dateString: string | string[]) => {
        if (onChange) {
            if (typeof dateString === 'string') {
                onChange(dateString);
            } else {
                console.error('Unexpected dateString format');
            }
        }
    };

    return (
        <div className="form-group">
             <label className="col-form-label">
                {label}
                {required && <span style={{ color: 'red' }}>*</span>}
            </label>
            <div className="form-icon">
                <TimePicker
                    className="form-control timepicker"
                    placeholder="Select Time"
                    suffixIcon={<ClockCircleOutlined />}
                    use12Hours={use12Hours}
                    format={use12Hours ? 'h:mm A' : 'HH:mm'}
                    value={timeValue}
                    onChange={handleChange}
                />
                <span className="cus-icon">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="react-feather-custom"
                    >
                        <circle cx="12" cy="12" r="10"></circle>
                        <polyline points="12 6 12 12 16 14"></polyline>
                    </svg>
                </span>
            </div>
        </div>
    );
};

const TKN_EmailInputwithcheck: React.FC<EmailInputProps> = ({
    label = "Email",
    placeholder = "Enter your email",
    maxLength = 40,
    errorMessages = {
        charLimit: "Character limit exceeded",
        invalidEmail: "Invalid email format",
    },
    validateEmail = true,
    required,
    onChange,
    value,
}) => {
    const [inputValue, setInputValue] = useState('');
    const [emailError, setEmailError] = useState('');

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (value.length >= maxLength) {
            setEmailError(en.CHAR_LIMIT);
        }
        else {
            setEmailError('');
        }
        if (onChange) {
            onChange(value);
        }
        setInputValue(value);
    };

    return (
        <div className="form-group">
            {label && <label className="col-form-label">{label}
                {required && <span className="text-danger"> *</span>}</label>}
            <input
                type="email"
                className="form-control"
                placeholder={placeholder}
                maxLength={maxLength}
                value={value ? value : inputValue}
                onChange={handleInputChange}
            />
            {emailError && <div className="error-message" style={{ color: 'red' }}>{emailError}</div>}
        </div>
    );
};



const TKN_InputPlaceholder = (props: TKN_InputPlaceholderProps) => {
    return (
        <div>
            <div>
                <input
                    type="text"
                    className="form-control"
                    placeholder={props.placeholderText}
                    onChange={props.onChange}
                    value={props.value}
                    maxLength={props.maxLength}
                />
            </div>
        </div>
    );
};


/* const TKN_InputPlaceholderIcon: React.FC<InputWithIconProps> = (props) => {

    const { placeholderText, IconComponent } = props;

    return (
        <div className="subscribe-form">
            <input
                type="email"
                className="form-control"
                placeholder={placeholderText}
            />
            <button type="submit" className="btn footer-btn">
                {IconComponent}
            </button>
        </div>
    );
}; */


const mapContainerStyle = {
    width: '100%',
    height: '400px',
};

const defaultCenter = {
    lat: 20.5937,
    lng: 78.9629,
};

const TKN_InputPlaceholderIcon: React.FC<InputWithIconProps> = ({
    maxLength = 40,
    placeholder = '',
    label = '',
    errorMessage = 'Character limit exceeded',
    onChange,
    icon,
}) => {
    const [inputValue, setInputValue] = useState('');
    const [inputError, setInputError] = useState('');
    const [showMapModal, setShowMapModal] = useState(false); // Control modal visibility
    const [selectedLocation, setSelectedLocation] = useState(defaultCenter); // Store selected location
    const [placeName, setPlaceName] = useState(''); // Store place name
    const [scriptLoaded, setScriptLoaded] = useState(false); // Track script loading status

    const mapRef = useRef<google.maps.Map | null>(null);
    const markerRef = useRef<google.maps.Marker | null>(null);
    const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (value.length > maxLength) {
            setInputError(errorMessage);
        } else {
            setInputError('');
            setInputValue(value);
        }
        if (onChange) {
            onChange(value);
        }
    };

    const handleIconClick = () => {
        setShowMapModal(true); // Open modal on icon click
    };

    const handleMapClick = (event: google.maps.MapMouseEvent) => {
        if (event.latLng) {
            const lat = event.latLng.lat();
            const lng = event.latLng.lng();
            setSelectedLocation({ lat, lng }); // Set selected lat/lng

            // Update Marker position
            if (markerRef.current) {
                markerRef.current.setPosition({ lat, lng });
            } else {
                // Create a new Marker if it doesn't exist yet
                markerRef.current = new google.maps.Marker({
                    position: { lat, lng },
                    map: mapRef.current!,
                });
            }
        }
    };

    const handlePlaceSelected = () => {
        const place = autocompleteRef.current?.getPlace();
        if (place?.geometry?.location) {
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            setSelectedLocation({ lat, lng }); // Center map to selected place
            setPlaceName(place.formatted_address || place.name || '');
            setInputValue(place.formatted_address || place.name || ''); // Update input with place name
            // Update Marker position
            if (markerRef.current) {
                markerRef.current.setPosition({ lat, lng });
            }
        }
    };

    const handleModalClose = () => {
        setShowMapModal(false); // Close modal
    };

    const handleSaveLocation = () => {
        console.log('Selected Location:', selectedLocation);
        console.log('Place Name:', placeName);
        setShowMapModal(false); // Close modal after saving location
    };

    useEffect(() => {
        const existingScript = document.getElementById('google-maps-script');

        if (!existingScript) {
            const script = document.createElement('script');
            script.src =
                // 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCrmKst8GuS8VI8_CKKBFl8LCL_qkthAJ8&libraries=places&v=beta';
                ' https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(inputValue)}&key=AIzaSyAf3LRTL4qaj_40KPE5qCCHU_Aet0npVyA';
            script.async = true;
            script.defer = true;
            script.id = 'google-maps-script';
            document.body.appendChild(script);
            script.onload = () => {
                console.log('Google Maps API loaded successfully');
                setScriptLoaded(true); // Script loaded, now we can use Autocomplete
            };
        } else {
            setScriptLoaded(true); // Script was already loaded
        }

        // Cleanup the marker when the component unmounts
        return () => {
            markerRef.current?.setMap(null);
        };
    }, []);

    return (
        <>
            <div className="form-group">
                {label && <label className="col-form-label">{label}</label>}
                <div className="group-img">
                    <input
                        type="text"
                        className="form-control"
                        placeholder={placeholder}
                        maxLength={maxLength + 1}
                        value={inputValue}
                        onChange={handleInputChange}
                        style={{ flex: 1 }}
                    />
                    {icon && (
                        <span onClick={handleIconClick} className="react-feather-custom">
                            {icon}
                        </span>
                    )}
                </div>
                {inputError && (
                    <div className="error-message" style={{ color: 'red' }}>
                        {inputError}
                    </div>
                )}
            </div>

            {/* Modal for Google Map */}
            <Modal show={showMapModal} onHide={handleModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Select Location</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {scriptLoaded ? (
                        <>
                            <Autocomplete
                                onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                                onPlaceChanged={handlePlaceSelected}
                            >
                                <input
                                    type="text"
                                    placeholder="Search for a place"
                                    className="form-control mb-3"
                                />
                            </Autocomplete>


                            <GoogleMap
                                mapContainerStyle={mapContainerStyle}
                                center={selectedLocation}
                                zoom={10}
                                onClick={handleMapClick} // Capture click event to set marker
                                onLoad={(map) => {
                                    mapRef.current = map;
                                    // Return void to satisfy the expected return type
                                }}
                            />
                        </>
                    ) : (
                        <div>Loading map...</div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSaveLocation}>
                        Save Location
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

//=======================================================================//


const TKN_TextareaPlaceholdernew: React.FC<TextareaPlaceholderProps> = ({ placeholderText, value, onChange }) => {
    const maxLength = 200;
    const [inputValue, setInputValue] = useState('');
    const [textareaerror, setTextareaError] = useState('');

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = e.target;
        if (value.length > maxLength) {
            setTextareaError(en.CHAR_LIMIT);
        } else {
            setTextareaError('');
            if (onChange) {
                onChange(e);
            }
            setInputValue(value);
        }
    };
    return (
        <div className="form-group">
            <textarea
                className="form-control"
                placeholder={placeholderText}
                rows={4}
                maxLength={maxLength + 1}
                value={value}
                onChange={handleInputChange}
            />
            {textareaerror && <div className="error-message" style={{ color: 'red' }}>{textareaerror}</div>}
        </div>
    );
};

const TKN_TextareaPlaceholder: React.FC<TextareaPlaceholderProps> = ({ placeholderText, value, descriptionInputError, onChange, maxLength, required }) => {

    return (
        <div className="form-group">
            <textarea
                className="form-control"
                placeholder={`${placeholderText} ${required ? '*' : ''}`}
                rows={4}
                maxLength={maxLength}
                value={value}
                onChange={onChange}
            />
            {descriptionInputError && <div className="error-message" style={{ color: 'red' }}>{descriptionInputError}</div>}
        </div>
    );
};


const TKN_DropdownInput: React.FC<DropdownInputProps> = ({ placeholderText, options, value, onChange, onSelect, categoryDropdownError, required }) => {
    const [selectedOption, setSelectedOption] = useState<string | undefined>(undefined);
    const [isOpen, setIsOpen] = useState(false);

    const handleOptionSelect = (option: any) => {
        setSelectedOption(option);
        setIsOpen(false); // Close dropdown after selecting an option
        if (onSelect) {
            onSelect(option);
        }
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        setSelectedOption(value);
    }, [value]);

    return (
        <div className="form-group position-relative">
            <div className="input-with-icon">
                <input
                    type="text"
                    className="form-control"
                    placeholder={`${placeholderText} ${required ? '*' : ''}`}
                    value={selectedOption || ''}
                    readOnly
                    onClick={toggleDropdown}
                />
                <ChevronDown className="dropdown-icon" onClick={toggleDropdown} />
            </div>
            <div className={`dropdown-options ${isOpen ? 'open' : ''}`}>
                {options.map((option: any, index: any) => (
                    <div
                        key={index}
                        className="dropdown-option"
                        onClick={() => handleOptionSelect(option)}
                    >
                        {option}
                    </div>
                ))}
            </div>
            {categoryDropdownError && (
                <div className="error-message" style={{ color: 'red' }}>
                    {categoryDropdownError}
                </div>
            )}
        </div>
    );
};
/* -------------------------------------- */

const TKN_DropdownInputvalue: React.FC<DropdownInputvalueProps> = ({
    placeholderText,
    options,
    onSelect,
    descriptionError,
    handleSubmit
}) => {
    const [selectedOption, setSelectedOption] = useState<Option | undefined>(undefined);
    const [isOpen, setIsOpen] = useState(false);

    const handleOptionSelect = (option: Option) => {
        setSelectedOption(option);
        setIsOpen(false);
        if (onSelect) {
            onSelect(option);
        }
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="form-group position-relative">
            <div className="input-with-icon">
                <input
                    type="text"
                    className="form-control"
                    placeholder={placeholderText}
                    value={selectedOption ? selectedOption.label : ''}
                    readOnly
                    onClick={toggleDropdown}
                />
                <ChevronDown className="dropdown-icon" onClick={toggleDropdown} />
            </div>
            <div className={`dropdown-options ${isOpen ? 'open' : ''}`}>
                {options.map((option: Option, index: number) => (
                    <div
                        key={option.value}
                        className="dropdown-option"
                        onClick={() => handleOptionSelect(option)}
                    >
                        {option.label}
                    </div>
                ))}
            </div>
        </div>
    );
};
/* -------------------------------------- */
const TKN_DropdownInputvalueedit: React.FC<DropdownInputvalueeditProps> = ({
    placeholderText,
    options,
    onSelect,
    selectedValue,
    descriptionError,
    handleSubmit
}) => {
    const [selectedOption, setSelectedOption] = useState<Option | undefined>(undefined);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (selectedValue) {
            const selected = options.find(option => option.label === selectedValue);
            if (selected) {
                setSelectedOption(selected);
            }
        }
    }, [selectedValue, options]);

    const handleOptionSelect = (option: Option) => {
        setSelectedOption(option);
        setIsOpen(false);
        if (onSelect) {
            onSelect(option);
        }
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="form-group position-relative">
            <div className="input-with-icon">
                <input
                    type="text"
                    className="form-control"
                    placeholder={placeholderText}
                    value={selectedOption ? selectedOption.label : ''}
                    readOnly
                    onClick={toggleDropdown}
                />
                <ChevronDown className="dropdown-icon" onClick={toggleDropdown} />
            </div>
            <div className={`dropdown-options ${isOpen ? 'open' : ''}`}>
                {options.map((option: Option, index: number) => (
                    <div
                        key={option.value}
                        className="dropdown-option"
                        onClick={() => handleOptionSelect(option)}
                    >
                        {option.label}
                    </div>
                ))}
            </div>
        </div>
    );
};
/*--------------------------------------------------*/


const TKN_BlankInput: React.FC = () => {
    return (
        <div className="col-md-12">
            <div className="form-group">
                <input
                    type="text"
                    className="form-control"
                />
            </div>
        </div>
    );
};

/* -------------------------------------- */
const TKN_TextInput: React.FC<TextInputProps> = ({ maxLength = 40, placeholder = '', label = '', errorMessage = 'Character limit exceeded', onChange }) => {
    const [inputValue, setInputValue] = useState('');
    const [inputError, setInputError] = useState('');

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (value.length > maxLength) {
            setInputError(errorMessage);
        } else {
            setInputError('');
            setInputValue(value);
        }
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <div className="form-group">
            {label && <label className="col-form-label">{label}</label>}
            <input
                type="text"
                className="form-control"
                placeholder={placeholder}
                maxLength={maxLength + 1}
                value={inputValue}
                onChange={handleInputChange}
            />
            {inputError && <div className="error-message" style={{ color: 'red' }}>{inputError}</div>}
        </div>
    );
};

const TKN_TextInputreqired: React.FC<TextInputrequiredProps> = ({ maxLength = 40, placeholder = '', label = '', errorMessage = en.CHAR_LIMIT, onChange }) => {
    const [inputValue, setInputValue] = useState('');
    const [inputError, setInputError] = useState('');

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (value.length > maxLength) {
            setInputError(errorMessage);
        } else {
            setInputError('');
            setInputValue(value);
        }
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <div className="form-group">
            {label && <label className="col-form-label">{label}
                <span className="text-danger">*</span>   </label>}
            <input
                type="text"
                className="form-control"
                placeholder={placeholder}
                maxLength={maxLength + 1}
                value={inputValue}
                onChange={handleInputChange}
            />
            {inputError && <div className="error-message" style={{ color: 'red' }}>{inputError}</div>}
        </div>
    );
};

const TKN_EmailInput: React.FC<TKN_EmailInputProps> = ({ onChange, value, maxLength, error, placeholder = "johndoe@example.com" }) => {
    return (
        <div className="form-group">
            <label className="col-form-label">Email</label>
            <input
                type="email"
                className="form-control"
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                maxLength={maxLength}
            />
            {/* {error && <div className="error-message" style={{ color: 'red' }}>{error}</div>} */}
            {value && error && <div className="error-message" style={{ color: 'red' }}>{error}</div>}
        </div>
    );
};

const TKN_PasswordInput: React.FC<TKN_PasswordInputProps> = ({ label, value, onChange, serverError, required }) => {
    const maxLength = 16;
    const minLength = 8;
    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    return (
        <div className="form-group">
            <label className="col-form-label d-block">
                {label} {required && <span style={{ color: 'red' }}>*</span>}
            </label>
            <div className="pass-group">
                <input
                    type={passwordVisible ? 'text' : 'password'}
                    className="form-control pass-input"
                    placeholder="*************"
                    maxLength={maxLength}
                    value={value}
                    onChange={onChange}
                />
                <span
                    className="toggle-password"
                    onClick={togglePasswordVisibility}
                >
                    {passwordVisible ? <Icon.Eye className="react-feather-custom" /> : <Icon.EyeOff className="react-feather-custom" />}
                </span>
            </div>
            {serverError && <div className="error-message" style={{ color: 'red' }}>{serverError}</div>}
        </div>
    );
};

const TKN_OTPInput: React.FC<OTPInputProps> = ({ onChange }) => {
    const length = 6;
    const [otp, setOtp] = useState<string[]>(Array(length).fill(''));

    const handleChange = (value: string, index: number) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
        onChange(newOtp.join(''));

        // Move focus to the next input field
        if (value && index < length - 1) {
            const nextInput = document.getElementById(`otp-input-${index + 1}`);
            if (nextInput) {
                nextInput.focus();
            }
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            const prevInput = document.getElementById(`otp-input-${index - 1}`);
            if (prevInput) {
                prevInput.focus();
            }
        }
    };

    return (
        <div className="col-md-12">
            <div className="otp-input-container">
                {otp.map((digit, index) => (
                    <input
                        key={index}
                        id={`otp-input-${index}`}
                        type="text"
                        maxLength={1}
                        value={digit}
                        onChange={(e) => handleChange(e.target.value, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        className="otp-input"
                    />
                ))}
            </div>
        </div>
    );
};

// const OTPInput = () => {

//     return (
//         <InputOtp length={6} ></InputOtp>
//     );
// };

const LocationSearchBox = () => {
    const [inputValue, setInputValue] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const handleInputChange = (event: any) => {
        setInputValue(event.target.value);
    };

    const handleSetLocation = async () => {
        try {
            const response = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(inputValue)}&key=AIzaSyAf3LRTL4qaj_40KPE5qCCHU_Aet0npVyA`
            );

            if (response.data.results.length > 0) {
                const { formatted_address, geometry } = response.data.results[0];
                console.log('Location set to:', formatted_address);
                console.log('Latitude:', geometry.location.lat, 'Longitude:', geometry.location.lng);
                setInputValue(formatted_address);
            } else {
                console.log('No results found');
            }
        } catch (error) {
            console.error('Error fetching location:', error);
        }
    };

    return (
        <div style={{ width: '100%', display: 'flex', gap: 3 }}>
            <input
                id="pac-input"
                type="text"
                placeholder="Search Box"
                value={inputValue}
                onChange={handleInputChange}
                autoComplete='string'
            />
            <button id="set-location-btn" onClick={handleSetLocation} className='btn btn-primary' style={{ width: 155 }}>
                Set Location
            </button>
        </div>
    );
};

type Prediction = {
    description: string;
    id: string;
};

const TKN_LocationSearchBox = () => {
    const [inputValue, setInputValue] = useState<string>('');
    const [predictions, setPredictions] = useState<Prediction[]>([]);
    const [idCounter, setIdCounter] = useState<number>(0);

    const generateUniqueId = () => {
        setIdCounter((prevCounter) => prevCounter + 1);
        return `prediction-${idCounter}`;
    };

    const handleInputChange = async (e: { query: string }) => {
        const value = e.query;
        setInputValue(value);

        if (value.length >= 2) {
            try {
                const response = await axios.get(
                    `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(value)}&key=AIzaSyAf3LRTL4qaj_40KPE5qCCHU_Aet0npVyA`
                );

                console.log('RESPONSE', response.data);

                if (response.data.results.length > 0) {
                    const formattedAddresses = response.data.results.map((result: any) => ({
                        description: result.formatted_address,
                        id: generateUniqueId(),
                    }));
                    setPredictions(formattedAddresses);
                } else {
                    setPredictions([]);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        } else {
            setPredictions([]);
        }
    };

    const handleSetLocation = async () => {
        try {
            const response = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(inputValue)}&key=AIzaSyAf3LRTL4qaj_40KPE5qCCHU_Aet0npVyA`
            );

            if (response.data.results.length > 0) {
                const { formatted_address, geometry } = response.data.results[0];
                console.log('Location set to:', formatted_address);
                console.log('Latitude:', geometry.location.lat, 'Longitude:', geometry.location.lng);
                setInputValue(formatted_address);
                setPredictions([]);
            } else {
                console.log('No results found');
            }
        } catch (error) {
            console.error('Error fetching location:', error);
        }
    };

    const handleSelectPrediction = (prediction: Prediction) => {
        setInputValue(prediction.description);
        setPredictions([]);
    };

    return (
        <React.Fragment>
            <div style={{ width: '100%', display: 'flex', gap: 3 }}>
                <div style={{ width: '100%', flexWrap: 'wrap' }} >
                    <AutoComplete
                        className="autocomplete-container"
                        placeholder='Search Box'
                        value={inputValue}
                        suggestions={predictions}
                        completeMethod={handleInputChange}
                        field="description"
                        onChange={(e) => setInputValue(e.value)}
                        onSelect={(e) => handleSelectPrediction(e.value)}
                        style={{ borderColor: 'white' }}
                        loadingIcon={'false'}
                    />
                </div>
                <button id="set-location-btn" onClick={handleSetLocation} className='btn btn-primary' style={{ width: 155 }}>
                    Set Location
                </button>
            </div>
        </React.Fragment>
    );
};

const TKN_PhoneInputComponent: React.FC<TKN_PhoneInputComponentProps> = ({ value, onChange, required, error }) => {
    const [phone, setPhone] = useState(value || ''); // Use value prop as initial state
    const [countryCode, setCountryCode] = useState('');
    const [localNumber, setLocalNumber] = useState('');
    const [countryName, setCountryName] = useState('');
    const [countryDetails, setCountryDetails] = useState<CountryDetailsType | null>(null);

    const handlePhoneChange = (value: string, country: any) => {
        const dialCode = country.dialCode;
        const formattedDialCode = `+${dialCode}`;
        const CountryCode = country.countryCode.toUpperCase();

        const localNum = value.replace(`+${dialCode}`, '').replace(new RegExp(`^${dialCode}`), '');
        const CountryDetails = countries.find(country => country.cca2 === CountryCode);

        const phoneNumber = `+${value}`; // Use value directly

        if (CountryDetails) {
            const transformedCountryDetails = {
                cca2: CountryDetails.cca2,
                currency: Object.keys(CountryDetails.currencies),
                callingCode: dialCode,
                region: CountryDetails.region,
                subregion: CountryDetails.subregion,
                flag: `flag-${CountryDetails.cca2.toLowerCase()}`,
                name: CountryDetails.name.common
            };
            setCountryDetails(transformedCountryDetails);
        }

        setPhone(phoneNumber);
        setCountryCode(formattedDialCode);
        setCountryName(country.name);

        // Call the onChange handler passed from parent component
        onChange(phoneNumber, formattedDialCode, country.name, countryDetails);
    };

    // Sync local phone state with value prop changes
    useEffect(() => {
        if (value !== undefined && value !== phone) {
            setPhone(value);
        }
    }, [value]);

    useEffect(() => {
        console.log(`Country Code: ${countryCode}, Local Number: ${localNumber}`);
    }, [countryCode, localNumber]);

    return (
        <div>
            <label className="col-form-label">Phone Number
                {required && <span className="text-danger"> *</span>}
            </label>
            <div className="form-group">
                <PhoneInput
                    country={'us'}
                    value={phone} // Controlled by local phone state
                    onChange={handlePhoneChange} // Handle changes here
                    inputStyle={{
                        position: 'relative',
                        fontSize: '14px',
                        letterSpacing: '.01rem',
                        marginTop: '0 !important',
                        marginBottom: '0 !important',
                        background: '#FFFFFF',
                        border: '1px solid #CACACA',
                        borderRadius: '5px',
                        lineHeight: '25px',
                        height: '35px',
                        width: '80%',
                        outline: 'none'
                    }}
                    containerStyle={{
                        width: '100% !important'
                    }}
                    countryCodeEditable={false}
                />
                {error && (
                    <div className="error-message" style={{ color: 'red' }}>
                        {error}
                    </div>
                )}
            </div>
        </div>
    );
};

/* ====================================================================== */

const SearchBox = () => {
    const [locationInput, setLocationInput] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const handleLocationChange = (event: any) => {
        setLocationInput(event.target.value);
    };

    const handleSearchChange = (event: any) => {
        setSearchInput(event.target.value);
    };

    const handleLocationSearch = async (event: any) => {
        event.preventDefault();
        try {
            const response = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(locationInput)}&key=AIzaSyCrmKst8GuS8VI8_CKKBFl8LCL_qkthAJ8`
            );

            if (response.data.results.length > 0) {
                const { formatted_address, geometry } = response.data.results[0];
                console.log('Location set to:', formatted_address);
                console.log('Latitude:', geometry.location.lat, 'Longitude:', geometry.location.lng);
                setLocationInput(formatted_address);
            } else {
                console.log('No results found');
            }
        } catch (error) {
            console.error('Error fetching location:', error);
        }
    };

    return (
        <div className="col-md-12">
            <div className="search-box">
                <form onSubmit={handleLocationSearch}>
                    <div className="search-input line">
                        <div className="search-group-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="standard-feather">
                                <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                <circle cx="12" cy="10" r="3"></circle>
                            </svg>
                        </div>
                        <div className="form-group mb-0">
                            <label>Your Location</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="America"
                                value={locationInput}
                                onChange={handleLocationChange}
                            />
                        </div>
                    </div>
                    <div className="search-input">
                        <div className="search-group-icon search-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="react-feather-custom">
                                <circle cx="11" cy="11" r="8"></circle>
                                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                            </svg>
                        </div>
                        <div className="form-group mb-0">
                            <label>What are you looking for?</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Car Repair Services"
                                value={searchInput}
                                onChange={handleSearchChange}
                            />
                        </div>
                    </div>
                    <div className="search-btn">
                        <button type="submit" className="btn btn-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="react-feather-custom me-2">
                                <circle cx="11" cy="11" r="8"></circle>
                                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                            </svg>
                            Search
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

// const TKN_TimePickerComponent: React.FC<TimePickerComponentProps> = ({ use12Hours, label }) => {
//     return (
//         <div className="form-group">
//             <label className="col-form-label">{label}</label>
//             <div className="form-icon">
//                 <TimePicker
//                     className="form-control timepicker"
//                     placeholder="Select Time"
//                     suffixIcon={<ClockCircleOutlined />}
//                     use12Hours={use12Hours}
//                     format={use12Hours ? 'h:mm A' : 'HH:mm'}
//                 />
//                 <span className="cus-icon">
//                     <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         width="24"
//                         height="24"
//                         viewBox="0 0 24 24"
//                         fill="none"
//                         stroke="currentColor"
//                         strokeWidth="2"
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                         className="react-feather-custom"
//                     >
//                         <circle cx="12" cy="12" r="10"></circle>
//                         <polyline points="12 6 12 12 16 14"></polyline>
//                     </svg>
//                 </span>
//             </div>
//         </div>
//     );
// };

const TKN_TimePickerComponent: React.FC<TimePickerComponentProps> = ({ use12Hours, label, onChange, required }) => {
    const handleChange = (time: any, timeString: any) => {
        if (onChange) {
            onChange(timeString);
        }
    };

    return (
        <div className="form-group">
             <label className="col-form-label">
                {label}
                {required && <span style={{ color: 'red' }}>*</span>}
            </label>
            <div className="form-icon">
                <TimePicker
                    className="form-control timepicker"
                    placeholder="Select Time"
                    suffixIcon={<ClockCircleOutlined />}
                    use12Hours={use12Hours}
                    format={use12Hours ? 'h:mm A' : 'HH:mm'}
                    onChange={handleChange}
                />
                <span className="cus-icon">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="react-feather-custom"
                    >
                        <circle cx="12" cy="12" r="10"></circle>
                        <polyline points="12 6 12 12 16 14"></polyline>
                    </svg>
                </span>
            </div>
        </div>
    );
};


const DayPickerComponent: React.FC<DayPickerComponentProps> = ({ label }) => {
    const { Option } = Select;
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    return (
        <div className="form-group">
            <label className="col-form-label">{label}</label>
            <div className="form-icon">
                <Select className="form-control daypicker" placeholder="Select Day">
                    {daysOfWeek.map((day, index) => (
                        <Option key={index} value={day}>
                            {day}
                        </Option>
                    ))}
                </Select>
            </div>
        </div>
    );
};

const TKN_DateOfBirthInput: React.FC<TKN_DateOfBirthInputProps> = ({ onChange }) => {
    const [selectedDate, setSelectedDate] = useState<any>(null);

    const handleDateChange = (date: any) => {
        setSelectedDate(date);
        onChange(date); // Call the onChange prop with the selected date
    };

    return (
        <div className="form-group">
            <label className="col-form-label">Date of birth</label>
            <div className="form-icon">
                <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    placeholderText="DD/MM/YYYY"
                    className="form-control datetimepicker"
                    dateFormat="dd/MM/yyyy"
                    dropdownMode="select"
                    showYearDropdown
                    yearDropdownItemNumber={15} // Show 15 years in the dropdown
                    scrollableYearDropdown
                />
                <span className="cus-icon">
                    <i className="feather-calendar" />
                </span>
            </div>
        </div>
    );
};

const SearchBar: React.FC<SearchBarProps> = (Props) => {
    const IconComponent = Icon[Props.icon];

    // Handler for input change
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (Props.onChangeText) {
            Props.onChangeText(event.target.value);
        }
    };

    return (
        <div className="p-relative-new">
            <div className="search-bar">
                <span>
                    {IconComponent && (
                        <IconComponent
                            className={`searchbox-inputicon p-absolute-style-new search-icon-color-new search-icon-width ${Props.iconclassName}`}
                            style={Props.style}
                        />
                    )}
                </span>
                <input
                    type="text"
                    placeholder={Props.placeholderText}
                    className={`search-icon-padding-new form-control ${Props.className}`}
                    value={Props.value}
                    onChange={handleInputChange} // Bind the onChange event
                />
            </div>
        </div>
    );
};

const TKN_DropdownInputEdit: React.FC<DropdownInputEditProps> = ({ placeholderText, options, initialValue, onChange }) => {
    const [selectedOption, setSelectedOption] = useState<string | undefined>(initialValue);
    const [isOpen, setIsOpen] = useState(false);

    const handleOptionSelect = (option: string) => {
        setSelectedOption(option);
        setIsOpen(false); // Close dropdown after selecting an option
        if (onChange) {
            console.log('Selected Option:', option);
            onChange(option);
        }
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    useEffect(() => {
        if (initialValue) {
            setSelectedOption(initialValue);
        }
    }, [initialValue]);


    return (
        <div className="form-group position-relative">
            <div className="input-with-icon">
                <input
                    type="text"
                    className="form-control"
                    placeholder={placeholderText}
                    value={selectedOption || ''}
                    readOnly
                    onClick={toggleDropdown}
                />
                <ChevronDown className="dropdown-icon" onClick={toggleDropdown} />
            </div>
            <div className={`dropdown-options ${isOpen ? 'open' : ''}`}>
                {options.map((option: any, index: any) => (
                    <div
                        key={index}
                        className="dropdown-option"
                        onClick={() => handleOptionSelect(option)}
                    >
                        {option}
                    </div>
                ))}
            </div>
        </div>
    );
};

const TKN_InputPlaceholderEdit: React.FC<TKN_InputPlaceholderEditProps> = ({ placeholderText, value, onChange, className, readOnly, maxLength, style }) => {
    const [inputValue, setInputValue] = useState(value || '');

    useEffect(() => {
        if (value !== undefined) {
            setInputValue(value);
        }
    }, [value]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setInputValue(newValue);
        if (onChange) {
            onChange(e);
        }
    };
    return (
        <div className="form-group">
            <input
                type="text"
                className={`form-control ${className}`}
                placeholder={placeholderText}
                value={inputValue}
                onChange={handleInputChange}
                readOnly={readOnly}
                maxLength={maxLength}
                style={{ ...style }}
            />
        </div>
    );
};




const TKN_PhoneInputEditComponent: React.FC<TKN_PhoneInputComponentEditProps> = ({ onChange, required, error, inputvalue, readOnly, }) => {

    const [phone, setPhone] = useState('');
    const [countryCode, setCountryCode] = useState('');
    /* const [countryCode, setCountryCode] = useState<string>(''); */
    const [localNumber, setLocalNumber] = useState('');
    const [countryName, setCountryName] = useState('');
    const [countryDetails, setCountryDetails] = useState<CountryDetailsType | null>(null);


    const handlePhoneChange = (value: any, country: any) => {
        const dialCode = country.dialCode;
        const phoneNumber = `+${value}`;
        const formattedDialCode = `+${dialCode}`;
        const CountryCode = country.countryCode;
        const countryCodeUppercase = CountryCode.toUpperCase();
        const CountryDetails = countries.find(country => country.cca2 === countryCodeUppercase);

        if (CountryDetails) {

            const transformedCountryDetails = {
                cca2: CountryDetails.cca2, // 'IN'
                currency: Object.keys(CountryDetails.currencies), // ['INR']
                callingCode: dialCode, // ['91']
                // callingCode: CountryDetails.idd.suffixes.map(suffix => suffix),
                region: CountryDetails.region, // 'Asia'
                subregion: CountryDetails.subregion, // 'Southern Asia'
                flag: `flag-${CountryDetails.cca2.toLowerCase()}`, // 'flag-in'
                name: CountryDetails.name.common // 'India'
            };
            setCountryDetails(transformedCountryDetails);
        }

        setPhone(phoneNumber);
        setCountryCode(formattedDialCode);
        setCountryName(country.name);
        onChange(phoneNumber, countryCode, countryName, countryDetails);
    };

    return (
        <div>
            <label className="col-form-label">Phone Number
                {required && <span className="text-danger"> *</span>}
            </label>
            <div className="form-group">
                <PhoneInput
                    country={'us'}
                    value={inputvalue}
                    onChange={handlePhoneChange}
                    inputStyle={{
                        position: 'relative',
                        fontSize: '14px',
                        letterSpacing: '.01rem',
                        marginTop: '0 !important',
                        marginBottom: '0 !important',
                        // paddingLeft: '4px',
                        // marginLeft: '38px',
                        background: readOnly ? 'rgb(245, 245, 245)' : '#ffffff',
                        border: '1px solid #CACACA',
                        borderRadius: '5px',
                        lineHeight: '25px',
                        height: '35px',
                        width: '80%',
                        outline: 'none'
                    }}
                    containerStyle={{
                        width: '100% !important'
                    }}
                    countryCodeEditable={false}
                    disableDropdown={readOnly}
                    inputProps={{
                        readOnly: readOnly
                    }}
                />
                {error && (
                    <div className="error-message" style={{ color: 'red' }}>
                        {error}
                    </div>
                )}
            </div>
        </div>
    );
};

type TKPrediction = {
    description: string;
    id: string;
    location: {
        lat: number;
        lng: number;
    }; // Add location with lat and lng properties
};

const TKN_LocationMapSearch: React.FC<MapWithIconSearchProps> = ({
    icon,
    onLocationSelect,
    incomingLocation,
    incomingLocationPlaceName,
}) => {
    const [inputValue, setInputValue] = useState<string>('');
    const [predictions, setPredictions] = useState<TKPrediction[]>([]);
    const [idCounter, setIdCounter] = useState<number>(0);
    const [mapVisible, setMapVisible] = useState<boolean>(false);
    const [selectedLocation, setSelectedLocation] = useState<{ lat: number; lng: number } | undefined>(
        incomingLocation || { lat: 20.5937, lng: 78.9629 }
    );
    const userLocationDetails = useSelector((state: RootState) => state.userLocationDetails);
    const mapRef = useRef<google.maps.Map | null>(null);

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyCrmKst8GuS8VI8_CKKBFl8LCL_qkthAJ8',
        libraries: ['places', 'marker'],
        mapIds: ['745402fb5b67878c'],
    });

    const generateUniqueId = () => {
        setIdCounter((prevCounter) => prevCounter + 1);
        return `prediction-${idCounter}`;
    };

    const handleMapClick = async (e: google.maps.MapMouseEvent) => {
        const lat = e.latLng?.lat();
        const lng = e.latLng?.lng();
        if (lat !== undefined && lng !== undefined) {
            setSelectedLocation({ lat, lng });

            if (onLocationSelect) {
                onLocationSelect({ lat, lng });
            }

            try {
                const response = await axios.get(
                    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCrmKst8GuS8VI8_CKKBFl8LCL_qkthAJ8`
                );

                if (response.data.results.length > 0) {
                    const address = response.data.results[0].formatted_address;
                    setInputValue(address);
                } else {
                    console.log('No address found');
                }
            } catch (error) {
                console.error('Error fetching address:', error);
            }
        }
    };

    const handleInputChange = async (e: { query: string }) => {
        const value = e.query;
        setInputValue(value);

        if (value.length >= 2) {
            try {
                const response = await axios.get(
                    `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(value)}&key=AIzaSyCrmKst8GuS8VI8_CKKBFl8LCL_qkthAJ8`
                );

                if (response.data.results.length > 0) {
                    const formattedAddresses = response.data.results.map((result: any) => ({
                        description: result.formatted_address,
                        id: generateUniqueId(),
                        location: result.geometry.location,
                    }));
                    setPredictions(formattedAddresses);
                } else {
                    setPredictions([]);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        } else {
            setPredictions([]);
        }
    };

    const handleSelectPrediction = (prediction: TKPrediction) => {
        setInputValue(prediction.description);
        setSelectedLocation(prediction.location);
        setPredictions([]);

        if (onLocationSelect) {
            onLocationSelect(prediction.location);
        }
    };

    const handleIconClick = () => {
        setMapVisible(true);
        if (incomingLocation) {
            setSelectedLocation(incomingLocation);
        } else {
            setSelectedLocation({ lat: 20.5937, lng: 78.9629 });
        }
    };

    const handleClearLocation = () => {
        setInputValue('');
        console.log('userLocationDetails', userLocationDetails);
        if (userLocationDetails) {
            console.log("reshma");
            const userLat = userLocationDetails.latitude;
            const userLng = userLocationDetails.longitude;
            const userPlaceName = userLocationDetails.place_name || '';

            setSelectedLocation({ lat: userLat, lng: userLng });

            if (onLocationSelect) {
                onLocationSelect({ lat: userLat, lng: userLng });
            }

            if (userPlaceName) {
                setInputValue(userPlaceName);
            }
        } else {
            setSelectedLocation({ lat: 20.5937, lng: 78.9629 });
        }
    };

    useEffect(() => {
        if (isLoaded && selectedLocation && mapVisible) {
            const mapElement = new google.maps.Map(document.getElementById('map') as HTMLElement, {
                center: selectedLocation,
                zoom: 14,
                mapId: '745402fb5b67878c',
            });

            const markerElement = new google.maps.Marker({
                position: selectedLocation,
                map: mapElement,
                title: 'Selected Location',
            });

            google.maps.event.addListener(mapElement, 'click', handleMapClick);
            mapRef.current = mapElement;
        }
    }, [isLoaded, selectedLocation, mapVisible]);

    useEffect(() => {
        if (incomingLocation && incomingLocationPlaceName) {
            setMapVisible(true);
            setInputValue(incomingLocationPlaceName);
        }
    }, [incomingLocation, incomingLocationPlaceName]);

    return (
        <React.Fragment>
            <div style={{ width: '100%', display: 'flex', gap: 3 }}>
                <div style={{ width: '100%', position: 'relative' }}>
                    <AutoComplete
                        className="autocomplete-containersignup"
                        placeholder="Search Box"
                        value={inputValue}
                        suggestions={predictions}
                        completeMethod={handleInputChange}
                        field="description"
                        onChange={(e) => setInputValue(e.value)}
                        onSelect={(e) => handleSelectPrediction(e.value)}
                        style={{ borderColor: 'white', paddingRight: '40px' }}
                    />
                    {icon && (
                        <span
                            // onClick={handleIconClick}
                            className="react-feather-custom"
                            style={{
                                position: 'absolute',
                                right: '30px',
                                top: '35%',
                                transform: 'translateY(-50%)',
                                cursor: 'pointer',
                            }}
                        >
                            {icon}
                        </span>
                    )}
                    {inputValue && (
                        <Icon.XCircle className="close-icon" onClick={handleClearLocation}
                            style={{
                                position: 'absolute',
                                right: '10px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                cursor: 'pointer',
                            }} />
                    )}
                </div>
            </div>

            {mapVisible && isLoaded && (
                <div id="map" style={{ width: '100%', height: '400px', marginTop: '10px' }} />
            )}
        </React.Fragment>
    );
};

const libraries: ('places' | 'marker')[] = ['places', 'marker'];

const TKN_LocationMap: React.FC<MapWithIconProps> = ({ icon, onLocationSelect }) => {


    const [inputValue, setInputValue] = useState<string>('');
    const [predictions, setPredictions] = useState<TKPrediction[]>([]);
    const [idCounter, setIdCounter] = useState<number>(0);
    const [mapVisible, setMapVisible] = useState<boolean>(false);
    const [selectedLocation, setSelectedLocation] = useState<{ lat: number; lng: number } | null>({
        lat: 20.5937, // Default latitude for India
        lng: 78.9629, // Default longitude for India
    });
    const mapRef = useRef<google.maps.Map | null>(null);



    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyCrmKst8GuS8VI8_CKKBFl8LCL_qkthAJ8',
        libraries,
        mapIds: ['745402fb5b67878c'],
    });

    //const loaderOptions = useMemo(
    // () => ({
    //googleMapsApiKey: 'AIzaSyCrmKst8GuS8VI8_CKKBFl8LCL_qkthAJ8', 
    //libraries: libraries, 
    // mapIds: ['745402fb5b67878c'],
    // }),
    // []
    //);    

    /*const { isLoaded } = useJsApiLoader(loaderOptions);    
    
    if (!isLoaded) {
        return <div>Loading...</div>;
    } */

    const generateUniqueId = () => {
        setIdCounter((prevCounter) => prevCounter + 1);
        return `prediction-${idCounter}`;
    };

    // Handle map clicks to select location
    const handleMapClick = async (e: google.maps.MapMouseEvent) => {
        const lat = e.latLng?.lat();
        const lng = e.latLng?.lng();
        if (lat && lng) {
            setSelectedLocation({ lat, lng });

            console.log('FROM MAP:', selectedLocation);

            if (onLocationSelect) {
                onLocationSelect(lat, lng);
            }

            try {
                const response = await axios.get(
                    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCrmKst8GuS8VI8_CKKBFl8LCL_qkthAJ8`
                );

                if (response.data.results.length > 0) {
                    const address = response.data.results[0].formatted_address;
                    setInputValue(address);
                } else {
                    console.log('No address found');
                }
            } catch (error) {
                console.error('Error fetching address:', error);
            }
        }
    };

    const handleInputChange = async (e: { query: string }) => {
        const value = e.query;
        setInputValue(value);

        if (value.length >= 2) {
            try {
                const response = await axios.get(
                    `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(value)}&key=AIzaSyCrmKst8GuS8VI8_CKKBFl8LCL_qkthAJ8`
                );

                if (response.data.results.length > 0) {
                    const formattedAddresses = response.data.results.map((result: any) => ({
                        description: result.formatted_address,
                        id: generateUniqueId(),
                        location: result.geometry.location,
                    }));
                    setPredictions(formattedAddresses);
                    console.log('PREDICTIONS:', formattedAddresses);
                } else {
                    setPredictions([]);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        } else {
            setPredictions([]);
        }
    };

    const handleSelectPrediction = (prediction: TKPrediction) => {
        setInputValue(prediction.description);
        setSelectedLocation(prediction.location);
        if (onLocationSelect) {
            onLocationSelect(prediction.location.lat, prediction.location.lng);
        }
        console.log('PREDICTIONS 2:', prediction.location);
        setPredictions([]);
    };

    const handleIconClick = () => {
        setMapVisible(true); // Ensure the map is shown
        setSelectedLocation({ lat: 20.5937, lng: 78.9629 }); // Set to India's latitude and longitude
    };

    useEffect(() => {
        if (isLoaded && selectedLocation && mapVisible) {
            const mapElement = new google.maps.Map(document.getElementById('map') as HTMLElement, {
                center: selectedLocation,
                zoom: 14,
                mapId: '745402fb5b67878c', // Add mapId here
            });

            // Use regular marker if AdvancedMarkerElement isn't supported by the map
            const markerElement = new google.maps.Marker({
                position: selectedLocation,
                map: mapElement,
                title: 'Selected Location',
            });

            // Add map click listener
            google.maps.event.addListener(mapElement, 'click', handleMapClick);
            mapRef.current = mapElement;
        }
    }, [isLoaded, selectedLocation, mapVisible]);

    return (
        <React.Fragment>
            <div style={{ width: '100%', display: 'flex', gap: 3 }}>
                <div style={{ width: '100%', position: 'relative' }}>
                    <AutoComplete
                        className="autocomplete-containersignup p-newinputtext"
                        placeholder="Search Box"
                        value={inputValue}
                        suggestions={predictions}
                        completeMethod={handleInputChange}
                        field="description"
                        onChange={(e) => setInputValue(e.value)}
                        onSelect={(e) => handleSelectPrediction(e.value)}
                        style={{ borderColor: 'white' }}
                    />
                    {icon && (
                        <span
                            onClick={handleIconClick}
                            className="react-feather-map"
                            style={{
                                position: 'absolute',
                                right: '10px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                cursor: 'pointer',
                            }}
                        >
                            {icon}
                        </span>
                    )}
                </div>
            </div>

            {mapVisible && isLoaded && (
                <div id="map" style={{ width: '100%', height: '400px', marginTop: '10px' }} />
            )}
        </React.Fragment>
    );
};

const PhoneInputComponent: React.FC<PhoneInputComponentProps> = ({
    required,
    error,
    value,
    readOnly,
    handlePhoneChange
}) => {

    return (
        <div>
            <label className="col-form-label">Phone Number
                {required && <span className="text-danger"> *</span>}
            </label>
            <div className="form-group">
                <PhoneInput
                    country={'us'}
                    value={value}
                    onChange={handlePhoneChange}
                    inputStyle={{
                        position: 'relative',
                        fontSize: '14px',
                        letterSpacing: '.01rem',
                        marginTop: '0 !important',
                        marginBottom: '0 !important',
                        background: readOnly ? 'rgb(245, 245, 245)' : '#ffffff',
                        border: '1px solid #CACACA',
                        borderRadius: '5px',
                        lineHeight: '25px',
                        height: '35px',
                        width: '80%',
                        outline: 'none'
                    }}
                    containerStyle={{
                        width: '100% !important'
                    }}
                    countryCodeEditable={false}
                    disableDropdown={readOnly}
                    inputProps={{
                        readOnly: readOnly
                    }}
                />
                {error && (
                    <div className="error-message" style={{ color: 'red' }}>
                        {error}
                    </div>
                )}
            </div>
        </div>
    );
};

const DropdownInputvalue = () => {

    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const DropdownComponent = ({
        placeholderText,
        options,
        handleOptionSelect,
        value,
    }: DropdownProps) => {
        return (
            <div className="form-group position-relative">
                <div className="input-with-icon">
                    <input
                        type="text"
                        className="form-control"
                        placeholder={placeholderText}
                        value={value}
                        readOnly
                        onClick={toggleDropdown}
                    />
                    <ChevronDown className="dropdown-icon" onClick={toggleDropdown} />
                </div>
                <div className={`dropdown-options ${isOpen ? 'open' : ''}`}>
                    {options.map((option: Option, index: number) => (
                        <div
                            key={index}
                            className="dropdown-option"
                            onClick={() => handleOptionSelect(option)}
                        >
                            {option.label}
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    return { DropdownComponent, toggleDropdown };
};


const TKN_LocationMapedit: React.FC<MapWithIconeditProps> = ({ icon, onLocationSelect, savedLat, savedLng, savedAddress }) => {

    const [inputValue, setInputValue] = useState<string>(savedAddress || ''); // Set input to saved address
    const [predictions, setPredictions] = useState<TKPrediction[]>([]);
    const [idCounter, setIdCounter] = useState<number>(0);
    const [mapVisible, setMapVisible] = useState<boolean>(true); // Keep map visible for edit case

    const [selectedLocation, setSelectedLocation] = useState<{
        lat: number;
        lng: number;
      }>({
        lat: savedLat && !isNaN(Number(savedLat)) ? Number(savedLat) : 20.5937, // Parse as number
        lng: savedLng && !isNaN(Number(savedLng)) ? Number(savedLng) : 78.9629, // Parse as number
      });

    const mapRef = useRef<google.maps.Map | null>(null);

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyCrmKst8GuS8VI8_CKKBFl8LCL_qkthAJ8',
        libraries,
        mapIds: ['745402fb5b67878c'],
    });

    const generateUniqueId = () => {
        setIdCounter((prevCounter) => prevCounter + 1);
        return `prediction-${idCounter}`;
    };

    const fetchAddressFromCoordinates = async (lat: number, lng: number) => {
        try {
            const response = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCrmKst8GuS8VI8_CKKBFl8LCL_qkthAJ8`
            );

            if (response.data.results.length > 0) {
                const address = response.data.results[0].formatted_address;
                setInputValue(address); // Update the input field with the fetched address
            } else {
                console.log('No address found');
            }
        } catch (error) {
            console.error('Error fetching address:', error);
        }
    };

    

    // Handle map clicks to select location
    const handleMapClick = async (e: google.maps.MapMouseEvent) => {
        const lat = e.latLng?.lat();
        const lng = e.latLng?.lng();
        if (lat && lng) {
            setSelectedLocation({ lat, lng });
            if (onLocationSelect) {
                onLocationSelect(lat, lng);
            }

            // Fetch address based on lat and lng
            try {
                const response = await axios.get(
                    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCrmKst8GuS8VI8_CKKBFl8LCL_qkthAJ8`
                );

                if (response.data.results.length > 0) {
                    const address = response.data.results[0].formatted_address;
                    setInputValue(address); // Update the input field with the address                 

                    console.log('address',address);
                } else {
                    console.log('No address found');
                }
            } catch (error) {
                console.error('Error fetching address:', error);
            }
        }
    };

    const handleInputChange = async (e: { query: string }) => {
        const value = e.query;
        setInputValue(value);

        if (value.length >= 2) {
            try {
                const response = await axios.get(
                    `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(value)}&key=AIzaSyCrmKst8GuS8VI8_CKKBFl8LCL_qkthAJ8`
                );

                if (response.data.results.length > 0) {
                    const formattedAddresses = response.data.results.map((result: any) => ({
                        description: result.formatted_address,
                        id: generateUniqueId(),
                        location: result.geometry.location,
                    }));
                    setPredictions(formattedAddresses);
                } else {
                    setPredictions([]);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        } else {
            setPredictions([]);
        }
    };

    const handleSelectPrediction = (prediction: TKPrediction) => {
        setInputValue(prediction.description);
        setSelectedLocation(prediction.location);
        if (onLocationSelect) {
            onLocationSelect(prediction.location.lat, prediction.location.lng);
        }
        setPredictions([]);
    };

    const handleIconClick = () => {
        setMapVisible(true); // Ensure the map is shown
    };

      useEffect(() => {
        if (savedLat && savedLng && !isNaN(Number(savedLat)) && !isNaN(Number(savedLng))) {

            const lat = Number(savedLat);
            const lng = Number(savedLng);

          setSelectedLocation({
            lat: Number(savedLat),
            lng: Number(savedLng),
          });
          fetchAddressFromCoordinates(lat, lng);
          
        }
      }, [savedLat, savedLng]);

    useEffect(() => {

        if (isLoaded && selectedLocation && mapVisible) {

            const mapElement = new google.maps.Map(document.getElementById('map') as HTMLElement, {
                center: selectedLocation,
                zoom: 14,
                mapId: '745402fb5b67878c', // Add mapId here
            });

            // Set marker at the saved or selected location
            const markerElement = new google.maps.Marker({
                position: selectedLocation,
                map: mapElement,
                title: 'Selected Location',
            });

            // Add map click listener
            google.maps.event.addListener(mapElement, 'click', handleMapClick);
            mapRef.current = mapElement;
        }
    }, [isLoaded, selectedLocation, mapVisible]);

    return (
        <React.Fragment>
            <div style={{ width: '100%', display: 'flex', gap: 3 }}>
                <div style={{ width: '100%', position: 'relative' }}>
                    <AutoComplete
                        className="autocomplete-containersignup"
                        placeholder="Search Box"
                        value={inputValue}
                        suggestions={predictions}
                        completeMethod={handleInputChange}
                        field="description"
                        onChange={(e) => setInputValue(e.value)}
                        onSelect={(e) => handleSelectPrediction(e.value)}
                        style={{ borderColor: 'white' }}
                    />
                    {icon && (
                        <span
                            onClick={handleIconClick}
                            className="react-feather-map"
                            style={{
                                position: 'absolute',
                                right: '10px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                cursor: 'pointer',
                            }}
                        >
                            {icon}
                        </span>
                    )}
                </div>
            </div>

            {mapVisible && isLoaded && (
                <div id="map" style={{ width: '100%', height: '400px', marginTop: '10px' }} />
            )}
        </React.Fragment>
    );
};


export {
    TKN_DropdownInputEdit,
    TKN_LocationMapSearch,
    TKN_LocationMap,
    TKN_TimePickerComponentEdit,
    TKN_InputPlaceholderEdit,
    TKN_InputPlaceholder,
    TKN_TextInput,
    TKN_TextInputreqired,
    TKN_EmailInputwithcheck,
    TKN_InputPlaceholderIcon,
    TKN_DropdownInput,
    TKN_DropdownInputvalue,
    TKN_DropdownInputvalueedit,
    TKN_TextareaPlaceholder,
    TKN_BlankInput,
    TKN_EmailInput,
    TKN_PasswordInput,
    TKN_OTPInput,
    LocationSearchBox,
    TKN_PhoneInputComponent,
    SearchBox,
    TKN_TimePickerComponent,
    DayPickerComponent,
    TKN_LocationSearchBox,
    TKN_DateOfBirthInput,
    SearchBar,
    TKN_PhoneInputEditComponent,
    PhoneInputComponent,
    DropdownInputvalue,
    TKN_LocationMapedit
};


