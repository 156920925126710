import React, { useEffect, useState } from 'react';
import * as Icon from 'react-feather';
import en from '../../../../en/en';
import { ImageWithBasePath } from '../../../../core/img/ImageWithBasePath';
import { Link, useNavigate } from 'react-router-dom';
import "yet-another-react-lightbox/styles.css";
import { all_routes } from '../../../../core/data/routes/all_routes';
import Available_Services from '../../../components/available_services/available_services_component';
import ReviewBoxListing from '../../../components/bussinessDetails/reviewBoxListing/reviewboxListing';
import RelatedBusiness from '../../../components/bussinessDetails/relatedBusinessSection/relatedBusiness';
import GallerySection from '../../../components/bussinessDetails/gallerySection/gallery';
import apiService from '../../../../api/authentication-service';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GOOGLE_MAPS_PLACES_API, viewLargeMapUrl, WEB_BASE_URL, WEB_USER_ROLE_CUSTOMER } from '../../../../constants/constants';
import './service-details2.css';
import CustomMap from '../../../components/customMap/customMap';
import { useLoadGoogleMapAPI } from '../../../../hooks/useLoadGoogleMapAPI';
import FooterOne from '../../../components/footer-one';
import HomeHeader from '../../../components/header/home-header';
import { ButtonTextOnly_Colored } from '../../../components/buttons/button';
import { RootState } from '../../../../core/data/redux/store';
import { setVendorBusinessDetails } from '../../../../core/data/redux/action';
import Loader from '../../../components/loader_spinner/loader_spinner';
import { set } from 'date-fns';
import { eventNames } from 'process';
import TKN_modal from '../../../components/modal/modal';

interface Service {
  service_name: string;
  service_description: string;
  service_image: string;
  service_vendor_map_id: number;
  start_date: string;
  end_date: string;
  hours_open: string;
}

interface BusinessDetails {
  State: string;
  bussiness_address: string;
  bussiness_name: string;
  bussiness_description: string;
  city: string;
  country: string;
  latitude: string
  longitude: string
  mobile: string;
  services: Service[];
  timezone: string;
  vendor_email: string;
  vendorId: number;
  vendor_images: string[];
  website: string | null;
  category: any;
  subcategory: any;
  createdat: any;

}

const ServiceDetails1 = () => {
  const routes = all_routes;
  const location = useLocation();
  const [businessDetails, setBusinessDetails] = useState<BusinessDetails | null>(null);
  const [services, setServices] = useState<Service[]>([]);
  const vendorId = useSelector((state: RootState) => state.vendorId);
  const dispatch = useDispatch();
  const isLoaded = useLoadGoogleMapAPI(GOOGLE_MAPS_PLACES_API);
  const vendor_images = businessDetails?.vendor_images ?? [];
  const images = vendor_images.map((src: string) => ({ src: `${WEB_BASE_URL}${src}` }));
  const user = useSelector((state: RootState) => state.user);
  const user_id = user.user_id;
  const navigate = useNavigate();
  const [favStatus, setFavStatus] = useState(false);
  const [showloader, setShowLoader] = useState<boolean>(true);
  const role = useSelector((state: RootState) => state?.role);
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (vendorId) {
      sendGetBusinessDetailsRequest(vendorId);
      getCheckUserFavourite();
    }
  }, [vendorId]);

  useEffect(() => {

    if (location.state?.eventNames === 'favorites') {
      handlefavouriteChange();
    }
  }, [location.state !== null]);

  const sendGetBusinessDetailsRequest = (vendorId: string) => {
    const payload = { id: vendorId };
    apiService.getbusinessdetails(payload)
      .then((response) => {
        getAllBusinessDetailsResponse(response?.data);
      })
      .catch((error) => {
        console.error('Error fetching businesses:', error);
      });
  };

  const getAllBusinessDetailsResponse = (response: any) => {
    if (response) {
      const status = response?.status;
      if (status === true) {
        const responseData = response?.data;
        setBusinessDetails(responseData);

        setServices(responseData.services);
        dispatch(setVendorBusinessDetails(responseData));
        setShowLoader(false);
      };
    };
  };

  const getCheckUserFavourite = () => {
    const payload = { user_id: user_id, vendor_id: vendorId };

    apiService.checkFavouriteStatus(payload).then((response) => {
      const status = response?.data?.status;

      if (status === true) {
        const data = response?.data?.data
        if (data === 1) {
          setFavStatus(true)
        } else {
          setFavStatus(false)
        };
      };
    }).catch((error) => {
      console.log('error', error)
    });
  };

  const handlefavouriteChange = () => {
    if (user_id) {
      if (role === WEB_USER_ROLE_CUSTOMER) {
        getAddupdatefavourite();
      } else {
        console.log("Not customer its vendor");
        setShowModal(true);
      }
    } else {
      navigate(routes.login, { state: { from: routes.businessDetails, eventNames: "favorites" }, replace: true });
    };
  };

  const handleunfavouriteChange = () => {
    if (user_id) {
      getDeletefavourites();
    }
  };

  const getDeletefavourites = () => {
    const payload = { user_id: user_id, vendor_id: vendorId }
    apiService.deleteUserFavourites(payload).then((response) => {
      const status = response?.data?.status
      if (status === true) {
        setFavStatus(false)
      };
    }).catch((error) => {
      console.log("error", error)
    });
  };

  const getAddupdatefavourite = () => {
    const payload = {
      user_id: user_id,
      vendor_id: vendorId,
      status: 1
    };

    apiService.addUpdateFavourites(payload).then((response) => {
      const status = response?.data?.status;

      if (status === true) {
        setFavStatus(true)
      };
    }).catch((error) => {
      console.log("Error", error)
    });
  };

  return (
    <>
      <HomeHeader type={1} />
      <div className="bg-img">
        <ImageWithBasePath
          src="assets/img/bg/work-bg-03.png"
          alt="img"
          className="bgimg1"
        />
        <ImageWithBasePath
          src="assets/img/bg/work-bg-03.png"
          alt="img"
          className="bgimg2"
        />
        <ImageWithBasePath
          src="assets/img/bg/feature-bg-03.png"
          alt="img"
          className="bgimg3"
        />
      </div>
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">{en.BUSINESS_DETAILS}</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={routes.homeOne}>{en.HOME}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {en.BUSINESS_DETAILS}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <div className="serv-profile">
                <h2>{businessDetails?.bussiness_name}</h2>
                <ul>
                  <li className="service-map">
                    <Icon.MapPin size={15} style={{ color: "#0445cf" }} /> {`${businessDetails?.city}, ${businessDetails?.State}`}
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className='add-to-favbutton'>
                {favStatus ? (<ButtonTextOnly_Colored label={'Unfavourite'} fullWidth={false} onClick={handleunfavouriteChange} style={{ color: '#0445cf', backgroundColor: '#f8f8f8', }} />) :
                  (<ButtonTextOnly_Colored label={'Add to Favourites'} fullWidth={false} onClick={handlefavouriteChange} />)}
              </div>
            </div>
            {showloader ? (

              <Loader showloader={showloader} />
            ) : (

              <GallerySection type='row' images={images} />
            )}
          </div>
          <div className="row">
            <div className="col-lg-8">
              <div className="service-wrap">
                <h5>Description</h5>
                <p>
                  {businessDetails?.bussiness_description ?? en.NOT_AVAILABLE}
                </p>
              </div>
              {showloader ? (
                <Loader showloader={showloader} />
              ) : (

                <GallerySection type='slider' images={images} />
              )}
              <div className="row align-items-center">
                <h5>Available Services</h5>
                {showloader ? (

                  <Loader showloader={showloader} />
                ) : (

                  <div className="row">
                    {services.map((service, index) => {
                      const hoursOpen = JSON.parse(service.hours_open);
                      return (
                        <Available_Services
                          key={index}
                          imagesrc={`${WEB_BASE_URL}${service.service_image}`}
                          descriptiontext={service.service_description}
                          service_name={service.service_name}
                          hoursOpen={hoursOpen}
                          State={businessDetails?.State}
                          city={businessDetails?.city}
                          bussiness_name={businessDetails?.bussiness_name}
                          mobile={businessDetails?.mobile}
                          bussiness_address={businessDetails?.bussiness_address}
                          vendor_email={businessDetails?.vendor_email}
                          vendorMapId={service.service_vendor_map_id}
                          vendor_id={vendorId}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
              {businessDetails && businessDetails.category && businessDetails.subcategory && vendorId && (
                <RelatedBusiness
                  vid={vendorId}
                  category={businessDetails.category}
                  subcategory={businessDetails.subcategory}
                />
              )}
              <ReviewBoxListing />
            </div>
            <div className="col-lg-4 theiaStickySidebar">
              <div className="stickybar">
                <div className="card card-provide mb-0">
                  <div className="card-body">
                    <div className="package-widget pack-service">
                      <h5>Business Information</h5>
                      <ul style={{ marginTop: "35px" }}>
                        <li>
                          <div className="add-serving w-100">
                            <div className="add-serv-item">
                              <div className="provide-box">
                                <span style={{ marginTop: "15px", backgroundColor: "white" }}><Icon.Home style={{ color: "#0445cf" }} size={15} /></span>
                              </div>
                              <div className="add-serv-info">
                                <h6>Vendor Name</h6>
                                <p className='text-overflow-elipses w-100'>{businessDetails?.bussiness_name}</p>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li style={{ marginTop: "-25px" }}>
                          <div className="add-serving w-100">
                            <div className="add-serv-item">
                              <div className="add-serv-item">
                                <div className="provide-box">
                                  <span style={{ marginTop: "15px", backgroundColor: "white" }}><Icon.Clock style={{ color: "#0445cf" }} size={15} /></span>
                                </div>
                                <div className="add-serv-info">
                                  <h6>Member Since</h6>
                                  <p>{businessDetails?.createdat}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li style={{ marginTop: "-25px" }}>
                          <div className="add-serving w-100">
                            <div className="add-serv-item">
                              <div className="add-serv-item">
                                <div className="provide-box">
                                  <span style={{ marginTop: "15px", backgroundColor: "white" }}><Icon.MapPin style={{ color: "#0445cf" }} size={15} /></span>
                                </div>
                                <div className="add-serv-info">
                                  <h6>Address</h6>
                                  <p className='text-overflow-elipses w-100'>{businessDetails?.bussiness_address}</p>
                                </div>
                              </div>
                            </div></div>
                        </li>
                        <li style={{ marginTop: "-25px" }}>
                          <div className="add-serving w-100">
                            <div className="add-serv-item">
                              <div className="add-serv-item">
                                <div className="provide-box">
                                  <span style={{ marginTop: "15px", backgroundColor: "white" }}><Icon.Mail style={{ color: "#0445cf" }} size={15} /></span>
                                </div>
                                <div className="add-serv-info">
                                  <h6>Email</h6>
                                  <p className='text-overflow-elipses w-100'>
                                    <a href={`mailto:${businessDetails?.vendor_email}`}
                                      className="email-link">
                                      {businessDetails?.vendor_email}
                                    </a>
                                  </p>
                                </div>
                              </div>
                            </div></div>
                        </li>
                        <li style={{ marginTop: "-25px" }}>
                          <div className="add-serving w-100">
                            <div className="add-serv-item">
                              <div className="provide-box">
                                <span style={{ marginTop: "15px", backgroundColor: "white" }}><Icon.Phone style={{ color: "#0445cf" }} size={15} /></span>
                              </div>
                              <div className="add-serv-info">
                                <h6>Phone</h6>
                                <p className='text-overflow-elipses w-100'>
                                  <a href={`tel:${businessDetails?.mobile}`} className="phone-link">
                                    {businessDetails?.mobile}
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="map-grid">
                      <CustomMap
                        latitude={Number(businessDetails?.latitude)}
                        longitude={Number(businessDetails?.longitude)}
                        isLoaded={isLoaded}
                      />
                    </div>
                    <Link
                      target='_blank'
                      to={viewLargeMapUrl(Number(businessDetails?.latitude), Number(businessDetails?.longitude))}
                      className="btn btn-primary"
                    >
                      {en.VIEW_IN_LARGE_MAP}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterOne />
      </div>
      {showModal && (
        <TKN_modal
          title={en.OPTION_UNAVAILABLE}
          description={en.OPTION_UNAVAILABLE_VENDOR}
          okbutton={en.OK}
          handleshowModal={showModal}
          handleSuccessButton={() => navigate(routes.homeOne)}
        />
      )}
    </>
  );
};

export default ServiceDetails1;
