import React from 'react';
import { Link } from 'react-router-dom';
import { ImageWithBasePath } from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';
import HomeHeader from '../../../components/header/home-header';
import FooterOne from '../../../components/footer-one';

const PrivacyPolicy = () => {
  const routes = all_routes;
  return (
    <>
    <HomeHeader type={1} />
      <div className="bg-img">
        <ImageWithBasePath
          src="assets/img/bg/work-bg-03.png"
          alt="img"
          className="bgimg1"
        />
        <ImageWithBasePath
          src="assets/img/bg/work-bg-03.png"
          alt="img"
          className="bgimg2"
        />
      </div>
      {/* Breadcrumb */}
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">Privacy Policy</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={routes.homeOne}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Privacy Policy
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* /Breadcrumb */}
      <div className="content">
        <div className="container">
          <div className="row">
            {/* Terms & Conditions */}
            <div className="col-md-12">
              <div className="terms-content privacy-cont">
                <p>
                  SAFA Logics is committed to protecting your personal information.
                  We employ industry-standard security measures to safeguard your data
                  from unauthorized access, disclosure, alteration, and destruction.
                </p>
                <p>
                  The web app may integrate with third-party services for enhanced functionality.
                  Please be aware that these services may have their own privacy policies,
                  and SAFA Logics is not responsible for their practices.{' '}
                </p>
                <p>
                  Your personal information will not be shared with third parties
                  for marketing purposes without your explicit consent.
                  However, aggregated and anonymized data may be used for analytical purposes.
                </p>
                <p>
                  Users are responsible for maintaining the confidentiality of their account information,
                  including the associated email address. Any activity occurring under the user’s account
                  is their responsibility. It is the duty of the customer to verify the legitimacy of the service providers.
                  The “Take A No” web app is designed to offer a smooth time management solution for both clients and vendors;
                  it will not verify the legitimacy of the vendors or their services.
                  Respective parties are in charge of confirming with good judgment.{' '}
                </p>
                <p>
                  SAFA Logics reserves the right to update or modify this data collection and privacy policy.
                  Users will be notified of any significant changes, and continued use of the web app implies acceptance
                  of the updated terms. By using the “𝗧𝗮𝗸𝗲 𝗔 𝗡𝗼” web app,
                  you acknowledge and agree to the terms outlined in this data collection and privacy policy.
                </p>
                <p>SAFA Logics reserves the right to terminate or suspend user accounts for violation of these
                  terms or any other reason deemed necessary for the proper functioning and
                  integrity of the “𝗧𝗮𝗸𝗲 𝗔 𝗡𝗼” web app.
                </p>
              </div>
            </div>
            {/* /Terms & Conditions */}
          </div>
        </div>
      </div>
      <FooterOne />
    </>
  );
};

export default PrivacyPolicy;
