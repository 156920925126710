import React from 'react';
import { Link } from 'react-router-dom';
import { ImageWithBasePath, ImageWithBasePathWithUrl } from '../../../../core/img/ImageWithBasePath';
import Slider from 'react-slick';
import './gallery.css';
import * as Icon from 'react-feather';

interface Image {
  src: string;
}

interface GalleryProps {
  type: 'row' | 'slider';
  images?: Image[];
}

const sliderSettings = {
  dots: false,
  autoplay: false,
  slidesToShow: 3,
  speed: 500,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 776,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 567,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const defaultImage = { src: 'assets/img/default-profile-img.jpg' };

const GallerySection: React.FC<GalleryProps> = ({ type, images = [] }) => {
  const imageSlots = [defaultImage, defaultImage, defaultImage];
  images.forEach((image, index) => {
    if (index < 3) {
      imageSlots[index] = image;
    }
  });

  return (
    <div>
      {type === 'row' && (
        <div className="col-md-12">
          <div className="service-gal">
            <div className="row gx-2">
              <div className={`col-md-${images.length > 1 ? '9' : '12'}`}>
                <div className="service-images big-gallery">
                  {imageSlots[0].src !== defaultImage.src ? (
                    <ImageWithBasePathWithUrl
                      src={imageSlots[0].src}
                      className="img-fluid serv-img slider-img"
                    />
                  ) : (
                    <ImageWithBasePath
                      src={defaultImage.src}
                      className="img-fluid serv-img slider-img"
                    />
                  )}
                  {/*  <Link
                    to={imageSlots[0]?.src || '#'}
                    data-fancybox="gallery"
                    className="btn btn-show"
                  >
                    <Icon.Image size={15} style={{ marginRight: '5px', color: '#0445cf' }} />
                    Show all photos
                  </Link> */}
                </div>
              </div>
              <div className="col-md-3">
                <div className="service-images small-gallery">
                  {imageSlots.slice(1, 3).map((image, index) => (
                    <div key={index} className="service-images small-gallery">
                      {image.src !== defaultImage.src ? (
                        <Link to={image.src} data-fancybox="gallery">
                          <ImageWithBasePathWithUrl
                            src={image.src}
                            className="img-fluid serv-img sliders-img"
                          />
                        </Link>
                      ) : (
                        <ImageWithBasePath
                          src={defaultImage.src}
                          className="img-fluid serv-img sliders-img hide"
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {type === 'slider' && (
        <div className="service-wrap">
          <div className="row">
            <div className="col-md-6">
              <h5>Gallery</h5>
            </div>
            <div className="col-md-6 text-md-end">
              <div className="owl-nav mynav3" />
            </div>
          </div>
          <Slider {...sliderSettings} className="gallery-slide">
            {imageSlots.map((image, index) => (
              <div key={index} className="gallery-widget">
                {image.src !== defaultImage.src ? (
                  <Link to={image.src} data-fancybox="gallery">
                    <ImageWithBasePathWithUrl
                      className="img-fluid serv-img gallery-img"
                      src={image.src}
                    />
                  </Link>
                ) : (
                  <ImageWithBasePath
                    className="img-fluid serv-img gallery-img hide"
                    src={defaultImage.src}
                  />
                )}
              </div>
            ))}
          </Slider>
        </div>
      )}
    </div>
  );
};

export default GallerySection;
