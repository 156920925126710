import React, { useEffect, useRef, useState } from 'react';
import './customer-favourite-style.css';
import FavoriteCard from '../../../components/favoriteCard/favoriteCard';
import useGetAllFavorites from '../../../../hooks/getAllFavorites';
import { usePagination } from '../../../../context/paginationContext';
import { useSelector } from 'react-redux';
import PaginationComponent from '../../../components/paginationComponent/pagination';
import { SearchBar } from '../../../components/inputfields/input';
import en from '../../../../en/en';
import { useSearch } from '../../../../hooks/useSearch';
import Loader from '../../../components/loader_spinner/loader_spinner';

const CustomerFavourite = () => {

  const limit = 25;
  const { count, setCount } = usePagination();
  const userId = useSelector((state: any) => state.user.user_id);
  const { favorites, pagesCount, showloaderfav } = useGetAllFavorites(userId, count, limit, true);
  const useSearchForFavoriteBusiness = useSearch(favorites);
  const { searchQuery, filteredData, handleSearchQuery } = useSearchForFavoriteBusiness('bussiness_name');
  const prevFavoritesRef = useRef(false);

  const handlePrevious = (count: number) => {
    if (count > 1) {
      setCount((prevCount) => prevCount - 1);
    }
  };

  const handleNext = (count: number) => {
    if (count < pagesCount) {
      setCount((prevCount) => prevCount + 1);
    }
  };

  useEffect(() => {
    if (favorites.length > 0 && !prevFavoritesRef.current) {
      handleSearchQuery('');

    }
  }, [favorites]);

  return (
    <div className="customer-favorite-container">
      <div className="row align-items-center">
        <div className="page-header pt-3">
          <div className="row">
            <div className="col-md-4">
              <div className="provider-subtitle">
                <h6>{en.FAVORITES}</h6>
              </div>
            </div>
            <div className="col-md-8 d-flex align-items-center justify-content-md-end flex-wrap">
              <div className="grid-listview" >
                <ul>
                  <li>
                    <SearchBar
                      placeholderText={'Search'}
                      icon={'Search'}
                      iconclassName={'search-icon'}
                      value={searchQuery}
                      onChangeText={(text: string) => handleSearchQuery(text)}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loader showloader={showloaderfav} style={{ marginTop: '100px' }} />
      {!showloaderfav && (<div className="row">
        {filteredData.length > 0 ? (
          <FavoriteCard favoritesArray={filteredData} />
        ) : (
          <div className="no-favorites-container justify-content-center d-flex align-items-center">
            <h1>{en.NO_FAVORITES}</h1>
          </div>
        )}
      </div>)}
      {!showloaderfav && (
        <div className="d-flex justify-content-center">
          {pagesCount > 1 &&
            <div>
              <PaginationComponent
                totalPages={pagesCount}
                currentPage={count}
                handlePrevious={() => handlePrevious(count)}
                handleNext={() => handleNext(count)}
                handleBeginning={() => setCount(1)}
                handleEnd={() => setCount(pagesCount)}
              />
            </div>
          }
        </div>
      )}

    </div>
  );
};

export default CustomerFavourite;
