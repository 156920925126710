import React from 'react';

interface IconWithTextProps {
    className?: any;
    iconclassName: string;
    label1: string;
    style?: React.CSSProperties
}

interface IconWithDateProps {
    className?: any;
    iconclassName: string;
    label1: string;
    label2: string;
    style?: React.CSSProperties
}

interface IconWithNumberProps {
    iconclassName: string;
    label1: number;
    style?: React.CSSProperties
}

const IconWithText: React.FC<IconWithTextProps> = (Props) => {
    return (
        <>
            <p className={`mb-2 ${Props.className}`}>
                <i className={Props.iconclassName} style={{ color: '#0445CF' }}></i> : {Props.label1}
            </p>

        </>
    )
}
const IconWithDate: React.FC<IconWithDateProps> = (Props) => {
    return (
        <>
            <p className={`mb-2 ${Props.className}`}>
                <i className={Props.iconclassName} style={{ color: '#0445CF' }}></i> : {Props.label1} | {Props.label2}
            </p>
        </>
    )
}

const IconWithNum: React.FC<IconWithNumberProps> = (Props) => {
    return (
        <>
            <p>
                <i className={Props.iconclassName} style={{ color: '#0445CF', }}></i> : +91 {Props.label1}
            </p>
        </>
    )
}


export { IconWithText, IconWithDate, IconWithNum };