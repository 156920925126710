import React from "react";
import { ImageWithBasePath, ImageWithBasePathWithUrl } from "../../../core/img/ImageWithBasePath";
import { VendorServiceBookingInterface } from "../../../core/models/interface";
import en from "../../../en/en";
import './serviceNameSection.css'

const ServiceProfile = ({ vendor }: { vendor: VendorServiceBookingInterface }) => {
    return (
        <div className="serv-profile">
            <span className="badge" style={{ fontSize: '20px' }}>{vendor.vendorServiceName}</span>
            <h2>{vendor.vendorBusinessName}</h2>
            <ul>
                <li className="serv-pro">
                    {/* Conditionally render the vendor image or an icon */}
                    {typeof vendor.vendorImageSrc === 'string' ? (
                        <ImageWithBasePath src={vendor.vendorImageSrc} alt="img" />
                    ) : (
                        <span className="icon-container">
                            {vendor.vendorImageSrc}
                        </span>
                    )}
                    <div className="serv-pro-info">
                        <h6>{vendor.vendorName}</h6>
                        <p className="serv-review hide">
                            <i className="fa-solid fa-star" />{' '}
                            <span>{en.RATING_NUMBER} </span>({en.NUMBER_OF_REVIEWS})
                        </p>
                    </div>
                </li>
            </ul>
        </div>
    );
};

const ServiceNameSection = (props: VendorServiceBookingInterface) => {
    return (
        <div className="col-lg-6">
            <div className="service-book">
                <div className="service-book-img">
                    <ImageWithBasePathWithUrl src={props.serviceImageSrc} alt="img" className="img-size" />
                </div>
                <ServiceProfile vendor={props} />
            </div>
        </div>
    );
};
export default ServiceNameSection;