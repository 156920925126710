import React, { useEffect, useState } from 'react';
import AllRoutes from './router/router';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  set_current_route,
  set_current_route_array,
  set_is_mobile_sidebar,
  setUserLocationDetails,
  setSearchRequestData,
} from '../core/data/redux/action';
import Progress from './frontend/common/progress/progress';
import {
  AppState,
  CurrentRoute,
  Pageinfos,
  ProviderEarningsadmindatas,
} from '../core/models/interface';
import fetchCurrentLocation from '../helper/location';
import initialState from '../core/data/redux/initial.values';
import { RootState } from '../core/data/redux/store';

const Feature = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const toggle_data = useSelector(
    (state: ProviderEarningsadmindatas) => state.ProviderEarningsAdmin,
  );
  const toggle_data_2 = useSelector((state: AppState) => state.toggleSidebar2);
  const mouse_data = useSelector((state: AppState) => state.mouseOverSidebar);
  const [currentRoute, setCurrentRoute] = useState(location.pathname);
  const current_route = useSelector((state: Pageinfos) => state.current_route);
  const mobileSidebar = useSelector((state: AppState) => state.mobileSidebar);
  const current_route_array = useSelector(
    (state: CurrentRoute) => state.current_route_array,
  );

  const [locationRequestCounter, setLocationRequestCounter] = useState(1);

  const getRoutes = () => {
    setCurrentRoute(location.pathname);
    const splitVal = location.pathname.split('/');
    const route_data = {
      base: splitVal[1],
      page: splitVal[2] || '',
      last: splitVal[3] || '',
    };

    dispatch(set_current_route_array(splitVal));
    dispatch(set_current_route(route_data));
    dispatch(set_is_mobile_sidebar(false));
  };

  useEffect(() => {
    getRoutes();
  }, [location.pathname, currentRoute]);

  const userLocationData = useSelector((state: RootState) => state.userLocationDetails);
  const searchRequestData = useSelector((state: RootState) => state.searchRequestData);

  // useEffect(() => {

  //   if((!userLocationData) || (!userLocationData.latitude) || (!userLocationData.longitude) || (!userLocationData.place_name)){

  //     fetchAndUpdateUserCurrentUserDetails();
  //   }
  // }, [userLocationData]);

  useEffect(() => {

    if(locationRequestCounter) {

      console.log('LOC Counter', locationRequestCounter);
      fetchAndUpdateUserCurrentUserDetails();
      setLocationRequestCounter(0);
    } else {

      console.log('No more request');
    }
  }, []);

  const fetchAndUpdateUserCurrentUserDetails = async () => {

    dispatch(setUserLocationDetails(initialState.userLocationDetails));
    dispatch(setSearchRequestData(initialState.searchRequestData));
    const userLocationDetails = await fetchCurrentLocation();
    dispatch(setUserLocationDetails(userLocationDetails));

    if (!(searchRequestData?.latitude) || !(searchRequestData?.longitude) || (searchRequestData?.place_name)) {

      dispatch(setSearchRequestData({
        ...searchRequestData,
        latitude: userLocationDetails?.latitude,
        longitude: userLocationDetails?.longitude,
        place_name: userLocationDetails?.place_name
      }));
    }
  }

  return (
    <div
      className={`${(current_route.base === 'vendors' || current_route.base === 'customers') ? 'provider-body' : ''}${current_route_array.includes('authentication') ? 'login-body' : ''
        }${current_route.base == 'admin' ? 'admin' : ''} ${toggle_data_2 ? 'mini-sidebar' : ''
        } ${mobileSidebar ? 'menu-opened slide-nav' : ''} ${mouse_data ? 'expand-menu' : ''
        } ${current_route.base == 'home-five' ? 'home-page-five' : ''}`}
    >
      <div className={`main-wrapper ${mobileSidebar ? 'menu-opened' : ''}`}>
        <AllRoutes />
      </div>
      <div className={`sidebar-overlay ${toggle_data ? 'opened' : ''} ${mobileSidebar ? 'opened' : ''}`} />
      {location.pathname.includes('home') ? <Progress /> : <></>}
    </div>
  );
};

export default Feature;
