import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Icon from "react-feather";
import colors from "../../../../colors/colors";
import { ButtonTextOnly_Colored } from "../../buttons/button";
import { ImageWithBasePathWithUrl } from "../../../../core/img/ImageWithBasePath";
import { BookingRowSectionProps } from "../../../../core/models/interface";
import "./bookingRowSectionStyle.css";
import { checkBookingTimeCondition } from "../../../../helper/noShowBookingCheckFunction";
import { WEB_BASE_URL } from "../../../../constants/constants";
import en from "../../../../en/en";
import useBookingStatusText from "../../../../helper/bookingStatusCheckFunction";
import { all_routes } from "../../../../core/data/routes/all_routes";
import { useDispatch, useSelector } from "react-redux";
import apiService from "../../../../api/authentication-service";
import TKN_modal from "../../modal/modal";
import { setvendorId } from "../../../../core/data/redux/action";

const BookingRowSection = (props: BookingRowSectionProps) => {
    const dispatch = useDispatch();
    const routes = all_routes;
    const [activebookingid, setActiveBookingId] = useState<number | null>(null);

    const handleCancelButton = (bookingId: number) => {
        setActiveBookingId(bookingId);
    };

    const handlecloseButton = () => {
        setActiveBookingId(null);
    };
    const handleOkButton = (user_id: any, booking_id: any) => {
        setActiveBookingId(null);
        sendGetCancelUserBookings(user_id, booking_id)
    };

    const sendGetCancelUserBookings = (user_id: any, booking_id: any) => {
        apiService.cancelUserBookings({ user_id: user_id, booking_id: booking_id }).then((response) => {
            const responseData = response?.data;
            if (responseData) {
                const status = responseData?.status;
                if (status) {
                    window.location.reload()
                    setActiveBookingId(null)
                }
            }
        }).catch((error) => {
            console.log('error =', error)
        });
    };

    return (
        <React.Fragment>
            {props.dataArray?.map((booking, index) => {
                const { text, className } = useBookingStatusText(booking);
                return (
                    <tr key={index}>
                        <td>
                            <h2 className="table-avatar table-booking" >
                                <div className="avatar avatar-m">
                                    <ImageWithBasePathWithUrl
                                        className="avatar-img rounded"
                                        src={WEB_BASE_URL + booking.service_image}
                                        alt="User Image"
                                    />
                                </div>
                                <div className="booking-details">
                                    <React.Fragment>
                                        <div>
                                            <h2 style={{ color: '#28283C', fontWeight: '500' }}>{booking.service_name}</h2>
                                        </div>
                                        <span className="mb-3 flex-fill">
                                            <p className="mt-2 mb-2 booking-details-title booking-details-style" style={{ fontSize: '13px' }}>Booking Details:</p>
                                            <div className="boking-details-info">
                                                <div className="mb-2 d-flex">
                                                    <div>
                                                        {booking.first_name + ' ' + booking.last_name}
                                                    </div>
                                                    <p className="mb-0 py-0 px-1">|</p>
                                                    <div className="flex-fill">
                                                        {booking.token_number.substring(4)}
                                                    </div>
                                                </div>
                                                <div className="d-flex">
                                                    <div>
                                                        {booking.booking_for}

                                                    </div>
                                                    <p className="mb-0 py-0 px-1">|</p>
                                                    <div className="flex-fill">
                                                        {booking.booking_slot.replace(/(am|pm)/i, (match: any) => match.toUpperCase())}
                                                    </div>
                                                </div>
                                            </div>
                                            {activebookingid === booking.booking_id && (
                                                <TKN_modal title={'Cancel Booking'} description={'Are you sure you want to cancel this booking?'} okbutton={'OK'} cancelbutton={'Cancel'} closeButton={false} handleshowModal={true} handleCancelButton={handlecloseButton} handleSuccessButton={() => handleOkButton(booking.user_id, booking.booking_id)} />
                                            )}
                                        </span>
                                        {booking.status !== 0 && booking.status !== 3 && checkBookingTimeCondition(booking.booking_for + ' | ' + booking.booking_slot, booking.business_timezone) === false &&

                                            <div className="d-flex justify-content-between">
                                                <ButtonTextOnly_Colored label={en.CANCEL} fullWidth={false} style={{ backgroundColor: '#f8f8f8', color: '#0445cf', borderColor: '#f8f8f8', minWidth: '124px', justifyContent: 'center', height: '36px' }} onClick={() => handleCancelButton(booking.booking_id)} />
                                            </div>
                                        }
                                    </React.Fragment>
                                </div>
                            </h2>
                        </td>
                        <td>
                            <h2 className="table-avatar table-user">
                                <div className="round-white-background">
                                    <Icon.Home style={{ color: "#0445cf" }} size={18} />
                                </div>
                                <Link
                                    to={{ pathname: routes.businessDetails }}
                                    onClick={() => dispatch(setvendorId(booking.vendor_id))}
                                    className="vendor-name"
                                >
                                    {booking.bussiness_name}
                                </Link>
                            </h2>
                        </td>
                        <td className="text-end">
                            <div className="provider-card5-customer" style={{ justifyContent: 'center' }}>
                                <p className={`provider-card6 ${className}`} style={{ paddingTop: '6px' }}>{text}</p>
                            </div>
                        </td>
                    </tr>

                );
            })}
        </React.Fragment>
    )
};

const FavoriteRowSection = (props: BookingRowSectionProps) => {
    const [serverError, setServerError] = useState('');
    const [messagevalue, setMessagevalue] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const dispatch = useDispatch();
    const routes = all_routes;
    const navigateToBookings = useNavigate();

    const handleBookingClick = (vendor_id: number, event?: React.MouseEvent<HTMLAnchorElement>) => {
        event?.preventDefault();
        dispatch(setvendorId(vendor_id));
        navigateToBookings(routes.businessDetails);
    };

    const handleremove = (vendorId: number) => {
        sendRemoveFavouriteRequest(vendorId);
    };

    const sendRemoveFavouriteRequest = (vendorId: number) => {

        apiService.deleteUserFavourites(addRemoveFavouriteRequest(vendorId)).then((response) => {

            addRemoveFavouriteResponse(response?.data);
            if (response?.data.data == 1) {

                const responsestatus = response?.data.data;
                sendgetFavouriteRequest();
            }
        }).catch((error) => {

            console.log('error', error);
        });
    };

    const userId = useSelector((state: any) => {
        return state.user.user_id
    });

    const addRemoveFavouriteRequest = (vendorId: number) => ({
        user_id: userId,
        vendor_id: vendorId,
    });

    const addRemoveFavouriteResponse = (response: any) => {
        if (response) {
            const status = response?.status;
            if (status && status === true) {

                window.location.reload();

            } else {

                setServerError(en.SERVERERROR);
            }
        }
    };

    const sendgetFavouriteRequest = () => {

        apiService.getUserFavourites(getFavouriteRequest()).then((response) => {
            getFavouriteResponse(response?.data);
        }).catch((error) => {
            console.log('error', error);
        });
    };

    const getFavouriteRequest = () => ({
        user_id: userId,
    });

    const getFavouriteResponse = (response: any) => {

        if (response) {

            const status = response?.status;
            if (status && status === true) {
                const vendorList = response?.data;

                setMessagevalue(vendorList);
                setOriginalData(vendorList);
            } else {
                setServerError(en.SERVERERROR);
            }
        }
    };

    return (
        <React.Fragment>
            {props.dataArray?.map((booking, index) => (
                <tr key={index} >
                    <td >
                        <h2 className="table-avatar favorite-booking d-flex">
                            <div className="avatar avatar-m">
                                <ImageWithBasePathWithUrl
                                    className="avatar-img rounded"
                                    src={WEB_BASE_URL + booking.temp_name}
                                    alt="User Image"
                                />
                            </div>
                            <div className="favorite-details flex-fill fw-500">
                                <React.Fragment>
                                    {booking.bussiness_name}
                                    <span className="mb-3">
                                        <span className="mb-1">
                                            <Icon.Mail color={colors.web_primary_color} size={14} className="mb-1" />
                                            <a
                                                href={`mailto:${booking.vendor_email}`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    window.open(`mailto:${booking.vendor_email}`, '_blank');
                                                }}
                                            >
                                                {booking.vendor_email}
                                            </a>

                                        </span>
                                        <span>
                                            <Icon.Phone color={colors.web_primary_color} size={14} className="mb-1" />
                                            <a
                                                href={`tel:${booking.mobile}`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    window.open(`tel:${booking.mobile}`, '_blank');
                                                }}
                                            >
                                                {booking.mobile}
                                            </a>

                                        </span>
                                    </span>
                                    <span className="d-flex justify-content-between">
                                        <ButtonTextOnly_Colored
                                            label={en.UNFAVORITE}
                                            className="btn btn-light-danger btn-unfavorite d-flex justify-content-center align-items-center"
                                            route={""}
                                            onClick={() => handleremove(booking.id)}
                                            fullWidth={false}
                                        />
                                        <ButtonTextOnly_Colored
                                            label={en.BOOK_NOW}
                                            className="btn btn-light-danger btn-book-now d-flex justify-content-center align-items-center"
                                            route={""}
                                            fullWidth={false}
                                            onClick={(event?: React.MouseEvent<HTMLAnchorElement>) => handleBookingClick(booking.id, event)}
                                        />
                                    </span>
                                </React.Fragment>
                            </div>
                        </h2>
                    </td>
                </tr>
            ))}
        </React.Fragment >
    )
};

export { FavoriteRowSection, BookingRowSection };