import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { Calendar as PrimeCalendar } from 'primereact/calendar';
import Footer from '../../../components/footer/footer';
import PagesAuthHeader from './common/header';
import { Nullable } from 'primereact/ts-helpers';
import { ImageWithBasePath } from '../../../../core/img/ImageWithBasePath';
import { TKN_DropdownInputvalue, TKN_TextInputreqired, TKN_TextInput } from '../../../components/inputfields/input';
import { CountryDropdown } from "react-country-region-selector";
import { validateZipCodeByCountry } from "../../../../helper/validate";
import apiService from '../../../../api/authentication-service';
import { RootState } from '../../../../core/data/redux/store';
import { useDispatch, useSelector } from 'react-redux'
import { setUserRole, setVendorRole } from '../../../../core/data/redux/action';
import en from '../../../../en/en';
import { ButtonTextandIconReverse_Colored, ButtonTextOnly_Colored } from '../../../components/buttons/button';
import { dateFormatFunction } from '../../../../helper/Date';
import { format } from 'date-fns';

type ErrorMessages = {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  gender?: string;
  address1?: string;
  address2?: string;
  state?: string;
  city?: string;
  postcode?: string;
  selectedCountry?: string;
};

type Option = {
  label: string;
  value: number;
};

const UserProfile = () => {
  const routes = all_routes;
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState<ErrorMessages>({});
  const [error, setError] = useState('');
  const [birthDate, setBirthDate] = useState<Date | null>(null);
  const [selectedbirthDate, setSelectedbirthDate] = useState<any>();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [genderoption, setGenderoption] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [postcode, setPostcode] = useState('');
  const [selectedCountry, setSelectedCountry] = useState<any>(null);
  const [responseMessage, setResponseMessage] = useState('');
  const [countryError, setCountryError] = useState('');
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const userEmail = useSelector((state: RootState) => state.useremail);
  const gender: Option[] = [
    { label: 'Male', value: 1 },
    { label: 'Female', value: 2 },
    { label: 'Other', value: 3 }
  ];

  const resetResponseMessage = () => {
    setResponseMessage('');
  };

  const handleFirstNameChange = (value: string) => {
    setFirstName(value);
    if (value.trim() !== '') {
      setErrors((prevErrors) => ({ ...prevErrors, firstName: undefined }));
    }
  };

  const handleLastNameChange = (value: string) => {
    setLastName(value);
    if (value.trim() !== '') {
      setErrors((prevErrors) => ({ ...prevErrors, lastName: undefined }));
    }
  };

  const handleAddressChange = (value: string) => {
    setAddress1(value);
    if (value.trim() !== '') {
      setErrors((prevErrors) => ({ ...prevErrors, address1: undefined }));
    }
  };

  const handleAddress2Change = (value: string) => {
    setAddress2(value);
  };

  const handleStateChange = (value: string) => {
    setState(value);
    if (value.trim() !== '') {
      setErrors((prevErrors) => ({ ...prevErrors, state: undefined }));
    }
  };

  const handleCityChange = (value: string) => {
    setCity(value);
    if (value.trim() !== '') {
      setErrors((prevErrors) => ({ ...prevErrors, city: undefined }));
    }
  };

  const handlePostalcodeChange = (value: string) => {
    setPostcode(value);
    const zipCodeValidationResult = validateZipCodeByCountry(selectedCountry, value);
    if (value.trim() !== '' && zipCodeValidationResult.status === 'true') {
      setErrors((prevErrors) => ({ ...prevErrors, postcode: undefined }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, postcode: zipCodeValidationResult.error }));
    }
  };

  const handleSelectgender = (selectedGender: Option) => {
    setGenderoption(selectedGender.label);
    if (selectedGender.label) {
      setErrors((prevErrors) => ({ ...prevErrors, gender: undefined }));
    }
  };

  const handleCountryChange = (val: string) => {
    resetResponseMessage();

    if (!val) {
      setCountryError('Unable to select country. Please try again.');
      setSelectedCountry(null);
    } else {
      setCountryError('');
      setSelectedCountry(val);
      setErrors((prevErrors) => ({ ...prevErrors, selectedCountry: undefined }));
    };
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        const Imagedata = reader.result as string;
        setSelectedImage(Imagedata);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = () => {
    const newErrors: ErrorMessages = {};

    if (!firstName) newErrors.firstName = 'First name is required';
    if (!lastName) newErrors.lastName = 'Last name is required';
    if (!genderoption) newErrors.gender = 'Gender is required';
    if (!address1) newErrors.address1 = 'Address is required';
    if (!state) newErrors.state = 'State is required';
    if (!city) newErrors.city = 'City is required';

    if (!postcode) {
      newErrors.postcode = 'Zip code is required';
    } else {
      const zipCodeValidationResult = validateZipCodeByCountry(selectedCountry, postcode);

      if (zipCodeValidationResult.status === 'false') {
        newErrors.postcode = zipCodeValidationResult.error;
      }
    };

    if (!selectedCountry) newErrors.selectedCountry = 'Country is required';
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setIsLoading(true);
      sendProfileRequest();
    };
  };

  const sendProfileRequest = () => {
    const payload = profileRequest();

    apiService.addProfile(payload)
      .then((response: any) => {
        getProfileResponse(response?.data);
      })
      .catch((error: any) => {
        console.error('Error fetching User details', error);
        setError('Failed to fetch user details');
      });
  };

  const profileRequest = () => ({
    user_id: userEmail.userid,
    email: userEmail.email,
    first_name: firstName,
    last_name: lastName,
    gender: genderoption,
    dob: birthDate ? format(birthDate, 'yyyy-MM-dd') : null,
    address1: address1,
    address2: address2,
    country: selectedCountry,
    state: state,
    city: city,
    zipcode: postcode,
    profile_verified: 1,
    profile_photo: selectedImage ? selectedImage.split(',')[1] : '',
  });

  const getProfileResponse = (response: any) => {
    setIsLoading(false);

    if (response) {
      const status = response?.status;

      if (status === true) {
        const user_role = response?.data?.userrole;
        const U_id = response?.data?.id;

        const userRoleDetails = {
          userrole: user_role,
          uid: U_id
        };

        if (user_role === 2) {
          localStorage.removeItem('UserEmail');
          localStorage.removeItem('userRole');
          setModalTitle(en.ACCNT_CREATION_SUCCESS);
          setModalMessage(en.ACCNT_CREATION_SUCCESS_MESSAGE);
          setShowModal(true);
        } else {
          dispatch(setVendorRole(userRoleDetails));
          navigateTo(routes.vendorBusinessProfile);
        }
      }
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    navigateTo(routes.login);
  };

  const handleCancelButton = () => {
    navigateTo(-1)
  };
  const handleRemoveImage = () => {
    setSelectedImage(null);
  };

  return (
    <>
      <PagesAuthHeader />
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="widget-title">
                <h4>Add Profile</h4>
              </div>
              <h6 className="user-title">Profile Picture</h6>
              <div className="pro-picture">
                <div className="pro-img">
                  {selectedImage ? (
                    <img
                      src={selectedImage}
                      alt="Selected"
                      style={{ objectFit: 'cover' }} />
                  ) : (
                    <ImageWithBasePath src='assets/img/profiles/avatar-01.avif' alt="user" style={{ objectFit: 'cover' }} />
                  )}
                </div>
                <div className="pro-info">
                  {selectedImage ? (
                    <div className="d-flex">
                      <div className="img-upload">
                        <ButtonTextandIconReverse_Colored label={'Upload'} icon={'UploadCloud'} route={'#'} fullWidth={false} style={{ padding: '5px 10px' }} />
                        <input type="file" accept="image/*" onChange={handleFileChange} />
                      </div>
                      <ButtonTextOnly_Colored label={'Remove'} route={'#'} fullWidth={false} style={{ backgroundColor: '#F7F7FF', color: '#0445CF', borderColor: '#0445CF', height: '48px' }} onClick={handleRemoveImage} />
                    </div>
                  ) : (<div className="d-flex">
                    <div className="img-upload">
                      <ButtonTextandIconReverse_Colored label={'Upload'} icon={'UploadCloud'} route={'#'} fullWidth={false} style={{ padding: '5px 10px' }} />
                      <input type="file" accept="image/*" onChange={handleFileChange} />
                    </div>
                  </div>)}
                  <p>
                    *image size should be at least 320px big,and less then 500kb.
                    Allowed files .png and .jpg.
                  </p>
                </div>
              </div>
              <h6 className="user-title">General Information</h6>
              <div className="general-info">
                <div className="row">
                  <div className="col-md-6">
                    <TKN_TextInputreqired onChange={handleFirstNameChange} label="First Name" placeholder='Enter first name' />
                    {errors.firstName && <div className="text-danger">{errors.firstName}</div>}
                  </div>
                  <div className="col-md-6">
                    <TKN_TextInputreqired onChange={handleLastNameChange} label="Last Name" placeholder='Enter last name' />
                    {errors.lastName && <div className="text-danger">{errors.lastName}</div>}
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">Gender<span className="text-danger">*</span></label>
                      <TKN_DropdownInputvalue
                        placeholderText="Select gender"
                        options={gender}
                        onSelect={handleSelectgender}
                        descriptionError={errors.gender}
                      />
                      {errors.gender && <div className="text-danger">{errors.gender}</div>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="col-form-label">Date of birth</label>
                      <div className="form-icon cus-profile">
                        <PrimeCalendar
                          value={birthDate}
                          onChange={(e) => {
                            if (e.value) {
                              const date = new Date(e.value);
                              setBirthDate(date);
                            }
                          }}
                          placeholder="MM/dd/yyyy"
                          showIcon
                          inputClassName="p-inputtext"
                          readOnlyInput={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <h6 className="user-title">Address</h6>
                <div className="general-info">
                  <div className="row">
                    <div className="col-md-6">
                      <TKN_TextInputreqired onChange={handleAddressChange} label="Address 1" placeholder='Enter your address' />
                      {errors.address1 && <div className="text-danger">{errors.address1}</div>}
                    </div>
                    <div className="col-md-6">
                      <TKN_TextInput onChange={handleAddress2Change} label="Address 2" placeholder='Enter your address' />
                      {errors.address2 && <div className="text-danger">{errors.address2}</div>}
                    </div>
                    <div className="col-md-6">
                      <TKN_TextInputreqired onChange={handleStateChange} label="State" placeholder='Enter your state' />
                      {errors.state && <div className="text-danger">{errors.state}</div>}
                    </div>
                    <div className="col-md-6">
                      <TKN_TextInputreqired onChange={handleCityChange} label="City" placeholder='Enter your city' />
                      {errors.city && <div className="text-danger">{errors.city}</div>}
                    </div>
                    <div className="col-md-6">
                      <TKN_TextInputreqired onChange={handlePostalcodeChange} label="Zip Code" placeholder='Enter postal code' />
                      {errors.postcode && <div className="text-danger">{errors.postcode}</div>}
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="col-form-label">Country<span className="text-danger">*</span></label>
                        <CountryDropdown
                          value={selectedCountry}
                          onChange={handleCountryChange}
                          classes="form-control"
                          defaultOptionLabel="Select country"
                          priorityOptions={['United States']}
                        />
                        {errors.selectedCountry && <div className="text-danger">{errors.selectedCountry}</div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="acc-submit">
                <ButtonTextOnly_Colored
                  label={"Cancel"}
                  fullWidth={false}
                  style={{
                    color: '#0445cf',
                    backgroundColor: '#f8f8f8',
                    marginRight: '20px',
                    minWidth: '120px',
                    justifyContent: 'center'
                  }}
                  onClick={handleCancelButton}
                />
                <ButtonTextOnly_Colored
                  label={isLoading ? <Spinner style={{ width: '1.5rem', height: '1.5rem' }} /> : "Save"}
                  fullWidth={false}
                  style={{ minWidth: '120px', justifyContent: 'center' }}
                  disabled={isLoading}
                  onClick={handleSubmit} />
              </div>
            </div>
          </div>
        </div>
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{modalMessage}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleCloseModal}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <Footer />
    </>
  );
};

export default UserProfile;
