import React, { useEffect, useState } from 'react';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { useNavigate } from 'react-router-dom';
import './provider-sub.css';
import { ButtonTextOnly_Colored } from '../../../components/buttons/Buttons_colored';
import apiService from '../../../../api/authentication-service';
import { useDispatch, useSelector } from 'react-redux';
import { setServiceSubscriptionDetails } from '../../../../core/data/redux/action';
import { TKN_Danger_message, TKN_Success_message } from '../../../components/alert_messages/alert_messages';
import Loader from '../../../components/loader_spinner/loader_spinner';

const ProviderSubscription = () => {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const currentPackage = useSelector((state: any) => state.serviceSubscriptionDetails?.current_package ?? []);
  console.log('currentPackage', currentPackage);
  const currentServicesSubscriptionStatus = currentPackage?.service_subscription_status ?? 0;

  const [serverError, setServerError] = useState('');
  const [serviceSubscriptions, setServiceSubscriptions] = useState([]);
  const [responseMessage, setResponseMessage] = useState<string>('');
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<number | null>(null);
  const routes = all_routes;
  const [showModal, setShowModal] = useState(false);
  const [showloader, setShowLoader] = useState(true);

  useEffect(() => {
    sendGetAllServiceSubscriptionPackageRequest();
  }, []);

  const sendGetAllServiceSubscriptionPackageRequest = () => {
    setServerError('');
    setResponseMessage('');
    apiService.getAllServiceSubscriptionPackages()
      .then((response) => getAllSubscriptionPackageResponse(response?.data))
      .catch((error) => console.log('error', error));
  };

  const getAllSubscriptionPackageResponse = (response: any) => {
    if (response && response.status === true) {
      const allPackages = response?.data;
      setServiceSubscriptions(allPackages || []);
      setServerError('');
      setShowLoader(false)
    } else {
      setServiceSubscriptions([]);
      setServerError("Failed to get packages");
    }
  };

  const handleConfirmPurchase = () => {
    if (selectedSubscriptionId !== null) {
      sentPurchaseServiceSubscriptionRequest(selectedSubscriptionId);
      navigateTo(routes.vendorservice);
    }
    setShowModal(false);
  };

  const handleOK = (subscriptionId: number) => {
    setServerError('');
    setResponseMessage('');
    if (currentServicesSubscriptionStatus === 1) {
      setServerError("You already have an active subscription. Please upgrade or wait until it expires.");
      return;
    }
    else {

      setSelectedSubscriptionId(subscriptionId);
      setShowModal(true);
    }
  };

  const sentPurchaseServiceSubscriptionRequest = (subscriptionId: number) => {

    apiService.addServiceSubscription(serviceSubscriptionRequest(subscriptionId)).then((response) => {
      console.log('RESPONSE_DATA: ', response.data);

      purchaseServiceSubscriptionResponse(response?.data);
    }).catch((error) => {

      console.log('error', error);
    });
  }

  const vendorId = useSelector((state: any) => {
    console.log('VENDORID', state);
    return state.vendor.id
  });

  const serviceSubscriptionRequest = (subscriptionId: number) => ({
    vendor_id: vendorId,
    service_subscription_id: subscriptionId,
  });

  const purchaseServiceSubscriptionResponse = (response: any) => {
    if (response && response.status === true) {
      const currentPackage = response?.data ?? '';
      const s = dispatch(setServiceSubscriptionDetails(currentPackage));
      console.log('s', s);
      setResponseMessage(response.message || 'Package successfully subscribed and activated');
    } else {
      setServerError(response?.message || "Failed to activate package... Try after some time.");
    }
  };

  const handleSuccessmsgClose = () => {
    setResponseMessage('');
  }
  const handleServerErrorClose = () => {
    setServerError('');
  }

  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="widget-title">
                <h4>Subscription</h4>
              </div>
            </div>
            {responseMessage && (
              <TKN_Success_message text={responseMessage} onClick={handleSuccessmsgClose} />
            )}
            {serverError && (
              <TKN_Danger_message text={serverError} onClick={handleServerErrorClose} />
            )}
          </div>
          {/* Subscription */}
          <div className="row provider-price">
            <div className="col-md-12">
              <div className="choose-title text-center">
                <h6>Choose Plan</h6>
              </div>
            </div>
            {showloader ? (

              <Loader showloader={showloader} style={{ marginTop: '50px' }} />
            ) : (

              <React.Fragment>
                {serviceSubscriptions.map((subPackage: any, index) => {
                  const isActiveSubscription = currentPackage?.service_subscription_id === subPackage?.service_subscription_id;
                  const hasActiveSubscription = currentServicesSubscriptionStatus === 1;

                  return (
                    <div className="col-md-3 d-flex h-100" key={index}>
                      <div
                        className={`price-card flex-fill card-new-background ${isActiveSubscription ? 'highlighted' : ''}`}
                        style={isActiveSubscription ? { border: '2px solid grey', backgroundColor: 'rgba(244, 231, 231, 0.711)' } : {}}
                      >
                        <div className="price-head">
                          <div className="price-level">

                            <h1 ><span>{subPackage?.service_subscription_name}</span></h1>
                            {isActiveSubscription && <h1><span style={{ color: 'green' }}>Current Plan</span></h1>}

                          </div>
                          <h1> <h6 className='inline-new'>${subPackage?.service_subscription_amt}</h6> <span>/ month</span></h1>
                        </div>
                        <div className="price-body">
                          <ul>
                            <li className="active">{subPackage.services_count} Services</li>
                            <li className="active">{subPackage.service_subscription_duration} Month{(subPackage.service_subscription_duration > 1) ? 's' : ''} Validity</li>
                            <li className="active">Email Support</li>
                          </ul>
                          <div className="text-center">
                            <ButtonTextOnly_Colored
                              label={'Choose Plan'}
                              onClick={() => !hasActiveSubscription && handleOK(subPackage.service_subscription_id)}
                              route="#"
                              fullWidth={true}
                              className={isActiveSubscription ? 'button-disabled' : 'button-new-background'}
                              disabled={hasActiveSubscription && !isActiveSubscription}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
                }
              </React.Fragment>)}
          </div>
        </div>
      </div>
      {/* Modal */}
      {showModal && (
        <div className="custom-modal">
          <div className="custom-modal-content" style={{ width: '500px', height: '250px', marginTop: '100px' }}>
            <div className="widget-title">
              <h4>Confirm Purchase</h4>
            </div>
            <p>Are you sure want to purchase this package?</p>
            <div className="modal-actions">
              <button onClick={() => setShowModal(false)} className="btn btn-primary">Cancel</button>
              <button onClick={handleConfirmPurchase} className="btn btn-primary">OK</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProviderSubscription