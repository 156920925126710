import { APIRoutes } from './ApiRoutes';
import { apiClient } from './apiClient';

type Payload = Record<string, any>;

type LocationPayload = {
  city: string;
  zipcode: number;
};

interface ApiResponse<T = any> {
  status: boolean;
  data: T;
};

const apiService = {
  getAllCategories() { // Assuming no payload needed for GET request
    return apiClient.get<ApiResponse>(APIRoutes.getAllCategories);
  },
  listCategories() { // Assuming no payload needed for GET request
    return apiClient.get<ApiResponse>(APIRoutes.listCategories);
  },
  getAllSubcategories(payLoad: Payload) {
    return apiClient.post<ApiResponse>(APIRoutes.getAllSubcategories, payLoad);
  },
  getAllServices(payLoad: Payload) {
    return apiClient.post<ApiResponse>(APIRoutes.getAllServices, payLoad);
  },
  login(payLoad: Payload) {
    return apiClient.post<ApiResponse>(APIRoutes.login, payLoad);
  },
  getBusinessBasedOnLocation(payload: LocationPayload) {
    return apiClient.post<ApiResponse>(APIRoutes.getBusinessBasedOnLocation, payload);
  },
  getbusinessdetails(payLoad: Payload) {
    return apiClient.post<ApiResponse>(APIRoutes.getbusinessdetails, payLoad);
  },
  getReportedIssueCategories(payLoad: any) {
    return apiClient.post<ApiResponse>(APIRoutes.getReportedIssueCategories, payLoad);
  },
  AddReportAnIssue(payLoad: any) {
    return apiClient.post(APIRoutes.AddReportAnIssue, payLoad);
  },
  getServiceListbyId(payLoad: any) {
    return apiClient.post(APIRoutes.getServiceListbyId, payLoad);
  },
  forgotPassword(payLoad: any) {
    return apiClient.post(APIRoutes.forgotPassword, payLoad);
  },
  resetPassword(payLoad: any) {
    return apiClient.post(APIRoutes.resetPassword, payLoad);
  },
  changeOldPassword(payLoad: any) {
    return apiClient.post(APIRoutes.changeOldPassword, payLoad);
  },
  addFamilyMember(payLoad: any) {
    return apiClient.post(APIRoutes.addFamilyMember, payLoad);
  },
  getAllRelationships() {
    return apiClient.get(APIRoutes.getAllRelationships);
  },
  familyMembersUpdate(payLoad: any) {
    return apiClient.post(APIRoutes.familyMembersUpdate, payLoad);
  },
  getAllFamilyMembersDetailsById(payLoad: any) {
    return apiClient.post(APIRoutes.getAllFamilyMembersDetailsById, payLoad)
  },
  getUserFavouritesLimited(payLoad: any) {
    return apiClient.post<ApiResponse>(APIRoutes.getUserFavouritesLimited, payLoad);
  },
  getUserAllBookingsLimited(payLoad: any) {
    return apiClient.post<ApiResponse>(APIRoutes.userAllbookingsLimited, payLoad);
  },
  getUserTotalCountDetails(payLoad: any) {
    return apiClient.post(APIRoutes.getUserTotalCountDetails, payLoad);
  },
  getAvailableservicetime(payLoad: any) {
    return apiClient.post(APIRoutes.getAvailableservicetime, payLoad);
  },
  getAllactiveservices(payLoad: any) {
    return apiClient.post(APIRoutes.getAllactiveservices, payLoad);
  },
  getUserUpcomingBookingsLimited(payload: any) {
    return apiClient.post(APIRoutes.userUpcomingBookingsLimited, payload);
  },
  getAvailableSlots(payLoad: Payload) {
    return apiClient.post<ApiResponse>(APIRoutes.availableTimeSlot, payLoad);
  },
  getFamilyMembersList(payLoad: any) {
    return apiClient.post(APIRoutes.getFamilyMembersList, payLoad);
  },
  checkfamilymember(payLoad: any) {
    return apiClient.post(APIRoutes.checkfamilymember, payLoad);
  },
  familyMembersDelete(payLoad: any) {
    return apiClient.post(APIRoutes.familyMembersDelete, payLoad);
  },
  getAllFamilyMembersByUserId(payLoad: Payload) {
    return apiClient.post<ApiResponse>(APIRoutes.getAllFamilyMembersByUserId, payLoad);
  },
  getUserFavourites(payLoad: any) {
    return apiClient.post(APIRoutes.getUserFavourites, payLoad)
  },
  deleteUserFavourites(payLoad: any) {
    return apiClient.post(APIRoutes.deleteUserFavourites, payLoad)
  },
  getAllServiceSubscriptionPackages() {
    return apiClient.get(APIRoutes.getAllServiceSubscriptionPackages);
  },
  addServiceSubscription(payLoad: any) {
    return apiClient.post(APIRoutes.addServiceSubscription, payLoad);
  },
  addBookings(payLoad: Payload) {
    return apiClient.post<ApiResponse>(APIRoutes.addBookings, payLoad);
  }, getServicesListByVendorId(payLoad: Payload) {
    return apiClient.post<ApiResponse>(APIRoutes.getServicesListByVendorId, payLoad);
  },
  getServiceDetailsById(payLoad: any) {
    return apiClient.post<ApiResponse>(APIRoutes.getServiceDetailsById, payLoad);
  },
  serviceStatusUpdation(payLoad: any) {
    return apiClient.post<ApiResponse>(APIRoutes.serviceStatusUpdation, payLoad)
  },
  addWalkingbooking(payLoad: any) {
    return apiClient.post<ApiResponse>(APIRoutes.addWalkingbooking, payLoad)
  },
  registerVendor(payLoad: Payload) {
    return apiClient.post<ApiResponse>(APIRoutes.registerVendor, payLoad);
  },
  registerotp(payLoad: Payload) {
    return apiClient.post<ApiResponse>(APIRoutes.registerotp, payLoad);
  },
  addProfile(payLoad: Payload) {
    return apiClient.post<ApiResponse>(APIRoutes.addProfile, payLoad);
  },
  apiAddService(payLoad: any) {
    return apiClient.post<ApiResponse>(APIRoutes.apiAddService, payLoad)
  },
  updateService(payLoad: any) {
    return apiClient.post<ApiResponse>(APIRoutes.updateService, payLoad)
  },
  getVendorTotalCountDetails(payload: any) {
    return apiClient.post(APIRoutes.getVendorTotalCountDetails, payload);
  },
  getVendorActiveServicesDetails(payload: any) {
    return apiClient.post(APIRoutes.getVendorActiveServicesDetails, payload);
  },
  getVendorAllBookingsDetails(payload: any) {
    return apiClient.post(APIRoutes.getVendorAllBookingsDetails, payload);
  },
  updatePersonalDetails(payLoad: any) {
    return apiClient.post<ApiResponse>(APIRoutes.updatePersonalDetails, payLoad);
  },
  updateProfilePhoto(payLoad: any) {
    return apiClient.post<ApiResponse>(APIRoutes.updateProfilePhoto, payLoad);
  },
  getVendorDetails(payLoad: Payload) { // Assuming no payload needed for GET request
    return apiClient.post<ApiResponse>(APIRoutes.getVendorDetails, payLoad);
  },
  updateBusinessDetails(payLoad: any) {
    return apiClient.post<ApiResponse>(APIRoutes.updateBusinessDetails, payLoad)
  },
  deleteService(payLoad: any) {
    return apiClient.post<ApiResponse>(APIRoutes.deleteService, payLoad)
  },
  saveVendordetails(payLoad: any) {
    return apiClient.post<ApiResponse>(APIRoutes.saveVendordetails, payLoad)
  },
  updatebookStatus(payLoad: any) {
    return apiClient.post<ApiResponse>(APIRoutes.updatebookStatus, payLoad);
  },
  getAllServiceBookingsById(payLoad: any) {
    return apiClient.post<ApiResponse>(APIRoutes.getAllServiceBookingsById, payLoad);
  },
  getBusinessByCategoryAndSubcategory(payLoad: any) {
    return apiClient.post<ApiResponse>(APIRoutes.getBusinessByCategoryAndSubcategory, payLoad)
  },
  checkVendoraccount(payLoad: any) {
    return apiClient.post<ApiResponse>(APIRoutes.checkVendoraccount, payLoad)
  },
  deleteAccount(payLoad: any) {
    return apiClient.post<ApiResponse>(APIRoutes.deleteAccount, payLoad)
  },
  checkUseraccount(payLoad: any) {
    return apiClient.post<ApiResponse>(APIRoutes.checkUseraccount, payLoad)
  },
  checkvendordeactstatus(payLoad: any) {
    return apiClient.post<ApiResponse>(APIRoutes.checkvendordeactstatus, payLoad)
  },
  qrCodeRequest(payLoad: any) {
    return apiClient.post<ApiResponse>(APIRoutes.qrCodeRequest, payLoad)
  },
  cancelUserBookings(payload: any) {
    return apiClient.post<ApiResponse>(APIRoutes.cancelUserBookings, payload)
  },
  getRelatedBusiness(payload: any) {
    return apiClient.post<ApiResponse>(APIRoutes.getRelatedBusiness, payload)
  },
  checkFavouriteStatus(payload: any) {
    return apiClient.post<ApiResponse>(APIRoutes.checkFavouriteStatus, payload)
  },
  addUpdateFavourites(payload: any) {
    return apiClient.post<ApiResponse>(APIRoutes.addUpdateFavourites, payload)
  },
  ResendOtp(payLoad: any) {
    return apiClient.post(APIRoutes.resendOtp, payLoad);
  },
  reactivateUseraccount(payLoad: any) {
    return apiClient.post(APIRoutes.reactivateUseraccount, payLoad);
  },
  contactUsMail(payload: any) {
    return apiClient.post<ApiResponse>(APIRoutes.contactUsMail, payload);
  },
};
export default apiService;