import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducer';
import { loadState, saveState } from './localStorage';


// function onStateChange() {
//   saveToLocalStorage(store.getState());
// }
// store.subscribe(onStateChange);
// Initial state

/* const preloadedState  = localStorage.getItem('userDetails')
    ? { user: JSON.parse(localStorage.getItem('userDetails')) }
    : {}; */

    const persistedState = loadState();

    const store = configureStore({
      reducer: rootReducer,
      preloadedState: persistedState,
    });
    
    store.subscribe(() => {
      saveState(store.getState());
    });

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export default store;
