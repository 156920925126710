import React from 'react';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import { IconProps } from '../../../core/models/interface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWalking } from '@fortawesome/free-solid-svg-icons';

const TKN_texticon = (props: IconProps) => {
    const renderEmails = () => {
        if (typeof props.emails === 'string') {
            return (
                <p className="mb-0">
                    <span>
                        {props.emailicon && <props.emailicon className="react-feather-custom" />}
                    </span>{' '}
                    <a href={`mailto:${props.emails}`} className="email-link" style={{ color: '#74788d' }}>
                        {props.emails}
                    </a>
                </p>
            );
        } else {
            return props.emails.map((email, index) => (
                <p className="mb-0" key={index}>
                    <span>
                        {props.emailicon && <props.emailicon className="react-feather-custom" />}
                    </span>{' '}
                    <a href={`mailto:${email}`} className="email-link" style={{ color: '#74788d' }}>
                        {email}
                    </a>
                </p>
            ));
        }
    };

    return (
        <div className="col-lg-3 col-md-6">
            <div className="footer-widget footer-contact">
                <h2 className="footer-title">{props.title}</h2>
                <div className="footer-contact-info">
                    <div className="footer-address">
                        <p>
                            <span>
                                {props.addressicon && <props.addressicon className="react-feather-custom" />}
                            </span>{' '}
                            {props.address}
                        </p>
                    </div>
                    {renderEmails()}
                </div>
            </div>
        </div>
    );
};



export default TKN_texticon
