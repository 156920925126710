import './header.css'
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../core/data/redux/action';
import { AppState } from '../../../core/models/interface';
import { RootState } from '../../../core/data/redux/store';
import { WEB_BASE_URL } from '../../../constants/constants';
import { AppDispatch } from '../../../core/data/redux/store';
import { all_routes } from '../../../core/data/routes/all_routes';
import { ButtonTextandIconReverse_Colored } from '../buttons/button';
import { set_header_data, set_toggleSidebar_data } from '../../../core/data/redux/action';
import { ImageWithBasePath, ImageWithBasePathWithUrl } from '../../../core/img/ImageWithBasePath';

type props = {
  type: number;
};

const HomeHeader: React.FC<props> = ({ type }) => {
  const routes = all_routes;
  const dispatch: AppDispatch = useDispatch();
  const navigateTo = useNavigate();
  const user = useSelector((state: RootState) => state.user);
  const vendor = useSelector((state: RootState) => state.vendor);
  const toggle_data = useSelector((state: AppState) => state.toggleSidebar);
  const isEmpty = (value: any) => value === undefined || value === null || value === 0 || value === '';
  const [scrollYPosition, setScrollYPosition] = useState<number>(0);
  const [imageUrl, setImageUrl] = useState({
    logo: '',
    logoSmall: '',
    logoSvg: '',
  });
  const defaultimg = { src: 'assets/img/profiles/avatar-01.avif' }


  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem('UserDetails');
    if (vendor) {
      localStorage.removeItem('VendorDetails');
    }
    navigateTo('../authentication/login');
  };

  const toogle = () => {
    dispatch(set_toggleSidebar_data(toggle_data ? false : true));
  };

  const setHeaderData = () => {
    dispatch(set_header_data([]));
  };

  const handleScroll = () => {
    setScrollYPosition(scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    type == 1 || type == 4 || type == 10
      ? setImageUrl({
        logo: 'assets/img/TKN_images/TestingLogo.png',
        logoSmall: 'assets/img/TKN_images/logo-small.png',
        logoSvg: 'assets/img/logo.svg',
      })
      : setImageUrl({
        logo: 'assets/img/logo-02.svg',
        logoSmall: 'assets/img/logo-icon.png',
        logoSvg: 'assets/img/logo-02.svg',
      });
  }, [type]);

  return (
    <header className={`header header-one ${scrollYPosition > 100 ? 'fixed' : ''}`}>
      <div className={` ${type == 3 ? 'container-fluid' : 'container'}`}>
        <nav className="navbar navbar-expand-lg header-nav">
          <div className="navbar-header">
            <Link onClick={toogle} id="mobile_btn" to="#">
              <span className="bar-icon">
                <span />
                <span />
                <span />
              </span>
            </Link>
            <Link to={'/'} className="navbar-brand logo ">
              <ImageWithBasePath
                src={imageUrl.logo}
                className="img-fluid max-height-logo"
                alt="Logo"
              />
            </Link>
            <Link
              to={routes.homeOne}
              className="navbar-brand logo-small"
            >
              <ImageWithBasePath
                src={imageUrl.logoSmall}
                className="img-fluid"
                alt="Logo"
              />
            </Link>
          </div>
          <div className="main-menu-wrapper">
            <div className="menu-header">
              <Link to={routes.homeOne} className="menu-logo">
                <ImageWithBasePath
                  src={imageUrl.logoSvg}
                  className="img-fluid"
                  alt="Logo"
                />
              </Link>
              <Link
                onClick={toogle}
                id="menu_close"
                className="menu-close"
                to="#"
              >
                {' '}
                <i className="fas fa-times" />
              </Link>
            </div>
            <ul className="main-nav">
              <li>
                <Link to={'/'}>Home</Link>
              </li>
              <li>
                <Link to={routes.aboutUs}>About</Link>
              </li>
              <li>
                <Link to={routes.contactUs}>Support</Link>
              </li>
              <li>
                <Link to={routes.faq}>FAQ</Link>
              </li>
              <li>
                <Link to={routes.search}>Search</Link>
              </li>
            </ul>
          </div>
          <ul className="nav header-navbar-rht">
            {isEmpty(user.user_id) ? (
              <>
                <li className="nav-item">
                  <Link
                    className="nav-link header-reg"
                    to="/authentication/choose-signup"
                  >
                    Register
                  </Link>
                </li>
                <li onClick={setHeaderData} className="nav-item">
                  <ButtonTextandIconReverse_Colored
                    label={'Login'}
                    icon={'Users'}
                    route={'/authentication/login'}
                    fullWidth={false} />
                </li>
              </>
            ) : !isEmpty(user.user_id) && isEmpty(vendor.id) ? (
              <li className="nav-item dropdown has-arrow account-item">
                <Link
                  to="#"
                  className="dropdown-toggle nav-link"
                  data-bs-toggle="dropdown"
                >
                  <div className="user-infos">
                    {user.profilephoto ? (
                      <span className="user-img">
                        <ImageWithBasePathWithUrl
                          src={`${WEB_BASE_URL}${user.profilephoto}`}
                          className="rounded-circle"
                          alt="User Image"
                          style={{ objectFit: 'cover' }}
                        />
                      </span>
                    ) : (<span className="user-img">
                      <ImageWithBasePath
                        src={defaultimg.src}
                        className="rounded-circle"
                        alt="User Image"
                        style={{ objectFit: 'cover' }}
                      />
                    </span>)}
                    <div className="user-info">
                      <h6>{user.first_name}</h6>
                    </div>
                  </div>
                </Link>
                <div className="dropdown-menu dropdown-menu-end emp">
                  <Link
                    className="dropdown-item"
                    to={routes.customerDashboard}
                  >
                    <i className="feather-user me-2" /> Dashboard
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={routes.customerEditProfile}
                  >
                    <i className="feather-user me-2" /> Profile
                  </Link>
                  <Link className="dropdown-item" onClick={(e) => { e.preventDefault(); handleLogout(); }} to={''}>
                    <i className="feather-log-out me-2" /> Logout
                  </Link>
                </div>
              </li>
            ) : (
              <li className="nav-item dropdown has-arrow account-item">
                <Link
                  to="#"
                  className="dropdown-toggle nav-link"
                  data-bs-toggle="dropdown"
                >
                  <div className="user-infos">
                    {user.profilephoto ? (

                      <span className="user-img">
                        <ImageWithBasePathWithUrl
                          src={`${WEB_BASE_URL}${user.profilephoto}`}
                          className="rounded-circle"
                          alt="User Image"
                          style={{ objectFit: 'cover' }}
                        />
                      </span>
                    ) : (

                      <span className="user-img">
                        <ImageWithBasePath
                          src={defaultimg.src}
                          className="rounded-circle"
                          alt="User Image"
                          style={{ objectFit: 'cover' }}
                        />
                      </span>
                    )}
                    <div className="user-info">
                      <h6>{user.first_name}</h6>
                      <p>{vendor.bussiness_name}</p>
                    </div>
                  </div>
                </Link>
                <div className="dropdown-menu dropdown-menu-end emp">
                  <Link
                    className="dropdown-item"
                    to={routes.vendorDashboard}
                  >
                    <i className="feather-user me-2" /> Dashboard
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={routes.vendorAppointmentSettings}
                  >
                    <i className="feather-user me-2" /> Profile
                  </Link>
                  <Link className="dropdown-item" onClick={(e) => { e.preventDefault(); handleLogout(); }} to={''}>
                    <i className="feather-log-out me-2" /> Logout
                  </Link>
                </div>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default HomeHeader;