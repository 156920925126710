import React, { useEffect, useState } from 'react';
import { all_routes } from '../../../../core/data/routes/all_routes';
import './style.css'
import { SearchBar, TKN_DropdownInput, TKN_TextareaPlaceholder } from '../../../components/inputfields/input';
import apiService from '../../../../api/authentication-service';
import { useDispatch, useSelector } from 'react-redux';
import { validateDropdown, validateEmptyValue } from '../../../../helper/validate';
import en from '../../../../en/en';
import { CUSTOMER_APP } from '../../../../constants/constants';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { setFamilyMemberDetails } from '../../../../core/data/redux/action';
import TKN_modal from '../../../components/modal/modal';
import { TKN_Danger_message, TKN_Success_message } from '../../../components/alert_messages/alert_messages';
import Loader from '../../../components/loader_spinner/loader_spinner';


const CustomerAddMemberlisting = () => {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [memberId, setMemberId] = useState('');
    const [searchQuery, setSearchQuery] = React.useState<any>();
    const [showModal, setShowModal] = useState(false);
    const [showModal1, setShowModal1] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalMessage, setModalMessage] = useState('');
    const [status, setStatus] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [serverError, setServerError] = useState('');
    const [showloader, setShowLoader] = useState<boolean>(true);



    const routes = all_routes;
    const navigateTo = useNavigate();

    const userId = useSelector((state: any) => {
        return state.user.user_id
    });

    useEffect(() => {
        sendFamilyMembersListRequest();
    }, []);

    const sendFamilyMembersListRequest = () => {

        apiService.getFamilyMembersList(familyMembersListRequest())
            .then((response) => {

                const responseData = response?.data;
                const userMembers = responseData.data;
                setData(userMembers);
                setOriginalData(userMembers);
                setShowLoader(false)
            })
            .catch((error) => {
                console.error('Error fetching family members list:', error);
                setServerError('An error occurred while fetching family members.');
            });
    };

    const familyMembersListRequest = () => ({

        user_id: userId
    });
    console.log('USERID', userId);

    const handleDelete = async (familyMemberId: string) => {

        if (familyMemberId) {
            try {

                await checkFamilyMemberRequest(familyMemberId);
                setMemberId(familyMemberId);
            } catch (error) {

                console.error("Error checking family member:", error);
                setServerError('An error occurred while checking the family member. Please try again later.');
            }
        }
    };

    const checkFamilyMemberRequest = async (familyMemberId: any) => {
        try {
            const response = await apiService.checkfamilymember(familyMembersDeleteRequest(familyMemberId))
            const responseData = response?.data;
            if (responseData) {

                const status = responseData?.status;
                if (status === true) {

                    setShowModal(true);

                } else {

                    setShowModal1(true);

                }
            }
        }
        catch (error) {

            console.error('Error checking family member:', error);
            setServerError('An error occurred while checking the family member.');
        }
    };

    const sendDeleteFamilyMemberRequest = (familyMemberId: any) => {

        apiService.familyMembersDelete(familyMembersDeleteRequest(familyMemberId))
            .then((response) => {

                const responseData = response?.data;
                // setLoading(false);
                deleteFamilyMemberResponse(responseData)
                window.location.reload();
            })
            .catch((error) => {

                console.error('Error deleting family member:', error);
                setServerError('An error occurred while deleting the family member.');
            });
    };

    const deleteFamilyMemberResponse = (response: any) => {

        if (response) {

            const Status = response?.status;

            if (Status === true) {

                setResponseMessage('Deleted member succesfully')

                sendFamilyMembersListRequest();
            }
        }
    };

    const familyMembersDeleteRequest = (familyMemberId: any) => ({

        family_member_id: familyMemberId
    });

    const handleSearchQuery = (text: string) => {

        setSearchQuery(text);
        const trimmedText = text.trim();
        if (trimmedText === '') {

            setData(originalData);
        } else {

            const filtered = originalData.filter((item: any) =>
                item.family_member_name.toLowerCase().includes(trimmedText.toLowerCase())
            );
            setData(filtered);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    const handleCloseshowModal1 = () => {
        setShowModal1(false);
    };

    const handleSuccessButton = (familyMemberId: any) => {
        setShowModal(false);
        sendDeleteFamilyMemberRequest(familyMemberId);
    };

    const navigateToEditAddFamily = async (familyMemberId: any) => {

        const selectedFamilyMember = await fetchFamilyMemberDetails(familyMemberId);

        if (selectedFamilyMember) {

            dispatch(setFamilyMemberDetails(familyMemberId));
            navigateTo(routes.customerEditFamily);
        } else {

            console.error(`Family member with ID ${familyMemberId} not found.`);
        }
    };

    const fetchFamilyMemberDetails = (familyMemberId: any): any => {

        const selectedFamilyMember = data.find((member: any) => member.family_member_id === familyMemberId);
        return selectedFamilyMember;
    };

    const handleSuccessButtomClose = () => {
        setResponseMessage('')
    }

    const handleServerErrorClose = () => {
        setServerError('')
    }
    return (
        <div className="provider-body">
            <div className="main-wrapper">
                <div className="page-wrapper">
                    <div className="content container-fluid" style={{ width: '95%' }}>
                        <div className="col-lg-6" style={{ width: '95%' }}>
                            <div className="widget-title">
                                {serverError && (<TKN_Danger_message text={serverError} onClick={handleServerErrorClose} />)}
                                {responseMessage && (<TKN_Success_message text={responseMessage} onClick={handleSuccessButtomClose} />
                                )}
                                <h4>Family Members</h4>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <SearchBar placeholderText={'Search'} icon={'Search'} style={{ color: '#0445CF' }} className='form-control-new' value={searchQuery} onChangeText={handleSearchQuery} />
                                <div className="col-md-6 text-md-end">
                                    <Link to={routes.customerAddFamily} className="btn btn-primary btn-wallet">
                                        <i className="feather-plus" style={{ color: 'white' }}></i> Add Family Member
                                    </Link>
                                </div>
                            </div>
                            <div style={{ width: '100%', height: '540px', boxSizing: 'border-box' }}>
                                <div className="col-md-12" style={{ height: '233px', justifyContent: 'space-between', gap: 35, flexFlow: 'wrap' }}>
                                    <div className='row' style={{ width: '100%', marginLeft: 0, justifyContent: 'space-between' }}>
                                        {(showloader) ? (
                                            <Loader showloader={showloader} style={{ marginTop: '50px' }} />
                                        ) : (
                                            <React.Fragment>
                                                {(data && data.length > 0) ?
                                                    (<React.Fragment>

                                                        {(data.map((member: any, index: any) => (
                                                            <div key={index} className="col-md-6" style={{
                                                                height: '230px',
                                                                width: '48%',  // Adjusted width for two cards per row with space between them
                                                                marginBottom: '20px', // Adds space between the rows
                                                                boxShadow: 'rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px',
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                borderColor: '#0445CF',
                                                                borderRadius: 10,
                                                            }}>
                                                                <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <div style={{
                                                                        background: '#0445CF', width: '90px', height: '90px', borderRadius: '10%', border: '2px solid #fff',
                                                                        outline: '4px solid rgba(0, 0, 0, 0.07)', display: 'flex', justifyContent: 'center', alignItems: 'center'
                                                                    }}>
                                                                        <i className="fa-solid fa-user" style={{ fontSize: '50px', color: 'white' }}></i>
                                                                    </div>
                                                                </div>
                                                                <div style={{ width: '50%', boxSizing: 'border-box', display: 'flex', justifyContent: 'center', alignItems: 'left', flexDirection: 'column' }}>
                                                                    <p style={{ color: 'black', fontWeight: '500', fontSize: '18px' }}>{member.family_member_name.length > 20 ? `${member.family_member_name.slice(0, 20)}...` : member.family_member_name}
                                                                    </p>
                                                                    <div style={{ display: 'flex' }}>
                                                                        <i className="fa-solid fa-phone"></i>
                                                                        <a
                                                                            href={`tel:${member.phone}`}
                                                                            className="phone-link"
                                                                            style={{
                                                                                marginLeft: '6px',
                                                                                marginTop: '-5px',
                                                                                textDecoration: 'none',
                                                                                color: 'inherit' // Ensures the link doesn't change the text color
                                                                            }}
                                                                        >
                                                                            <p> {member.phone} </p>
                                                                        </a>
                                                                    </div>
                                                                    <div style={{ display: 'flex' }}>
                                                                        <i className="fa-solid fa-envelope"></i>
                                                                        <a
                                                                            href={`mailto:${member.email}`}
                                                                            className="phone-link"
                                                                            style={{
                                                                                marginLeft: '6px',
                                                                                marginTop: member.email ? '-5px' : '15px',  // Adjust margin based on whether email exists
                                                                                textDecoration: 'none',
                                                                                color: 'inherit'  // Ensures the link doesn't change the text color
                                                                            }}
                                                                        >
                                                                            <p> {member.email.length > 20 ? `${member.email.slice(0, 20)}...` : member.email} </p>
                                                                        </a>
                                                                    </div>
                                                                    <div style={{ display: 'flex' }}>
                                                                        <i className="fa-solid fa-trash" style={{ marginTop: '3px', color: '#0445cf', }}></i>
                                                                        <p style={{ marginLeft: '5px', color: '#0445cf', cursor: 'pointer' }} onClick={() => handleDelete(member.family_member_id)}>Delete</p>
                                                                        <i className="fa-solid fa-pen-to-square" style={{ marginTop: '4px', marginLeft: '80px', color: '#0445CF' }}></i>
                                                                        <p style={{ marginLeft: '5px', color: '#0445CF', cursor: 'pointer' }} onClick={() => navigateToEditAddFamily(member.family_member_id)}>Edit</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )))}
                                                    </React.Fragment>
                                                    ) : (
                                                        <div className='row' style={{ height: '514px' }}>
                                                            <div className='no-favorites-container justify-content-center d-flex align-items-center'>
                                                                <h1 style={{ color: '#adb5bd' }}>No Members Found</h1>
                                                            </div>
                                                        </div>
                                                    )}
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mouse-cursor cursor-outer"></div>
                <div className="mouse-cursor cursor-inner"></div>
            </div>
            {/* Delete Service */}
            <TKN_modal title={'Delete member'} description={'Are you sure want to delete?'} okbutton={'OK'} handleshowModal={showModal} handleSuccessButton={() => handleSuccessButton(memberId)} cancelbutton={'Cancel'} handleCancelButton={handleCloseModal} />
            <TKN_modal title={'Delete member'} description={'The member has an active booking. Please cancel the booking and then delete the member'} okbutton={'OK'} handleshowModal={showModal1} handleSuccessButton={handleCloseshowModal1} />
        </div>
    );
};


export default CustomerAddMemberlisting;