import React, { useEffect, useState } from 'react';
import { all_routes } from '../../../../core/data/routes/all_routes';
import apiService from '../../../../api/authentication-service';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../../../../core/data/redux/store';
import en from '../../../../en/en';
import './style.css'
import { logout } from '../../../../core/data/redux/action';
import { TKN_Danger_message } from '../../../components/alert_messages/alert_messages';

const ProviderDeleteAccount = () => {
    const routes = all_routes;
    const vendor_obj = useSelector((state: RootState) => state.vendor);
    const [statusbookData, setstatusbookData] = useState<number | null>(null);
    const [deleteStatusValue, setDeleteStatusValue] = useState<number | null>(null);
    const [deleteMessage, setDeleteMessage] = useState("");
    const [deleteModal, setDeleteModal] = useState(false);
    const user_obj = useSelector((state: RootState) => state.user);
    const dispatch: AppDispatch = useDispatch();
    const [successModal, setSuccessModal] = useState(false);
    const [serverError, setServerError] = useState('');
    const navigateTo = useNavigate();

    useEffect(() => {
        UpdatebookingStatus();
        handleDeleteAccount();
    });

    const viewstatusdata = async (response: any) => {
        if (response) {
            const status = response?.status;
            const message = response?.message;
            const responseData = await response.data;
            setstatusbookData(responseData);
        } else {
            console.log('Error:', response?.data?.error || 'Unknown Error');
        }
    };

    const UpdatebookingStatus = () => {
        const payload = {
            vendor_id: vendor_obj.id,
        };
        apiService.checkVendoraccount(payload)
            .then((response) => {
                viewstatusdata(response?.data);
                console.log('responseData:', response);
            })
            .catch((error) => {
                console.log('An error occurred while processing your request. Please try again.')
            });
    };

    const handleBookingCancel = () => {
        navigateTo(routes.vendorBooking);
    }

    const closeModal = () => {
        setDeleteModal(false);
        navigateTo(routes.vendorDashboard);
    };

    const closeSuccessModal = () => {

        setSuccessModal(false);
        dispatch(logout());
        navigateTo(routes.login);
    };
    const sendDeleteAccountRequest = () => {

        const payload = {
            user_id: user_obj.user_id,
        };
        console.log('API request payload delete:', payload);
        apiService.deleteAccount(payload)
            .then((response) => {
                console.log('UpdateVendorStatus: ', response?.data);
                const responseData = response?.data;
                if (responseData) {
                    setSuccessModal(true);
                }
                else {
                    console.log('Deletion Failed')
                }
            })
            .catch((error) => {
                console.log('An error occurred while processing your request. Please try again.')
            });
    };

    const handleDeleteAccount = async () => {
        try {
            UpdatebookingStatus();
            if (statusbookData === 1) {
                setDeleteStatusValue(statusbookData);
                setDeleteMessage(en.DELETE_PAST_ACTIVE_BOOKINGS);
                setDeleteModal(true);
            } else if (statusbookData === 2) {

                setDeleteStatusValue(statusbookData);
                setDeleteMessage(en.DELETE_PAST_BOOKINGS);
                setDeleteModal(true);
            } else if (statusbookData === 3) {

                setDeleteStatusValue(statusbookData);
                setDeleteMessage(en.DELETE_ACTIVE_BOOKINGS);
                setDeleteModal(true);
            } else if (statusbookData === 0) {

                setDeleteStatusValue(statusbookData);
                setDeleteMessage(en.DELETE_ACCOUNT_DEFAULT);
                setDeleteModal(true);
            } else {

                console.log("invalid booking status data");
            }
        } catch (error) {

            console.error('Error fetching statusbookData:', error);
        }
    };

    return (
        <div className="provider-body">
            <div className="main-wrapper">
                {deleteModal && (
                    <div className="custom-modal">
                        <div className="custom-modal-content" style={{ backgroundColor: 'white', width: '600px', height: '250px' }}>
                            <div className="widget-title" style={{ marginTop: '20px' }}>
                                <h4>Delete Account</h4>
                            </div>
                            <span className="close" onClick={closeModal}>&times;</span>
                            <p>{deleteMessage}</p>
                            <div className="modal-actions">
                                {(deleteStatusValue === 2 || deleteStatusValue === 0) ? (
                                    <>
                                        <button className="btn btn-primary" onClick={closeModal}>
                                            Cancel
                                        </button>
                                        <button className="btn btn-primary" onClick={() => sendDeleteAccountRequest()}>
                                            OK
                                        </button></>
                                ) : (
                                    <button className="btn btn-primary" style={{ width: '100%' }} onClick={handleBookingCancel}>
                                        OK
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                {successModal && (
                    <div className="custom-modal">
                        <div className="custom-modal-content" style={{ width: '500px', height: '250px', marginTop: '100px' }}>
                            <div className="widget-title">
                                <h4>Account Deleted</h4>
                            </div>
                            <p>{en.DELETE_ACCOUNT_MESSAGE}</p>
                            <div className="modal-actions">
                                <button className="btn btn-primary" onClick={closeSuccessModal}>
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                <div className="mouse-cursor cursor-outer"></div>
                <div className="mouse-cursor cursor-inner"></div>
            </div>
        </div>
    );
};
export default ProviderDeleteAccount;
