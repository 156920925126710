import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PagesAuthHeader from './common/header';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { TKN_EmailInputwithcheck, TKN_PasswordInput, TKN_PhoneInputComponent } from '../../../components/inputfields/input';
import { validateMobileNumber } from '../../../../helper/mobileNumberValidatorHelper';
import { validatepassword_ConfirmPassword, validatePassword_ConfirmPassword } from '../../../../../src/helper/validate';
import apiService from '../../../../api/authentication-service';
import { WEB_USER_ROLE_VENDOR } from '../../../../constants/constants';
import { setVendorEmail } from '../../../../core/data/redux/action';
import en from '../../../../en/en';
import { Spinner } from 'react-bootstrap';

interface FormErrors {
  email?: string;
  password?: string;
  general?: string;
  phone?: string;
  confirmPassword?: string;
}

const maxLength = 15;

const ProviderSignup = () => {
  const routes = all_routes;
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState<string>('');
  const [countryCode, setCountryCode] = useState<string>('');
  const [countryName, setCountryName] = useState<string>('');
  const [countryDetails, setCountryDetails] = useState<string>('');
  const [errors, setErrors] = useState<FormErrors>({});
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordResponse, setPasswordResponse] = useState({
    passwordResponseText: '',
    passwordResponseKey: '',
  });
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [signUpTermsError, setSignUpTermsError] = useState('');

  const handlePhonechange = (value: any, formattedDialCode: any, countryname: any, countrydetails: any) => {
    setPhone(value);
    setCountryCode(formattedDialCode);
    setCountryName(countryname);
    setCountryDetails(countrydetails);

    if (value && errors.phone) {
      setErrors((prevErrors) => ({ ...prevErrors, phone: undefined }));
    }
  };

  const onChangePassword = (password: string) => {
    setPassword(password);

    if (password.length <= 0) {
      setPasswordResponse({
        passwordResponseText: '',
        passwordResponseKey: '',
      });
    } else if (password.length < 8) {
      setPasswordResponse({
        passwordResponseText: en.PASSWORD_CONDITION,
        passwordResponseKey: '0',
      });
    } else if (
      password.search(/[a-z]/) < 0 ||
      password.search(/[A-Z]/) < 0 ||
      password.search(/[0-9]/) < 0
    ) {
      setPasswordResponse({
        passwordResponseText: en.PASSWORD_CONDITION,
        passwordResponseKey: '1',
      });
    } else if (password.search(/(?=.*?[#?!@$%^&*-])/) < 0) {
      setPasswordResponse({
        passwordResponseText: en.PASSWORD_CONDITION,
        passwordResponseKey: '2',
      });
    } else {
      // All conditions met, clear the message
      setPasswordResponse({
        passwordResponseText: '',
        passwordResponseKey: '3',
      });
    }
  };

  const validateForm = (): boolean => {
    const newErrors: FormErrors = {};
    if (!email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Please enter a valid email only';
    }
    if (!phone) {
      newErrors.phone = 'Phone number is required';
    }
    if (!phone) {
      newErrors.phone = 'Phone number is required';
    }
    else if (!validateMobileNumber(countryName, phone)) {
      newErrors.phone = 'Invalid phone number for the selected country';
    }
    const ispasswordValid = validatepassword_ConfirmPassword(password, confirmPassword, 'Password');
    if (ispasswordValid["status"] === "false") {
      setPasswordError(ispasswordValid["passworderror"]);
      setConfirmPasswordError(ispasswordValid["confirm_passworderror"]);
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const handleLoginClick = async (e: any) => {
    e.preventDefault(); // Prevents default form submission behavior
    if (validateForm()) {

      if (isChecked === true) {
        setIsLoading(true);
        setSignUpTermsError("");
        sendRegisterRequest();
      }
      else {
        setSignUpTermsError(en.SIGNUP_TERMS);
        console.log("Please accept the terms and conditions.");
      };
    } else {
      setSignUpTermsError(en.SIGNUP_TERMS);
      console.log("Please accept the terms and conditions.");
    };
  };

  const sendRegisterRequest = () => {
    const payload = signupRequest();

    apiService.registerVendor(payload)
      .then((response: any) => {
        getsignupResponse(response?.data);
      })
      .catch((error: any) => {
        console.error('Error fetching User details', error);
        setErrors({ general: 'Failed to fetch user details' });
      });
  };
  const signupRequest = () => ({
    email: email.toLowerCase(),
    phone: phone,
    country_code: countryCode,
    country_details: JSON.stringify(countryDetails),
    country: countryName,
    password: password,
    c_password: confirmPassword,
    userrole: WEB_USER_ROLE_VENDOR,
  });

  const getsignupResponse = (response: any) => {
    setIsLoading(false);

    if (response) {
      const status = response?.status;

      if (status && status === true) {
        const userId = response?.data?.userid;
        const user_emaildetails = response?.data;

        if (userId) {
          dispatch(setVendorEmail(user_emaildetails));
          navigateTo(routes.emailOtp);
        };
      } else {
        console.error('Error fetching User details', response?.message);
        setErrors({ general: response?.message });
      };
    };
  };

  return (
    <>
      <PagesAuthHeader />
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-6 mx-auto">
              <div className="login-wrap">
                <div className="login-header">
                  <h3>Vendor Signup</h3>
                </div>
                {errors.general && <div className="error" style={{ color: 'red' }}>{errors.general}</div>}
                {/* Signup Form */}
                <form>

                  <div className="form-group">
                    <TKN_EmailInputwithcheck
                      label='Email'
                      onChange={(value: string) => {
                        setEmail(value);
                        if (errors.email) {
                          setErrors((prevErrors) => ({ ...prevErrors, email: undefined }));
                        }
                      }}
                      required={true}
                    />
                    {errors.email && <div className="error" style={{ color: 'red' }}>{errors.email}</div>}
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <div /* style={{ width: "100%", maxWidth: "100%" }} */>
                          <TKN_PhoneInputComponent onChange={handlePhonechange} required={true} />
                          {errors.phone && <div className="error" style={{ color: 'red' }}>{errors.phone}</div>}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label d-block">
                    </label>
                    <div className="pass-group">
                      <TKN_PasswordInput
                        label={'Password'}
                        value={password}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const { value } = e.target;
                          setPassword(value);

                          if (errors.password && value.length > 0) {
                            setErrors((prevErrors) => ({ ...prevErrors, password: undefined }));
                          }

                          if (value.length < maxLength) {
                            setPasswordError('');
                          } else {
                            setPasswordError(en.CHAR_LIMIT);
                          }
                          onChangePassword(value);
                        }}
                        required={true}
                        serverError={passwordError}
                      />
                      {errors.password && <div className="error" style={{ color: 'red' }}>{errors.password}</div>}
                    </div>
                    <div
                      className={`password-strength ${passwordResponse.passwordResponseKey === '0'
                        ? 'poor-active'
                        : passwordResponse.passwordResponseKey === '1'
                          ? 'avg-active'
                          : passwordResponse.passwordResponseKey === '2'
                            ? 'strong-active'
                            : passwordResponse.passwordResponseKey === '3'
                              ? 'heavy-active'
                              : ''
                        }`}
                      id="passwordStrength"
                    >
                      <span id="poor" className="active"></span>
                      <span id="weak" className="active"></span>
                      <span id="strong" className="active"></span>
                      <span id="heavy" className="active"></span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label d-block">
                    </label>
                    <div className="pass-group">
                      <TKN_PasswordInput
                        label={'Confirm Password'}
                        value={confirmPassword}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const { value } = e.target;
                          setConfirmPassword(value);

                          if (errors.confirmPassword && value.length > 0) {
                            setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: undefined }));
                          }

                          if (value.length < maxLength) {
                            setConfirmPasswordError('');
                          } else {
                            setConfirmPasswordError(en.CHAR_LIMIT);
                          }
                        }}
                        required={true}
                        serverError={confirmPasswordError}
                      />
                      {errors.confirmPassword && <div className="error" style={{ color: 'red' }}>{errors.confirmPassword}</div>}
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="text-container">
                      <div className="checkbox-container">
                        <input
                          type="checkbox"
                          id="isChecked"
                          name="isChecked"
                          checked={isChecked}
                          onChange={() => {
                            setIsChecked(!isChecked);
                            setSignUpTermsError('');
                          }}
                          style={{ accentColor: "#0445CF" }}
                        />
                      </div>
                      <div className="signup-text-container">
                        <p className="signup-text">
                          {en.BY_CLICKING_SIGNUP}&nbsp;
                          <a
                            href={routes.termsCondition}
                            className="link-text"
                            target='_blank'
                            rel="noreferrer"
                          >
                            {en.TERMS}
                          </a>,&nbsp;
                          <a
                            href={routes.privacyPolicy}
                            className="link-text"
                            target='_blank'
                            rel="noreferrer"
                          >
                            {en.PRIVACY_POLICY}
                          </a>, {en.COOKIES_POLICY}.
                        </p>
                      </div>
                    </div>
                    {signUpTermsError && <div className="error" style={{ color: 'red' }}>{signUpTermsError}</div>}
                  </div>
                  <button
                    type="submit"
                    onClick={handleLoginClick}
                    className="btn btn-primary w-100 login-btn"
                    disabled={isLoading}
                  >
                    {isLoading ? <Spinner style={{ width: '1.5rem', height: '1.5rem' }} /> : "Signup"}
                  </button>
                  <div className="social-login">
                  </div>
                  <p className="no-acc">
                    Already have an account ?{' '}
                    <Link to={routes.login}> Sign In</Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProviderSignup;
