import React from "react";
import DashBoardRecentSubHeading from "../dashBoardRecentSubHeading/dashBoardRecentSubHeading";
import TableTag from "../../tableTag/tableTag";
import FavoriteSection from "../favoriteRowSection/favoriteRowSection";
import { all_routes } from "../../../../core/data/routes/all_routes";
import { useNavigate } from "react-router-dom";
import en from "../../../../en/en";

const CustomerDashboardRecentFavorites = () => {

    const routes = all_routes;
    const navigateToFavorites = useNavigate();

    return (
        <div className="col-lg-4 flex-fill">
            <div className="widget-dashBoard-title">
                <DashBoardRecentSubHeading
                    SubHeading={en.FAVORITES}
                    btnText={en.VIEW_ALL_FAVORITES}
                    tagName={"h6"}
                    allBookingsClick={() => { navigateToFavorites(routes.customerFavourite) }}
                />
            </div>
            <div className="table-responsive transaction-table flex-fill">
                <TableTag className="table favorite-section mb-0" >{<FavoriteSection />}</TableTag>
            </div>
        </div>
    )
};

export default CustomerDashboardRecentFavorites;