import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import { Dropdown } from 'primereact/dropdown';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { all_routes } from '../../../../core/data/routes/all_routes';
import useGetAllBookings from '../../../../hooks/getAllBookings';
import { useSelector } from 'react-redux';

const CustomerBookingCalendar = () => {
  const routes = all_routes;
  const limit = 0;
  const [page] = useState(1);
  const [selectedSub, setselectedSub] = useState(null);
  const sub = [{ name: 'Most Helpful' }, { name: 'A -&gt; Z' }];
  const userId = useSelector((state: any) => state.user.user_id);

  const events = [
    { title: 'Meeting 1', start: '2024-07-01T10:00:00' },  // ISO8601 string for specific time
    { title: 'Meeting 2', start: '2024-07-02T14:00:00' },
    { title: 'Meeting 3', start: '2024-07-03T09:00:00' },
    { title: 'Meeting 4', start: '2024-07-01T12:00:00' },
    { title: 'Meeting 5', start: '2024-07-04T16:00:00' },
    { title: 'Meeting 6', start: '2024-07-01T11:00:00' },
    { title: 'Meeting 7', start: '2024-07-01T13:00:00' },
    { title: 'Meeting 8', start: '2024-07-01T15:00:00' },
    { title: 'Meeting 9', start: '2024-07-01T17:00:00' },
    { title: 'Meeting 10', start: '2024-07-01T18:00:00' },
    { title: 'Meeting 11', start: '2024-07-01T19:00:00' },
    { title: 'Meeting 12', start: '2024-07-01T20:00:00' },
  ];

  const dateFormat = (date: Date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const { bookings } = useGetAllBookings(userId, page, limit);

  const bookingEvents = bookings.map(({ booking_for, service_name }: any) => ({
    title: service_name,
    start: dateFormat(new Date(booking_for)),
  }));

  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-md-4">
                <div className="provider-subtitle">
                  <h6>Bookings</h6>
                </div>
              </div>
              <div className="col-md-8 d-flex align-items-center justify-content-md-end flex-wrap">
                {/* <div className="review-sort cus-fav-sort me-2">
                  <p>Sort</p>
                  <Dropdown
                    value={selectedSub}
                    onChange={(e) => setselectedSub(e.value)}
                    options={sub}
                    optionLabel="name"
                    placeholder="English"
                    className="select provider-select select-space"
                  />
                </div> */}
                <div className="grid-listview">
                  <ul>
                    <li>
                      <Link to={routes.customerFavourite} className="active active-new ">
                        <Icon.Calendar className="react-feather-custom" />
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="#">
                        <Icon.Filter className="react-feather-custom" />
                      </Link>
                    </li> */}
                    <li>
                      <Link to={routes.customerBooking}>
                        <Icon.Grid className="react-feather-custom" />
                      </Link>
                    </li>
                    {/*  <li>
                      <Link to={routes.customerBooking}>
                        <Icon.List className="react-feather-custom" />
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <FullCalendar
                    plugins={[dayGridPlugin]}
                    initialView="dayGridMonth"
                    //weekends={false}
                    events={bookingEvents}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CustomerBookingCalendar;
