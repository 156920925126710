import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { zipCodePatterns } from './zipCodePatterns';

const validateEmptyValue = (input: string, fieldName = '', customErrorMessage?: string) => {
    const response = {
        status: 'true',
        error: '',
    };

    if (typeof input !== 'string') {
        throw new Error('Input must be a string');
    }

    if (input.trim() === '') {
        response.error = customErrorMessage || (fieldName ? `${fieldName} is required.` : 'This field is required.');
        response.status = 'false';
    }

    return response;
};

const validateUsername = (username: string, fieldName: string) => {

    const response = {
        "status": "true",
        "error": ""
    };
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const alphabetPattern = /^[A-Za-z\s]+$/;

    if (!emailPattern.test(username) && !alphabetPattern.test(username)) {

        response["error"] = 'Please enter a valid ' + fieldName + '.';
        response["status"] = "false";

    }
    console.log(response);
    return response;
};

const validateEmail = (email: string, fieldName: string) => {

    const response = {
        "status": "true",
        "error": ""
    };
    const emailPattern = /^[a-z0-9._-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

    const trimmedEmail = email.trim();

    if (!emailPattern.test(trimmedEmail)) {

        response["error"] = 'Please enter a valid ' + fieldName + '.';
        response["status"] = "false";

    }
    console.log(response);
    return response;
};

const validateMobileNumber = (number: string, countryCode: string, errorMessage: string) => {
    const response = {
        status: 'true',
        error: '',
    };

    if (!number) {
        response.error = 'Phone is required';
        response.status = 'false';
        return response;
    }

    // Remove any leading/trailing spaces from the number
    number = number.trim();

    if (number.includes(' ')) {
        response.error = 'Mobile number cannot contain spaces';
        response.status = 'false';
        return response;
    }

    // Check if the number contains only numeric characters and '+' symbol
    if (/\s|[^\d+]/.test(number)) {
        response.error = 'Mobile number must contain only numeric characters';
        response.status = 'false';
        return response;
    }

    number = String(countryCode) + String(number);

    // Parse the number using the parsing library
    const parsedPhoneNumber = parsePhoneNumberFromString(number);

    if (!parsedPhoneNumber || !parsedPhoneNumber.isValid()) {
        response.error = `${errorMessage}`;
        response.status = 'false';
        return response;
    }

    return response;
};


const validateOldPassword_NewPassword_ConfirmPassword = (oldPassword: string, newPassword: string, confirmPassword: string) => {
    const response = {
        status: "true",
        oldPasswordError: "",
        newPasswordError: "",
        confirmPasswordError: "",
    };

    const trimmedOldPassword = oldPassword.trim();
    const trimmedNewPassword = newPassword.trim();
    const trimmedConfirmPassword = confirmPassword.trim();

    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^~/<>.,:;])[A-Za-z\d@$!%*?&#^~/<>.,:;]{8,}$/;

    if (trimmedOldPassword === '') {
        response["oldPasswordError"] = 'Old password is required';
        response["status"] = "false";
    } else if (!passwordPattern.test(trimmedOldPassword)) {
        response["oldPasswordError"] = 'Old password must have at least 8 characters, including uppercase, lowercase, and numbers.';
        response["status"] = "false";
    }

    if (trimmedNewPassword === '') {
        response["newPasswordError"] = 'New password is required';
        response["status"] = "false";
    } else if (!passwordPattern.test(trimmedNewPassword)) {
        response["newPasswordError"] = 'New password must have at least 8 characters, including uppercase, lowercase, and numbers.';
        response["status"] = "false";
    }

    if (trimmedConfirmPassword === '') {
        response["confirmPasswordError"] = 'Confirm password is required';
        response["status"] = "false";
    } else if (!passwordPattern.test(trimmedConfirmPassword)) {
        response["confirmPasswordError"] = 'Confirm password must have at least 8 characters, including uppercase, lowercase, and numbers.';
        response["status"] = "false";
    } else if (trimmedNewPassword !== trimmedConfirmPassword) {
        response["confirmPasswordError"] = 'Passwords do not match';
        response["status"] = "false";
    }

    console.log(response);
    return response;
};

const validatePassword = (password: string) => {

    const response = {
        "status": "true",
        "error": ""
    };
    const passwordRules = [
        (password: string) => password.length >= 8,
        (password: string) => /[A-Z]/.test(password),
        (password: string) => /[a-z]/.test(password),
        (password: string) => /[0-9]/.test(password),
        (password: string) => /[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/.test(password),
    ];

    for (const rule of passwordRules) {
        if (!rule(password)) {
            response["error"] = 'Password must contain at least 8 characters, including both letters and numbers.';
            response["status"] = "false";
        }
    };
    console.log(response);
    return response;
};

const validatePassword_ConfirmPassword = (newPassword: string, confirmPassword: string, fieldName?: string) => {
    const response = {
        status: "true",
        newPasswordError: "",
        confirmPasswordError: "",
    };

    const trimmedNewPassword = newPassword.trim();
    const trimmedConfirmPassword = confirmPassword.trim();

    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^~/<>.,:;])[A-Za-z\d@$!%*?&#^~/<>.,:;]{8,}$/;


    if (trimmedNewPassword === '') {
        response["newPasswordError"] = 'Password is required';
        response["status"] = "false";
    } else if (!passwordPattern.test(trimmedNewPassword)) {
        response["newPasswordError"] = 'Password must have at least 8 characters, including uppercase, lowercase, and numbers.';
        response["status"] = "false";
    }

    if (trimmedConfirmPassword === '') {
        response["confirmPasswordError"] = 'Confirm password is required';
        response["status"] = "false";
    } else if (!passwordPattern.test(trimmedConfirmPassword)) {
        response["confirmPasswordError"] = 'Confirm password must have at least 8 characters, including uppercase, lowercase, and numbers.';
        response["status"] = "false";
    } else if (trimmedNewPassword !== trimmedConfirmPassword) {
        response["confirmPasswordError"] = 'Passwords do not match';
        response["status"] = "false";
    }
    return response;
};

const validatepassword_ConfirmPassword = (password: string, confirmPassword: string, fieldName: string) => {

    const response = {
        status: "true",
        passworderror: "",
        confirm_passworderror: "",
    };

    const passwordRules = [
        (password: string) => password.length >= 8,
        (password: string) => /[A-Z]/.test(password),
        (password: string) => /[a-z]/.test(password),
        (password: string) => /[0-9]/.test(password),
        (password: string) => /[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/.test(password),
    ];

    if (password.trim() === '' || confirmPassword.trim() === '') {
        response["passworderror"] = password.trim() === '' ? 'Password is required' : '';
        response["confirm_passworderror"] = confirmPassword.trim() === '' ? 'Confirm password is required' : '';
        response["status"] = "false";
    } else {

        let passwordValid = true
        for (const rule of passwordRules) {
            if (!rule(password)) {
                passwordValid = false;
                break;
            }
        };

        if (!passwordValid) {
            response["passworderror"] = "Your password must be at least 8 characters long, contain at least one number, and have a mixture of uppercase and lowercase letters with a special character.";
            response["status"] = "false";
        } else if (password !== confirmPassword) {
            response["confirm_passworderror"] = 'Passwords do not match';
            response["status"] = "false";
        };
    };
    console.log(response);
    return response;
};

const validateDropdown_RelationShip = (selectedItem: string, fieldName: string,): { status: string; error: string; } => {
    const response = {
        status: "true",
        error: "",
        OtherRelationship_error: ""
    };

    /**
    * Function to Validate other relationship 
    * @param String selectionRelationship, otherRelationship
    * @returns object key and boolean value
    */

    if (selectedItem === '') {
        response["error"] = fieldName + ' is required.';
        response["status"] = "false";

    } else {
        response["error"];
        response["status"];
    }
    console.log(response);
    return response;
};

const validateDropdown = (selectedItem: string, fieldName: string) => {
    const response = {
        status: "true",
        error: ""
    };
    if (selectedItem === '') {
        response["error"] = 'Please select a ' + fieldName;
        response["status"] = "false";

    } else {
        response['error'] = '';
        response['status'] = 'true';
    };
    console.log(response);
    return response;
};

const validateEmptyValuenumber = (value: any, fieldName: any) => {
    if (!value) {
        return { status: 'false', error: `${fieldName} cannot be empty` };
    }
    return { status: 'true' };
};

const validateOTP = (value: any, fieldName: any) => {
    const otpPattern = /^[0-9]{6}$/;
    if (!otpPattern.test(value)) {
        return { status: 'false', error: `${fieldName} is invalid` };
    }
    return { status: 'true' };
};

const validateAddress = (address: string, fieldName: string) => {
    const response = {
        status: "true",
        error: ""
    };
    const addressPattern = /^[a-zA-Z0-9\s,'#./-]+$/;

    if (!addressPattern.test(address)) {
        response["error"] = 'Please enter a valid ' + fieldName + ' only.';
        response["status"] = "false";
    };
    console.log(response);
    return response;
};


const validateZipCodeByCountry = (country: string, zipCode: string) => {

    const response = {
        status: "true",
        error: ""
    };

    const trimmedZipCode = zipCode.trim();
    if (country) {

        const countryZipCodePattern = zipCodePatterns[country];

        if (!trimmedZipCode) {
            response.error = 'Zip code is required';
            response.status = 'false';
        } else if (!countryZipCodePattern) {
            response.error = 'Zip code validation not available for this country';
            response.status = 'false';
        } else if (!countryZipCodePattern.test(trimmedZipCode)) {
            response.error = 'Invalid zip code for the selected country';
            response.status = 'false';
        }
    } else {

        if (!trimmedZipCode) {
            response.error = 'Zip code is required';
            response.status = 'false';
        }
    }
    return response;
};


const validateURL = (url: string, fieldName: string) => {
    const response = {
        status: "true",
        error: ""
    };

    // Regular expression for URL validation
    const urlPattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol (optional)
        '((([a-zA-Z0-9$_.+!*\'(),;?&=-]|%[0-9a-fA-F]{2}){1,64}(@[a-zA-Z0-9.-]{1,255})?)' + // username and password
        '(([a-zA-Z0-9.-]){1,255}\\.[a-zA-Z]{2,63}|localhost)|' + // domain name and extension or localhost
        '((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}' + // OR IPv4 address
        '(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))' + // IPv4 address part 2
        '(\\:\\d{1,5})?' + // port number (optional)
        '(\\/[-a-zA-Z0-9@:%._+~#=]*)*' + // path (optional)
        '(\\?[;&a-zA-Z0-9%@_.~+=-]*)?' + // query string (optional)
        '(\\#[-a-zA-Z0-9@:%_+.~#?&/=]*)?$', // fragment locator (optional)
        'i'
    );

    // Ensure the input starts with http:// or https:// if not provided
    let formattedURL = url;
    if (!/^https?:\/\//i.test(url)) {
        formattedURL = 'http://' + url;
    }

    if (!urlPattern.test(formattedURL)) {
        response.error = `Please enter a valid ${fieldName}.`;
        response.status = "false";
    }

    console.log(response);
    return response;
};




export {
    validateEmptyValue,
    validateUsername,
    validateEmail,
    validateMobileNumber,
    validateOldPassword_NewPassword_ConfirmPassword,
    validateDropdown_RelationShip,
    validateDropdown,
    validatePassword_ConfirmPassword,
    validateEmptyValuenumber,
    validateOTP,
    validateAddress,
    validateZipCodeByCountry,
    validateURL,
    validatePassword,
    validatepassword_ConfirmPassword
};