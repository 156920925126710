
import React, { useEffect, useState } from 'react';
import { all_routes } from '../../../../core/data/routes/all_routes';
import apiService from '../../../../api/authentication-service';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../core/data/redux/store';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import { Calendar as PrimeCalendar } from 'primereact/calendar';
import { TKN_DropdownInputEdit, TKN_TextInputreqired, TKN_TextareaPlaceholder, TKN_TimePickerComponent } from '../../../components/inputfields/input';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import { addDays, format, parse } from 'date-fns';
import { validateDropdown, validateEmptyValue } from '../../../../helper/validate';
import { message } from 'antd';
import './style.css';
import en from '../../../../en/en';
import { useNavigate } from 'react-router-dom';
import { TKN_Danger_message, TKN_Success_message } from '../../../components/alert_messages/alert_messages';
import TKN_modal from '../../../components/modal/modal';
import { ButtonTextOnly_Colored } from '../../../components/buttons/button';
import { Spinner } from 'react-bootstrap';
import { converHoursToMinutes } from '../../../../helper/Time';

type ErrorMessages = {
  serviceName?: string;
  startDate?: string;
  endDate?: string;
  description?: string;
  file?: string;
  businessHours?: string;
  [key: string]: string | undefined;
};

type BusinessHour = {
  day: string;
  open_from_time: string;
  open_to_time: string;
  break_from_time: string;
  break_to_time: string;
  interval: string;
  availableSlots?: string;
};

const ProviderAddService: React.FC = () => {

  const routes = all_routes;

  const [descriptionError, setDescriptionError] = useState('');
  const [selectedOptionvalue, setSelectedOptionvalue] = useState<number | null>(null);
  const [value, onChange] = useState(new Date());
  const [errors, setErrors] = useState<ErrorMessages>({});
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date('9999-12-31'));
  const [availableSlots, setAvailableSlots] = useState('');
  const today = new Date();
  const formattedToday = format(today, 'yyyy-MM-dd');
  const [datetosend, setDatetosend] = useState<string>(formattedToday);
  const [serviceName, setServiceName] = useState('');
  const [serviceDescription, setServiceDescription] = useState('');
  const [successmsg, setSuccessmsg] = useState('');
  const [statusValue, setstatusValue] = useState(1);
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const [showmodal, setShowModal] = useState<boolean>(false)
  const maxLength = 200;
  const [deleteErrorMsg, setDeleteErrorMsg] = useState('');

  const [successresponsemsg, setSuccessResponseMsg] = useState('');
  const [errorresponsemsg, setErrorResponseMsg] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

  const [businessHours, setBusinessHours] = useState<BusinessHour[]>([
    { day: '', open_from_time: '', open_to_time: '', break_from_time: '', break_to_time: '', interval: '' },
  ]);

  const vendor = useSelector((state: RootState) => state.vendor);

  const navigateTo = useNavigate();

  const formatTime = (time: string) => {
    const parsedTime = parse(time, 'hh:mm a', new Date());
    return format(parsedTime, 'HH:mm');
  };
  const handleBusinessHoursChange = (index: number, field: string, value: string) => {
    setBusinessHours(prev => {
      const newBusinessHours = [...prev];
      newBusinessHours[index] = {
        ...newBusinessHours[index],
        [field]: field.includes('_time') ? formatTime(value) : value,
      };
      if (errors[`${field}_${index}`] && value) {
        const { [`${field}_${index}`]: _, ...restErrors } = errors;
        setErrors(restErrors);
      }
      return newBusinessHours;
    });
  };

  const addDaySection = () => {
    const selectedDays = businessHours.map((hour) => hour.day);
    const availableDays = daysOfWeek.filter(day => !selectedDays.includes(day));
    if(businessHours.length === daysOfWeek.length) {
      setDeleteErrorMsg('Not more than seven days can be added');
    }
    else if (availableDays.length > 0 ) {
      setBusinessHours(prev => [
        ...prev,
        { day: '', open_from_time: '', open_to_time: '', break_from_time: '', break_to_time: '', interval: '' },
      ]);
      setDeleteErrorMsg('');
    }
  };

  const handleDayChange = (index: number, day: string) => {
    setBusinessHours(prev => {
      const newBusinessHours = [...prev];
      newBusinessHours[index].day = day;
       if (errors[`day_${index}`] && day) {
      const { [`day_${index}`]: _, ...restErrors } = errors;
      setErrors(restErrors);
    }
      return newBusinessHours;
    });
  };

  const deleteLastDaySection = () => {
    if (businessHours.length === 1) {
      setDeleteErrorMsg('Kindly add a minimum of one day of service.');
    } else {
      setBusinessHours(prev => prev.slice(0, -1));
      setDeleteErrorMsg('');
    }
  };
  

  const getTimeDifference = (startTime: string, endTime: string) => {
    const start = parse(startTime, 'HH:mm', new Date());
    const end = parse(endTime, 'HH:mm', new Date());
    return (end.getTime() - start.getTime()) / (1000 * 60);
  };

  const convertHoursToMinutes = (time: string) => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  };

  useEffect(() => {
    console.log("Business hours updated, recalculating slots...");
    businessHours.forEach((_, index) => {
      calculateAvailableSlots(index);
    });
  }, [businessHours]);

  useEffect(() => {
    console.log("Available Slots Updated:", availableSlots);
  }, [availableSlots]);

  useEffect(() => {
    console.log("Business Hours State Updated:", businessHours);
  }, [businessHours]);


  const handleTimeChange = (index: number, field: string, value: string) => {
    handleBusinessHoursChange(index, field, value);
   
  };

  const getDisabledDates = () => {
    const disabledDates = [];
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    for (let i = 1; i <= 3; i++) { 
        const pastDate = addDays(today, -i);
        disabledDates.push(pastDate);
    }

    return disabledDates;
};

  const calculateAvailableSlots = (index: number) => {
    const businessHour = businessHours[index];
    let totalSlots = 0;

    if (businessHour) {
      const { open_from_time, open_to_time, break_from_time, break_to_time, interval } = businessHour;

      if (open_from_time && open_to_time) {
        const intervalMinutes = convertHoursToMinutes(interval);

        if (break_from_time && break_to_time) {
          const forenoonTotalTime = getTimeDifference(open_from_time, break_from_time);
          const afternoonTotalTime = getTimeDifference(break_to_time, open_to_time);
          let forenoonSlots = 0;
          let afternoonSlots = 0;

          if (!(forenoonTotalTime < 0) && intervalMinutes) {
            forenoonSlots = Math.floor(forenoonTotalTime / intervalMinutes);
          }
          if (!(afternoonTotalTime < 0) && intervalMinutes) {
            afternoonSlots = Math.floor(afternoonTotalTime / intervalMinutes);
          }
          totalSlots = forenoonSlots + afternoonSlots;
        } else {
          const totalTime = getTimeDifference(open_from_time, open_to_time);
          console.log('Total time for indexp',interval);
          const intervalMinutes = convertHoursToMinutes(interval);
          if (totalTime && intervalMinutes) {
            totalSlots = Math.floor(totalTime / intervalMinutes);
          }
        }

        console.log(`Calculated Slots for index ${index}:`, totalSlots);

        setBusinessHours(prev => {
          const newBusinessHours = [...prev];
          newBusinessHours[index] = {
            ...newBusinessHours[index],
            availableSlots: totalSlots.toString(),
          };
          return newBusinessHours;
        });
      } else {
        console.warn(`Missing time for index ${index}`);
      }
    }
  };


  const validateForm = () => {

    const newErrors: ErrorMessages = {};
    if (!serviceName) newErrors.serviceName = 'Service name is required';
    if (!startDate) newErrors.startDate = 'Start date is required';
    if (!endDate) newErrors.endDate = 'End date is required';
    if (!serviceDescription) newErrors.description = 'Description is required';
    if (!selectedFile) newErrors.file = 'Service photo is required';
    if (startDate >= endDate) newErrors.endDate = 'End date must be after start date';

    businessHours.forEach((hour, index) => {
      if (!hour.day) newErrors[`day_${index}`] = 'Please select a day';
      if (!hour.open_from_time) newErrors[`open_from_time_${index}`] = 'Open start time is required';
      if (!hour.open_to_time) newErrors[`open_to_time_${index}`] = 'Open end time is required';
      if (!hour.interval) newErrors[`interval_${index}`] = 'Please select duration';

      if (hour.open_from_time && hour.open_to_time && hour.open_from_time >= hour.open_to_time) {
        newErrors[`open_time_order_${index}`] = 'Service end time should be greater than service start time';
      }

      if (hour.open_from_time && hour.break_from_time && hour.break_from_time <= hour.open_from_time) {
        newErrors[`break_from_time_${index}`] = 'Break start time must be after service start time';
      }
      if (hour.interval == "00:00") {
        newErrors[`interval_order_${index}`] = 'Please enter duration greater than zero minutes';
      }
      if (hour.break_from_time) {
        console.log(hour.break_from_time);
        if (getTimeDifference(hour.open_from_time, hour.break_from_time) < 0 || getTimeDifference(hour.open_to_time, hour.break_from_time) > 0) {

          newErrors[`break_from_time_${index}`] = 'Break time should fall between service start and end time';
        }
        if (!hour.break_to_time) {
          newErrors[`break_to_time_${index}`] = 'Break end time is missing';
        }
      }
      if (hour.break_to_time) {

        if (getTimeDifference(hour.open_from_time, hour.break_to_time) < 0 || getTimeDifference(hour.open_to_time, hour.break_to_time) > 0) {
          newErrors[`break_to_time_${index}`] = 'Break time should fall between service start and end time';

        } else if (getTimeDifference(hour.break_from_time, hour.break_to_time) <= 0) {
          newErrors[`break_to_time_${index}`] = 'Break end time should be greater than Break start time';

        }
      }

      if (hour.interval === '00:00') {

        newErrors[`interval_order_${index}`] = 'Please enter duration greater than zero minutes';

      } else {

        const intervalMinute = converHoursToMinutes(hour.interval);
        if (hour.break_from_time && hour.break_to_time) {

          const forenoonTime = getTimeDifference(hour.open_from_time, hour.break_from_time);
          const afternoonTime = getTimeDifference(hour.break_to_time, hour.open_to_time);
          if ((forenoonTime < intervalMinute && forenoonTime != 0) || (afternoonTime < intervalMinute && afternoonTime != 0)) {

            newErrors[`interval_order_${index}`] = 'The duration should fall between service start and end times, accounting for break periods.';

          } else if (forenoonTime == 0 && afternoonTime == 0) {

            newErrors[`available_slots_${index}`] = 'Zero slot error, modify your settings';

          }
        } else {

          if (getTimeDifference(hour.open_from_time, hour.open_to_time) < intervalMinute) {

            newErrors[`interval_order_${index}`] = 'Duration should fall between service start and end time';

          }
        }
      }

      if (parseInt(availableSlots) == 0) {

        newErrors[`available_slots_${index}`] = 'Zero slot error, modify your settings';
      }
    });

    setErrors(newErrors);
    console.log("newErrors", newErrors);
    console.log("newErrorsas", Object.keys(newErrors).length === 0);
    return Object.keys(newErrors).length === 0;
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    if (value.length >= maxLength) {
      setDescriptionError(en.CHAR_LIMIT);
    } else {
      setDescriptionError('');
    }
    setServiceDescription(value);
    setErrors((prevErrors) => ({ ...prevErrors, description: undefined }));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setSelectedFile(reader.result as string);
      };

      reader.readAsDataURL(file);
    }
    setErrors((prevErrors) => ({ ...prevErrors, file: undefined }));
  };


  const handleCancel = () => {
    navigateTo(routes.vendorservice)
  };
  const handleSubmit = () => {
    console.log("hi");
    if (validateForm()) {
      // setIsLoading(true);
      console.log("hidd");
      const transformedBusinessHours = businessHours.reduce((acc, curr) => {
        acc[curr.day] = {
          day: curr.day,
          open_from_time: curr.open_from_time,
          open_to_time: curr.open_to_time,
          break_from_time: curr.break_from_time,
          break_to_time: curr.break_to_time,
          interval: curr.interval,
        };
        return acc;
      }, {} as Record<string, BusinessHour>);
      const payload = {
        service_name: serviceName,
        start_date: getFormattedDate(startDate),
        end_date: getFormattedDate(endDate),
        service_description: serviceDescription,
        service_image: selectedFile ? selectedFile.split(',')[1] : '',
        status: statusValue,
        vendor_id: vendor.id,
        category_id: vendor.category_id,
        subcategory_id: vendor.subcategory_id,
        hours_open: JSON.stringify(transformedBusinessHours),
      }
      console.log("pay", payload);
      apiService.apiAddService(payload)
        .then((response) => {
          console.log("RESPONSE", response);
          const responseData = response.data as { status: boolean; message?: string };
          setIsLoading(false);


          if (responseData.status === true) {
            setShowModal(true);
            setSuccessResponseMsg(" Service Added successfully");
          } else {
            setErrorResponseMsg(responseData.message || 'Adding Service failed. Please try again.');
          }
        })
        .catch(error => {
          console.error('Error while adding service:', error);
          setErrorResponseMsg('An error occurred while processing your request. Please try again.');
        });
    }
  };

  const getFormattedDate = (date: Date | null) => {
    return date ? format(date, 'yyyy-MM-dd') : '';
  };

  const handleServerErrorClose = () => {
    setErrorResponseMsg('');
  }

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row">
            <TKN_modal title={"Service"} description={successresponsemsg} okbutton={'OK'} handleshowModal={showmodal} handleSuccessButton={() => navigateTo(routes.vendorservice)} />
            {errorresponsemsg && <TKN_Danger_message text={errorresponsemsg} onClick={handleServerErrorClose} />}
            <div className="col-md-4">
              <div className="provider-subtitle">
                <h3>Add Service</h3>
              </div>
            </div>
            <div className="col-md-8">
              <div className="book-title">
                {successmsg && <div className="text-danger">{successmsg}</div>}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="provider-subtitle">
            <h6>Service Information</h6>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4" style={{ width: '50%' }}>
            <div className="form-group">
              <label className="col-form-label">Service Name <span className="text-danger">*</span></label>
              <TKN_TextInputreqired
                  placeholder='Service name'
                  maxLength={100}
                  value={serviceName}
                  onChange={(value: string) => {
                    setServiceName(value);
                    if (errors.serviceName && value) {
                      const { serviceName, ...restErrors } = errors;
                      setErrors(restErrors);
                    }
                  }}
              />
              {errors.serviceName && <div className="error" style={{ color: 'red' }}>{errors.serviceName}</div>}
            </div>
          </div>
          <div className="col-md-4" style={{ width: '25%' }}>
            <div className="form-group">
              <label className="col-form-label">Start Date <span className="text-danger">*</span></label>
              <PrimeCalendar
                value={startDate}
                onChange={(e) => setStartDate(e.value as Date)}
                placeholder="DD/MM/YYYY"
                showIcon={true}
                inputClassName="p-inputtext"
                style={{ border: '1px solid #ced4da', borderRadius: '4px' }}
                disabledDates={getDisabledDates()}
                minDate={new Date()}
              />
              {errors.startDate && <div className="error" style={{ color: 'red' }}>{errors.startDate}</div>}
              <span className="position-absolute" style={{ right: '10px', top: '59px', transform: 'translateY(-50%)' }}>
                <i className="feather-calendar"></i>
              </span>
            </div>
          </div>
          <div className="col-md-4" style={{ width: '25%' }}>
            <div className="form-group">
              <label className="col-form-label">End Date <span className="text-danger">*</span></label>
              <PrimeCalendar
                value={endDate}
                onChange={(e) => setEndDate(e.value as Date)}
                placeholder="DD/MM/YYYY"
                showIcon
                inputClassName="p-inputtext"
                style={{ border: '1px solid #ced4da', borderRadius: '4px', borderColor: 'light_grey' }}
                disabledDates={getDisabledDates()}
                minDate={new Date()}
              />
              {errors.endDate && <div className="error" style={{ color: 'red' }}>{errors.endDate}</div>}
              <span className="position-absolute" style={{ right: '10px', top: '59px', transform: 'translateY(-50%)' }}>
                <i className="feather-calendar"></i>
              </span>
            </div>
          </div>
        </div>

        <div className="col-md-4" style={{ width: '100%' }}>
          <div className="form-group">
            <label className="col-form-label">Description <span className="text-danger">*</span></label>
            <TKN_TextareaPlaceholder 
            placeholderText="Description" 
            value={serviceDescription} 
            descriptionInputError="" 
            maxLength={500} 
            onChange={handleDescriptionChange} />
            {descriptionError && <div className="error" style={{ color: 'red' }}>{descriptionError}</div>}
            {errors.description && <div className="error" style={{ color: 'red' }}>{errors.description}</div>}
          </div>
        </div>
        <div className="col-md-4" style={{ width: '100%' }}>
          <div className="form-group">
            <label className="col-form-label">Add Service Photo<span className="text-danger">*</span></label>
          </div>
        </div>
        {selectedFile && (
          <div className="col-md-4" style={{ width: '100%', marginBottom: '15px' }}>
            <img
              src={selectedFile}
              alt="Selected Service"
              style={{ maxWidth: '100%', maxHeight: '200px', objectFit: 'cover' }}
            />
          </div>
        )}
        <div className="profile-container">
          <div className="pro-info">
            <div className="d-flex" style={{ paddingTop: '15px', marginTop: '-15px' }}>
              <div className="img-upload" style={{ alignItems: 'center', justifyContent: 'center', width: '130px', height: '40px', backgroundColor: '#0445CF', borderRadius: 5, borderColor: 'white' }}>
                Choose File
                <input type="file" accept="image/*" onChange={handleFileChange} />
              </div>
              {errors.file && <div className="error" style={{ color: 'red' }}>{errors.file}</div>}
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              <h6 className="user-title" style={{ paddingTop: '28px' }}>Availability</h6>
              {businessHours.map((hour, index) => (
                <div key={index} className="business-hours-section">
                  <div className="col-md-6" style={{ width: '49%' }}>
                    <div className="form-group">
                      <div>
                        <label className="col-form-label">Select Day<span className="text-danger">*</span></label>
                      </div>
                      <div>
                        <TKN_DropdownInputEdit
                          placeholderText={'Select day'}
                          options={daysOfWeek.filter(day => !businessHours.map(bh => bh.day).includes(day))}
                          initialValue={hour.day}
                          onSelect={(value) => handleDayChange(index, value)}
                          onChange={(value) => handleDayChange(index, value)}
                        />
                      </div>
                      {errors[`day_${index}`] && <div className="error" style={{ color: 'red' }}>{errors[`day_${index}`]}</div>}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4" style={{ width: '50%' }}>
                      <div className="form-group">
                        <TKN_TimePickerComponent
                          label="Start Time"
                          use12Hours={true}
                          value={businessHours[index].open_from_time}
                          onChange={(value) => handleTimeChange(index, 'open_from_time', value)}
                          required={true}
                        />
                      {errors[`open_from_time_${index}`] && <div className="error" style={{ color: 'red' }}>{errors[`open_from_time_${index}`]}</div>}
                      </div>
                    </div>
                    <div className="col-md-4" style={{ width: '50%' }}>
                      <div className="form-group">
                        <TKN_TimePickerComponent
                          label="End Time"
                          use12Hours={true}
                          value={businessHours[index].open_from_time}
                          onChange={(value) => handleTimeChange(index, 'open_to_time', value)}
                          required={true}
                        />
                        {errors[`open_to_time_${index}`] && <div className="error" style={{ color: 'red' }}>{errors[`open_to_time_${index}`]}</div>}
                        {errors[`open_time_order_${index}`] && <div className="error" style={{ color: 'red' }}>{errors[`open_time_order_${index}`]}</div>}

                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4" style={{ width: '50%' }}>
                      <div className="form-group">
                        <TKN_TimePickerComponent
                          label="Break Start Time"
                          use12Hours={true}
                          value={businessHours[index].open_from_time}
                          onChange={(value) => handleTimeChange(index, 'break_from_time', value)}
                        />
                        {errors[`break_from_time_${index}`] && <div className="error" style={{ color: 'red' }}>{errors[`break_from_time_${index}`]}</div>}
                        {errors[`break_time_order_${index}`] && <div className="error" style={{ color: 'red' }}>{errors[`break_time_order_${index}`]}</div>}

                      </div>
                    </div>
                    <div className="col-md-4" style={{ width: '50%' }}>
                      <div className="form-group">
                        <TKN_TimePickerComponent
                          label="Break End Time"
                          use12Hours={true}
                          value={businessHours[index].open_from_time}
                          onChange={(value) => handleTimeChange(index, 'break_to_time', value)}
                        />
                        {errors[`break_to_time_${index}`] && <div className="error" style={{ color: 'red' }}>{errors[`break_to_time_${index}`]}</div>}
                        {errors[`break_time_orderr_${index}`] && <div className="error" style={{ color: 'red' }}>{errors[`break_time_orderr_${index}`]}</div>}

                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4" style={{ width: '50%' }}>
                      <div className="form-group">
                        <TKN_TimePickerComponent
                          label="Duration"
                          use12Hours={false}
                          value={hour.interval}
                          onChange={(value: string) => handleBusinessHoursChange(index, 'interval', value)}
                          required={true}
                        />
                        {errors[`interval_${index}`] && <div className="error" style={{ color: 'red' }}>{errors[`interval_${index}`]}</div>}
                        {errors[`interval_order_${index}`] && <div className="error" style={{ color: 'red' }}>{errors[`interval_order_${index}`]}</div>}
                        {errors[`time_interval_${index}`] && <div className="error" style={{ color: 'red' }}>{errors[`time_interval_${index}`]}</div>}

                      </div>
                    </div>
                    <div className="col-md-4" style={{ width: '50%' }}>
                      <div className="form-group" key={index}>
                        <label className="col-form-label">Available Slots</label>
                        <input
                          style={{ height: '42px' }}
                          type="text"
                          value={hour.availableSlots || ""}
                          onChange={(e) => handleBusinessHoursChange(index, 'availableSlots', e.target.value)}
                        />

                        {errors[`available_slots_${index}`] && <div className="error" style={{ color: 'red' }}>{errors[`available_slots_${index}`]}</div>}

                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <ButtonTextOnly_Colored
                label={'Add Day'}
                route={''}
                fullWidth={false}
                style={{ backgroundColor: '#0445cf', color: 'white', borderColor: '#f7f7ff', marginRight: '20px' }}
                onClick={addDaySection}
                // disabled={businessHours.length === daysOfWeek.length}
              />
              <ButtonTextOnly_Colored
                label={'Delete Day'}
                route={''}
                fullWidth={false}
                style={{ backgroundColor: '#0445cf', color: 'white', borderColor: '#f7f7ff', marginRight: '20px' }}
                onClick={deleteLastDaySection}
              />
               {deleteErrorMsg && <div style={{ color: 'red', marginTop: '10px' }}>{deleteErrorMsg}</div>}
            </div>
          </div>
        </div>
        <div className="acc-submit" style={{ paddingTop: "16px;", paddingRight: "22px;", marginBottom: "20px;" }}>
          <ButtonTextOnly_Colored
            label={"Cancel"}
            fullWidth={false}
            style={{
              color: '#0445cf',
              backgroundColor: '#f8f8f8',
              marginRight: '20px'
            }}
            onClick={handleCancel}
          />
          <ButtonTextOnly_Colored
            label={isLoading ? <Spinner style={{ width: '1.5rem', height: '1.5rem' }} /> : "Save"}
            fullWidth={false}
            onClick={handleSubmit}
            disabled={isLoading}
          />
        </div>
      </div>
    </div>

  );
};

export default ProviderAddService;