import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Dropdown } from 'primereact/dropdown';
import { TagsInput } from 'react-tag-input-component';
import { Calendar } from 'primereact/calendar';
import * as Icon from 'react-feather';
import { ImageWithBasePath } from '../../../../core/img/ImageWithBasePath';
import { Nullable } from 'primereact/ts-helpers';

const CustomerProfile = () => {
  const [selectedGender, setGender] = useState(null);
  const [selectedCurrency, setCurrency] = useState(null);
  const [tags] = useState(['English', 'French']);
  const [date, setDate] = useState<Nullable<Date>>(null);

  const gender = [
    { name: 'Select Gender' },
    { name: 'Male' },
    { name: 'Female' },
  ];
  const currency = [
    { name: 'Choose Currency' },
    { name: 'Eur' },
    { name: 'Aud' },
  ];

  return (
    <div className="col-lg-9">
      <div className="widget-title">
        <h4>Account Settings</h4>
      </div>
      <h6 className="user-title">Profile Picture</h6>
      <div className="pro-picture">
        <div className="pro-img">
          <ImageWithBasePath src="assets/img/profiles/avatar-02.jpg" alt="user" />
        </div>
        <div className="pro-info">
          <div className="d-flex">
            <div className="img-upload">
            <Icon.UploadCloud className="react-feather-custom me-1"/>
              Upload
              <input type="file" />
            </div>
            <Link to="#" className="btn btn-remove">
              Remove
            </Link>
          </div>
          <p>
            *image size should be at least 320px big,and less then 500kb.
            Allowed files .png and .jpg.
          </p>
        </div>
      </div>
      <h6 className="user-title">General Information</h6>
      <div className="general-info">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="col-form-label">
                Name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Your Name"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="col-form-label">User Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Your User Name"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="col-form-label">
                Email <span className="text-danger">*</span>
              </label>
              <input
                type="email"
                className="form-control"
                placeholder="Enter Email Address"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="col-form-label">
                Mobile Number <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Mobile Number"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="col-form-label">Gender</label>
              <Dropdown
                value={selectedGender}
                onChange={(e) => setGender(e.value)}
                options={gender}
                optionLabel="name"
                placeholder="English"
                className="select w-100"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="col-form-label">Date of birth</label>
              <div className="form-icon cus-profile">
                <Calendar
                  value={date}
                  onChange={(e) => {
                    if (e.value) {
                      const date = new Date(e.value);
                      setDate(date);
                    }
                  }}
                  placeholder="DD/MM/YYYY" 
                  readOnlyInput={true}
                />
                <span className="cus-icon">
                  <Icon.Calendar className="react-feather-custom" />
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label className="col-form-label d-block">
                Your Bio{' '}
                <span className="brief-bio float-end">
                  Brief description for your profile. URLs are hyperlinked.
                </span>
              </label>
              <textarea
                className="form-control"
                rows={5}
                placeholder="Add a Short Bio....."
                defaultValue={''}
              />
            </div>
          </div>
        </div>
      </div>
      <h6 className="user-title">Address </h6>
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <label className="col-form-label">Address</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Your Address"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="col-form-label">Country</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Your Country"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="col-form-label">State</label>
            <input
              type="email"
              className="form-control"
              placeholder="Enter Your State"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="col-form-label">City</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Your City"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="col-form-label">Postal Code</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Your Postal Code"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="col-form-label">Currency Code</label>
            <Dropdown
              value={selectedCurrency}
              onChange={(e) => setCurrency(e.value)}
              options={currency}
              optionLabel="name"
              placeholder="Choose Currency"
              className="select w-100"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="col-form-label">Language</label>
            <TagsInput
             // eslint-disable-next-line @typescript-eslint/ban-ts-comment
               // @ts-expect-error
              className="input-tags form-control"
              tags={tags}
              value={tags}
            />
            {/* <input
                      className="input-tags form-control"
                      type="text"
                      data-role="tagsinput"
                      name="specialist"
                      defaultValue="English,French"
                      id="specialist"
                    /> */}
          </div>
        </div>
      </div>
      <div className="acc-submit">
        <Link to="#" className="btn btn-secondary">
          Cancel
        </Link>
        <Link to="#" className="btn btn-primary">
          Save Changes
        </Link>
      </div>
    </div>
  );
};

export default CustomerProfile;
