import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ImageWithBasePath } from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';
import HomeHeader from '../../../components/header/home-header';
import Footer from '../../../components/footer/footer';
import PricingPlanSection from '../../../components/about_us/pricing_plan';

const AboutUs = () => {
  const routes = all_routes;

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  const clientSlider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <>
      <div>
        <HomeHeader type={1} />
        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <h2 className="breadcrumb-title">About Us</h2>
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={routes.homeOne}>Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      About Us
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="content p-0">
          <div className="about-sec">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="about-img">
                    <div className="abt-img">
                      <ImageWithBasePath
                        src="assets/img/about-05.png"
                        className="img-fluid"
                        alt="img"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-content">
                    <h2>{`Improve the quality of life with a few clicks`}</h2>
                    <p style={{ textAlign: 'justify' }}>Welcome to “𝗧𝗮𝗸𝗲 𝗔 𝗡𝗼”, our first innovative venture from SAFA Logics. Empowering local service providers is at the core of our platform. With a user friendly interface, service providers can easily register with minimal details and efficiently manage their profiles. </p>
                    <p style={{ textAlign: 'justify' }}>Adding and updating services is a simple process, allowing them to showcase their offerings seamlessly. Consumers benefit by booking services at their convenience, eliminating the need for calls or waiting in queues.</p>
                    <div className="row">
                      <div className="col-md-6">
                        <ul>
                          <li><p>Time Efficiency</p></li>
                          <li>Effortless Brand Exposure</li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <ul>
                          <li>Access Local Services</li>
                          <li>Economic Opportunities</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="work-section work-bg">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <div className="section-heading">
                    <h2>How It Works</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="work-box">
                    <div className="work-icon">
                      <span>
                        <ImageWithBasePath src="assets/img/icons/work-icon.svg" alt="img" />
                      </span>
                    </div>
                    <h5>Download and Register</h5>
                    <p>Say goodbye to lengthy queues and effortlessly schedule the services you require.</p>
                    <h4>01</h4>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="work-box">
                    <div className="work-icon">
                      <span>
                        <ImageWithBasePath src="assets/img/icons/find-icon.svg" alt="img" />
                      </span>
                    </div>
                    <h5>Find What You Want</h5>
                    <p>Select your preferred day and time for a smooth and seamless experience.</p>
                    <h4>02</h4>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="work-box">
                    <div className="work-icon">
                      <span>
                        <ImageWithBasePath src="assets/img/icons/place-icon.svg" alt="img" />
                      </span>
                    </div>
                    <h5>Scan, Confirm, and Delight</h5>
                    <p>On your booking day, arrive, scan the QR code, and enjoy your reserved services.</p>
                    <h4>03</h4>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <PricingPlanSection />
          <div className="chooseus-sec">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="choose-content">
                    <h2>Why Choose Us</h2>
                    <p>Time and Convenience with &quot;Take A No&quot;</p>
                    <div className="support-card">
                      <h4 className="support-title">
                        <Link
                          className="collapsed"
                          data-bs-toggle="collapse"
                          to="#aboutone"
                          aria-expanded="false"
                        >
                          Cross Platform
                        </Link>
                      </h4>
                      <div id="aboutone" className="card-collapse collapse">
                        <p style={{ textAlign: 'justify' }}>
                          Our app seamlessly adapts to Android and iOS platforms,
                          ensuring a consistent experience with robust functionality and a user friendly interface.
                          Our goal is to offer you a versatile, dependable, and enjoyable user experience across both platforms.
                        </p>
                      </div>
                    </div>
                    <div className="support-card">
                      <h4 className="support-title">
                        <Link
                          className="collapsed"
                          data-bs-toggle="collapse"
                          to="#abouttwo"
                          aria-expanded="false"
                        >
                          Effortless booking
                        </Link>
                      </h4>
                      <div id="abouttwo" className="card-collapse collapse">
                        <p style={{ textAlign: 'justify' }}>
                          Discover the benefits of speed and simplicity on our platform.
                          We assure fast transactions and easy bookings, ensuring a seamless and
                          convenient overall booking experience. Give our services a try today and elevate the way you book!
                        </p>
                      </div>
                    </div>
                    <div className="support-card">
                      <h4 className="support-title">
                        <Link
                          className="collapsed"
                          data-bs-toggle="collapse"
                          to="#about3"
                          aria-expanded="false"
                        >
                          Zero wait guarantee
                        </Link>
                      </h4>
                      <div id="about3" className="card-collapse collapse">
                        <p style={{ textAlign: 'justify' }}>
                          We understand the value of your time, and waiting in lines can be a source of frustration. With our zero-wait guarantee,
                          we eliminate the inconvenience of queues and delays, ensuring a prompt service experience through our consumer app.
                        </p>
                      </div>
                    </div>
                    <div className="support-card">
                      <h4 className="support-title">
                        <Link
                          className="collapsed"
                          data-bs-toggle="collapse"
                          to="#about4"
                          aria-expanded="false"
                        >
                          Booking with Peace of Mind
                        </Link>
                      </h4>
                      <div id="about4" className="card-collapse collapse">
                        <p style={{ textAlign: 'justify' }}>
                          Our Queue Booking app takes server and app security to the next level, ensuring your data is as safe.
                          Our app adheres to the latest security standards,
                          incorporating secure APIs for seamless communication between the app and server.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="chooseus-img">
                    <ImageWithBasePath
                      src="assets/img/about-04.png"
                      className="img-fluid"
                      alt="img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="client-section hide">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <div className="section-heading aos" data-aos="fade-up">
                    <h2>What our client says</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="testimonial-slider">
                    <Slider {...clientSlider}>
                      <div className="client-widget aos" data-aos="fade-up">
                        <div className="client-img">
                          <Link to={'/'}>
                            <ImageWithBasePath
                              className="img-fluid"
                              alt="Avatar"
                              src="assets/img/profiles/avatar-01.jpg"
                            />
                          </Link>
                        </div>
                        <div className="client-content">
                          <div className="rating">
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                          </div>
                          <p>
                            {`Discovering "Take A No" was a game-changer for my business! It's the perfect platform for listing my services, reaching new clients, and streamlining bookings. Thanks to "Take A No", my business has experienced significant growth!`}
                          </p>
                          <h5>Sophie Moore</h5>
                          <h6>Director</h6>
                        </div>
                      </div>
                      <div className="client-widget aos" data-aos="fade-up">
                        <div className="client-img">
                          <Link to={'/'}>
                            <ImageWithBasePath
                              className="img-fluid"
                              alt="Avat"
                              src="assets/img/profiles/avatar-02.jpg"
                            />
                          </Link>
                        </div>
                        <div className="client-content">
                          <div className="rating">
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                          </div>
                          <p>
                            {`"Take A No" has truly elevated my business! With its user-friendly platform for listing services and attracting new clients, I've seen remarkable growth. I'm sincerely grateful for the positive impact "Take A No" has had on my business.`}
                          </p>
                          <h5>Mike Hussy</h5>
                          <h6>Lead</h6>
                        </div>
                      </div>
                      <div className="client-widget aos" data-aos="fade-up">
                        <div className="client-img">
                          <Link to={'/'}>
                            <ImageWithBasePath
                              className="img-fluid"
                              alt="Avat"
                              src="assets/img/profiles/avatar-03.jpg"
                            />
                          </Link>
                        </div>
                        <div className="client-content">
                          <div className="rating">
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                          </div>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi{' '}
                          </p>
                          <h5>John Doe</h5>
                          <h6>CEO</h6>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AboutUs;
