import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ButtonTextOnly_Colored } from '../buttons/button';
import './modal.css'


interface TKN_modalprops {
    title?: any;
    description?: any;
    okbutton?: any;
    handleshowModal?: boolean;
    cancelbutton?: any;
    closeButton?: boolean;
    handleCloseModal?: any;
    handleSuccessButton?: any;
    handleCancelButton?: any;
}


const TKN_modal: React.FC<TKN_modalprops> = (Props) => {

    return (
        <>
            <Modal show={Props.handleshowModal} onHide={Props.handleCloseModal} backdrop="static" keyboard={false} >
                <Modal.Header closeButton={Props.closeButton} className='border-none'>
                    <Modal.Title className='color-title'>{Props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{Props.description}</p>
                </Modal.Body>
                {Props.cancelbutton ? (<Modal.Footer className='modal-footer'>
                    <div>
                        <ButtonTextOnly_Colored label={Props.cancelbutton} fullWidth={false} style={{ color: '#0445cf', backgroundColor: '#f8f8f8', minWidth: '94px', justifyContent: 'center', maxWidth: '200px' }} onClick={Props.handleCancelButton} />
                    </div>
                    <div>
                        <ButtonTextOnly_Colored label={Props.okbutton} fullWidth={false} onClick={Props.handleSuccessButton} style={{ minWidth: '94px', justifyContent: 'center', maxWidth: '200px' }} />
                    </div>
                </Modal.Footer>) : (
                    <Modal.Footer className='border-none modal-footer2'>
                        <div>
                            <ButtonTextOnly_Colored label={Props.okbutton} fullWidth={false} onClick={Props.handleSuccessButton} style={{ minWidth: '94px', justifyContent: 'center', maxWidth: '200px' }} />
                        </div>
                    </Modal.Footer>)}
            </Modal >
        </>
    )
};

export default TKN_modal;