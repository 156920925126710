
import React, {useState } from 'react';
import { TimeSlotPickerProps } from "../../../core/models/interface";


const TimeSlot: React.FC<TimeSlotPickerProps> = ({ availableSlots, onSelectSlot }) => {

    const [selectedSlot, setSelectedSlot] = useState<string | null>(null);
    const convertAndSplitTimeSlots = (time: string[]): { amSlots: string[]; pmSlots: string[] } => {
        const amSlots: string[] = [];
        const pmSlots: string[] = [];

        time.forEach((slot: string) => {
            const [hour, minute] = slot.split(':').map(Number);
            const period = (hour < 12) ? 'AM' : 'PM';
            const formattedTime = `${String(hour % 12 || 12).padStart(2, '0')}:${String(minute).padStart(2, '0')} ${period}`;

            (period === 'AM') ? amSlots.push(formattedTime) : pmSlots.push(formattedTime);
        });

        return { amSlots, pmSlots };
    };

    const { amSlots, pmSlots } = convertAndSplitTimeSlots(availableSlots);

    return (
        <div>
            {(amSlots?.length > 0 || pmSlots?.length > 0) ? (
                <React.Fragment>
                    {amSlots?.length > 0 && (
                        <React.Fragment>
                            <h6 className="mt-3">AM</h6>
                            {amSlots?.map((slot: string, index: number) => (
                                <div className="form-check-inline visits me-0" key={index}>
                                    <label className="visit-btns">
                                        <input
                                            type="radio"
                                            className="form-check-input"                                            
                                            name="appointment"
                                            onChange={() => onSelectSlot(slot)}
                                        />
                                        <span className="visit-rsn">{slot}</span>
                                    </label>
                                </div>
                            ))}
                        </React.Fragment>
                    )}

                    {pmSlots?.length > 0 && (
                        <React.Fragment>
                            <h6 className="mt-3">PM</h6>
                            {pmSlots?.map((slot: string, index: number) => (
                                <div className="form-check-inline visits me-0" key={index}>
                                    <label className="visit-btns">
                                        <input
                                            type="radio"
                                            className="form-check-input"
                                            name="appointment"
                                            onChange={() => onSelectSlot(slot)}
                                        />
                                        <span className="visit-rsn">{slot}</span>
                                    </label>
                                </div>
                            ))}
                        </React.Fragment>
                    )}
                </React.Fragment>
            ) : (
                <div>
                    <p>No service available for the selected date</p>
                </div>
            )}
        </div>
    );
};

export default TimeSlot;
