export const GOOGLE_MAPS_API_KEY = "AIzaSyCrmKst8GuS8VI8_CKKBFl8LCL_qkthAJ8";
export const WEB_BASE_URL = "https://staging-admin.takeano.com/";
export const GOOGLE_MAPS_PLACES_API = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`;
export const viewLargeMapUrl = (lat: number, lng: number) => `https://www.google.com/maps?q=${lat},${lng}`;
export const CHAR_LIMIT_FIRST_NAME = 26;
export const CHAR_LIMIT_LAST_NAME = 26;
export const CHAR_LIMIT_EMAIL_WEBSITE = 40;
export const WEB_USER_ROLE_CUSTOMER = 2;
export const WEB_USER_ROLE_VENDOR = 3;
export const VENDOR_APP = 'vendor';
export const CUSTOMER_APP = 'customer';
export const MONTH_NAMES = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
export const CHAR_LIMIT_ADDRESS = 50;
export const CHAR_ZIPCODE = 10;
export const CHAR_LIMIT_PLACE = 25;
export const CHAR_LIMIT_BUSINESS_NAME = 50;
export const CHAR_LIMIT_DESC = 200;
export const CHAR_LIMIT_NAME_PASSWORD = 15;
export const CHAR_LIMIT_CATEGORY = 50;

