import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Icon from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { ImageWithBasePath, ImageWithBasePathWithUrl } from '../../../../core/img/ImageWithBasePath';
import { RootState } from '../../../../core/data/redux/store';
import { AppDispatch } from '../../../../core/data/redux/store';
import { logout } from '../../../../core/data/redux/action';
import { WEB_BASE_URL } from '../../../../constants/constants';

const ProviderHeaderTwo = () => {
    const routes = all_routes;
    const dispatch: AppDispatch = useDispatch();
    const navigateTo = useNavigate();
    const [isFullscreen, setIsFullscreen] = useState(false);
    const user = useSelector((state: RootState) => state.user);
    const defaultimg = { src: 'assets/img/profiles/avatar-01.avif' }


    const handleLogout = () => {
        dispatch(logout());
        localStorage.removeItem('UserDetails');
        navigateTo('../authentication/login');
    };

    const toggleFullscreen = () => {
        if (!isFullscreen) {
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            };
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            };
        };
        setIsFullscreen(!isFullscreen);
    };

    return (
        <div className="header">
            <div className="header-left" >
                <div className="sidebar-logo">
                    <Link to={routes.homeOne}>
                        <ImageWithBasePath
                            src="assets/img/TKN_images/TestingLogo.png"
                            className="img-fluid logo img-margin-left img-height"
                            alt="Logo"
                        />
                    </Link>
                    <Link to={routes.homeOne}>
                        <ImageWithBasePath
                            src="assets/img/logo-small.png"
                            className="img-fluid logo-small"
                            alt="Logo"
                        />
                    </Link>
                </div>
            </div>
            <div className="header-split" style={{ justifyContent: 'flex-end' }}>
                <ul className="nav user-menu noti-pop-detail ">
                    <li className="nav-item  has-arrow dropdown-heads dropdown logged-item noti-nav noti-wrapper">
                        <Link
                            to="#"
                            className="dropdown-toggled nav-link notify-link"
                            data-bs-toggle="dropdown"
                        >
                            <ImageWithBasePath
                                src="assets/img/icons/bell-icon.svg"
                                alt="Bell"
                            />
                        </Link>
                        <div className="dropdown-menu notify-blk notifications" style={{ height: 200 }}>
                            <div className="topnav-dropdown-header">
                                <div>
                                    <p className="notification-title">
                                        Notifications <span> 0 </span>
                                    </p>
                                </div>
                                <Link to="#" className="clear-noti">
                                    <i className="fa-regular fa-circle-check" /> Mark all as read{' '}
                                </Link>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#74788D', marginTop: 8 }}>There are no notifications available.</p>
                            </div>
                            <div className="topnav-dropdown-footer">
                                <Link to={'#'}>
                                    View All Notifications{' '}
                                    <ImageWithBasePath
                                        src="assets/img/icons/arrow-right-01.svg"
                                        alt="Arrow"
                                    />
                                </Link>
                            </div>
                        </div>
                    </li>
                    <li className="nav-item  has-arrow dropdown-heads ">
                        <Link onClick={toggleFullscreen} to="#" className="win-maximize">
                            <i className="feather-maximize">
                                <Icon.Maximize className="react-feather-custom" />
                            </i>
                        </Link>
                    </li>
                    <li className="nav-item dropdown has-arrow account-item">
                        <Link
                            to="#"
                            className="dropdown-toggle nav-link"
                            data-bs-toggle="dropdown"
                        >
                            <div className="user-infos">
                                {user.profilephoto ? (

                                    <span className="user-img">
                                        <ImageWithBasePathWithUrl
                                            src={`${WEB_BASE_URL}${user.profilephoto}`}
                                            className="rounded-circle"
                                            alt="User Image"
                                        />
                                    </span>
                                ) : (

                                    <span className="user-img">
                                        <ImageWithBasePath
                                            src={defaultimg.src}
                                            className="rounded-circle"
                                            alt="User Image"
                                            style={{ objectFit: 'cover' }}
                                        />
                                    </span>)}
                                <div className="user-info">
                                    <h6>{user.first_name}</h6>
                                </div>
                            </div>
                        </Link>
                        <div className="dropdown-menu dropdown-menu-end emp">
                            <Link
                                className="dropdown-item"
                                to={routes.customerEditProfile}
                            >
                                <i className="feather-user me-2" /> Profile
                            </Link>
                            <Link className="dropdown-item" onClick={(e) => { e.preventDefault(); handleLogout(); }} to={''}>
                                <i className="feather-log-out me-2" /> Logout
                            </Link>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default ProviderHeaderTwo;
