import React, { useEffect, useState } from "react";
import './style.css'
import { TKN_DateOfBirthInput, TKN_DropdownInput, TKN_EmailInput, TKN_InputPlaceholder, TKN_PhoneInputComponent } from "../../../components/inputfields/input";
import { DatePicker, TimePicker } from "antd";
import apiService from "../../../../api/authentication-service";
import { useSelector } from "react-redux";
import { format } from 'date-fns';
import { CHAR_LIMIT_EMAIL_WEBSITE, CHAR_LIMIT_FIRST_NAME, CHAR_LIMIT_LAST_NAME } from "../../../../constants/constants";
import { validateDropdown, validateDropdown_RelationShip, validateEmail, validateEmptyValue, validateMobileNumber, validateUsername } from "../../../../helper/validate";
import { CountryDropdown } from "react-country-region-selector";
import { Calendar } from 'primereact/calendar';
import en from "../../../../en/en";
import { useLocation, useNavigate } from "react-router-dom";
import { all_routes } from "../../../../core/data/routes/all_routes";
import { TKN_Danger_message, TKN_Success_message } from "../../../components/alert_messages/alert_messages";
import TKN_modal from "../../../components/modal/modal";
import { ButtonTextOnly_Colored } from "../../../components/buttons/button";
import { Spinner } from "react-bootstrap";

type CountryDetailsType = {
    cca2: string;
    currency: string[];
    callingCode: string[];
    region: string;
    subregion: string;
    flag: string;
    name: string;
};

const AddFamilyMember = () => {
    const [relation, setRelation] = useState([]);
    const [selected, setSelected] = useState("");
    const [selectedRelationship, setSelectedRelationship] = useState('');
    const [relationshipError, setRelationshipError] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [gender, setGender] = useState('');
    const [mobilenumber, setMobileNumber] = useState('');
    const [selectedCountry, setSelectedCountry] = useState<any>(null);
    const [firstnameError, setFirstnameError] = useState('');
    const [lastnameError, setLastnameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [countryError, setCountryError] = useState('');
    const [mobilenumberError, setMobileNumberError] = useState('');
    const [genderDropdownError, setGenderDropdownError] = useState('');
    const [countryCode, setCountryCode] = useState('+1');
    const [countryName, setCountryName] = useState('');
    const [countryDetails, setCountryDetails] = useState<CountryDetailsType | null>(null);
    const [serverError, setServerError] = useState('');
    const [selectedbirthDate, setSelectedbirthDate] = useState<any>(null);
    const [date, setDate] = useState<Date | null | undefined>(null);
    const [responseMessage, setResponseMessage] = useState('');
    const [showmodal, setShowModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const genderoptions = ['Male', 'Female', 'Other']
    const combinedRelation = [...relation];
    const navigateTo = useNavigate();
    const location = useLocation();
    const routes = all_routes;

    useEffect(() => {
        sendRelationshipDataRequest();
    }, []);

    const resetResponseMessage = () => {
        setResponseMessage('');
    };

    const sendRelationshipDataRequest = () => {
        apiService.getAllRelationships().then((response) => {
            const responseData = response?.data;
            console.log('response', responseData);
            setRelation(responseData.data);
        }).catch((error) => {
            console.log('error', error);
        })
    };

    const handleSubmit = () => {

        let isValid = true;
        const MobileNumber = mobilenumber.startsWith(countryCode) ? mobilenumber.slice(countryCode.length) : mobilenumber;
        const isDropdownValid = validateDropdown_RelationShip(selected, 'Any relationship');

        const fieldsToValidate = [
            { value: firstname, errorSetter: setFirstnameError, validationFunction: [validateEmptyValue, validateUsername], fieldName: 'First name' },
            { value: lastname, errorSetter: setLastnameError, validationFunction: [validateEmptyValue, validateUsername], fieldName: 'Last name' },
            // { value: email, errorSetter: setEmailError, validationFunction: [validateEmail], fieldName: 'email' },
            // { value: mobilenumber, errorSetter: setMobileNumberError, validationFunction: [(value: string, fieldName: string) => validateMobileNumber(value, countryCode, fieldName)], fieldName: 'Mobile number' },
            { value: gender, errorSetter: setGenderDropdownError, validationFunction: [validateDropdown], fieldName: 'gender' },
        ];

        if (email.trim() !== '') {
            fieldsToValidate.push({ value: email, errorSetter: setEmailError, validationFunction: [validateEmail], fieldName: 'email' });
        }

        fieldsToValidate.forEach((field => {
            for (const validationFunction of field.validationFunction) {
                const validationResult = validationFunction(field.value, field.fieldName);

                if (validationResult.status === 'false') {
                    field.errorSetter(validationResult.error);
                    isValid = false;
                    break;
                }
            }
        }));

        // const mobileNumberValidateResult = validateMobileNumber(mobilenumber, countryCode, 'Country code and phone number mismatch. Please verify.');
        // if (mobileNumberValidateResult.status === 'false') {

        //     setMobileNumberError(mobileNumberValidateResult.error);
        //     isValid = false;
        // } else {

        //     setMobileNumberError('');
        // };

        const phoneValidation = validateMobileNumber(MobileNumber, countryCode, 'Invalid phone number for the selected country.');
        if (phoneValidation.status === 'false') {
            setMobileNumberError(phoneValidation.error);
            isValid = false;
        } else {
            setMobileNumberError('');
        }

        if (!selectedCountry) {
            setCountryError('Please select a country.');
            isValid = false;
        } else {
            setCountryError('');
        }

        if (isDropdownValid["status"] === "false") {
            setRelationshipError(isDropdownValid["error"]);
            isValid = false;
        }

        if (isValid) {
            sendAddFamilyMemberRequest();
        };
    };

    // const concatenatePhoneNumber = (mobilenumber: string) => {
    //     if (mobilenumber.trim() !== mobilenumber) {
    //         const trimmedMobileNumber = mobilenumber.trim();
    //         return countryCode + trimmedMobileNumber;
    //     } else {
    //         return countryCode + mobilenumber;
    //     }
    // };

    const sendAddFamilyMemberRequest = () => {
        console.log("REQ", AddFamilyMemberRequest());
        apiService.addFamilyMember(AddFamilyMemberRequest())
            .then((response) => {
                console.log('response', response?.data);
                AddFamilyMemberResponse(response?.data);
            })
            .catch((error) => {
                console.log('error', error);
            });
    };

    const AddFamilyMemberResponse = (response: any) => {
        if (response) {
            const status = response?.status;
            if (status && status === true) {

                if (location?.state?.from === routes.bookingAppointment) {

                    navigateTo(routes.bookingAppointment, { state: true });
                } else {

                    const response_message = response?.message;
                    setResponseMessage(response_message)
                    setShowModal(true)
                }
            } else {
                setServerError(response?.message);
            }
        } else {
            setServerError(en.SERVERERROR);
        }
    };

    console.log('EMAIl: ', email.toLowerCase());

    const userId = useSelector((state: any) => {
        console.log('useriddd', state.user.user_id);
        return state.user.user_id
    });


    const AddFamilyMemberRequest = () => {
        const requestData: any = {
            user_id: userId,
            first_name: firstname,
            last_name: lastname,
            relation_name: selectedRelationship,
            gender: gender,
            phone: mobilenumber,
            country: selectedCountry,
            country_code: countryCode,
            country_details: JSON.stringify(countryDetails),
            dob: date ? format(date, 'yyyy-MM-dd') : null
        };

        if (email.trim() !== '') {
            requestData.email = email.toLowerCase();
        }

        return requestData;
    };


    const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        resetResponseMessage();
        const text = event.target.value;

        if (text.length < CHAR_LIMIT_FIRST_NAME) {
            setFirstname(text);
            setFirstnameError('');
        } else {
            setFirstnameError(en.CHAR_LIMIT);
        }
    };

    const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        resetResponseMessage();
        const text = event.target.value;
        if (text.length < CHAR_LIMIT_LAST_NAME) {
            setLastname(text);
            setLastnameError('');

        } else {
            setLastnameError(en.CHAR_LIMIT);
        }
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        resetResponseMessage();
        const text = event.target.value.toLowerCase();
        if (text.length < CHAR_LIMIT_EMAIL_WEBSITE) {
            setEmail(text);
            setEmailError('');
        } else {
            setEmailError(en.CHAR_LIMIT);
        }
    };

    const handleCountryChange = (val: string) => {
        resetResponseMessage();
        if (!val) {
            setCountryError('Unable to select country. Please try again.');
            setSelectedCountry(null);
        } else {
            setCountryError('');
            setSelectedCountry(val);
            console.log('VALUE', val);

        }
    };

    const handlePhonechange = (value: string, formattedDialCode: any, countryname: any, countrydetails: any) => {
        resetResponseMessage();
        if (value) {
            setMobileNumberError('');
        }

        setMobileNumber(value);
        setCountryCode(formattedDialCode);
        setCountryName(countryname);
        setCountryDetails(countrydetails);

        console.log('Phone:', value);
        console.log('CCODE:', countryCode);
        console.log('NAME:', countryName);
        console.log('DETAIILS:', countrydetails);
    };

    const handleCancel = () => {
        navigateTo(routes.customerAddMember)
    }
    const handleServerErrorClose = () => {
        setServerError('')
    }
    return (
        <div className="main-wrapper">
            <div className="page-wrapper">
                <div className="content container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="widget-title">
                                <TKN_modal title={"Family Member"} description={responseMessage} okbutton={"OK"} handleshowModal={showmodal} handleSuccessButton={() => navigateTo(routes.customerAddMember)} closeButton={false} />
                                {serverError && (<TKN_Danger_message text={serverError} onClick={handleServerErrorClose} />)}
                                <h4>Add Member</h4>
                            </div>
                            <h6 className="user-title">General Information</h6>
                            <div className="general-info">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="col-form-label">
                                                First Name <span className="text-danger">*</span>
                                            </label>
                                            <TKN_InputPlaceholder placeholderText={"Enter first name"}
                                                onChange={handleFirstNameChange}
                                                maxLength={CHAR_LIMIT_FIRST_NAME}
                                            >
                                            </TKN_InputPlaceholder>
                                            {firstnameError && (
                                                <div className='errorText'>{firstnameError}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="col-form-label">
                                                Last Name <span className="text-danger">*</span>
                                            </label>
                                            <TKN_InputPlaceholder placeholderText={"Enter last name"}
                                                onChange={handleLastNameChange}
                                                maxLength={CHAR_LIMIT_FIRST_NAME}>
                                            </TKN_InputPlaceholder>
                                            {lastnameError && (
                                                <div className='errorText'>{lastnameError}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <TKN_EmailInput
                                                onChange={handleEmailChange}
                                                maxLength={CHAR_LIMIT_EMAIL_WEBSITE}
                                                value={email}
                                                error={emailError}>
                                            </TKN_EmailInput>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="col-form-label">
                                                Country <span className="text-danger">*</span>
                                            </label>
                                            <CountryDropdown
                                                value={selectedCountry}
                                                onChange={handleCountryChange}
                                                classes="form-control"
                                                defaultOptionLabel="Select Country"
                                                priorityOptions={['United States']}
                                            />
                                            {countryError !== '' && (
                                                <div className='errorText'>{countryError}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <TKN_PhoneInputComponent onChange={handlePhonechange} required={true} error={mobilenumberError} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="col-form-label">
                                                Gender <span className="text-danger">*</span>
                                            </label>
                                            <TKN_DropdownInput placeholderText={"Select your gender"} options={genderoptions} categoryDropdownError={genderDropdownError}
                                                onSelect={(selectedItem) => {
                                                    setGender(selectedItem);
                                                    setGenderDropdownError('');
                                                }}>
                                            </TKN_DropdownInput>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="col-form-label">
                                                Relationship <span className="text-danger">*</span>
                                            </label>
                                            <TKN_DropdownInput placeholderText={"Relationship"}
                                                options={combinedRelation}
                                                categoryDropdownError={relationshipError}
                                                onSelect={(selectedItem: React.SetStateAction<string>) => {
                                                    if (selectedItem === 'other') {
                                                        setSelectedRelationship('other');
                                                        setSelected('other');
                                                    } else {
                                                        setSelectedRelationship(selectedItem);
                                                        setSelected(selectedItem);
                                                    }
                                                    setRelationshipError('');
                                                }}
                                            >
                                            </TKN_DropdownInput>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="col-form-label">Date of birth</label>
                                            <div className="form-icon cus-profile">
                                                <Calendar
                                                    id="dob"
                                                    value={date}
                                                    onChange={(e) => {
                                                        if (e.value) {
                                                          const date = new Date(e.value);
                                                          setDate(date);
                                                        }
                                                      }}
                                                    placeholder="MM/dd/yyyy"
                                                    showIcon
                                                    readOnlyInput={true}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="acc-submit">
                                <ButtonTextOnly_Colored
                                    label={"Cancel"}
                                    fullWidth={false}
                                    style={{
                                        color: '#0445cf',
                                        backgroundColor: '#f8f8f8',
                                        marginRight: '20px'
                                    }}
                                    onClick={handleCancel}
                                />
                                <ButtonTextOnly_Colored
                                    label={isLoading ? <Spinner style={{ width: '1.5rem', height: '1.5rem' }} /> : "Save"}
                                    fullWidth={false}
                                    onClick={handleSubmit}
                                    disabled={isLoading}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddFamilyMember;


