import React, { useState } from 'react';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { TKN_EmailInputwithcheck, TKN_PhoneInputComponent, TKN_TextInputreqired } from '../../../components/inputfields/input';
import './style/bookinglogin.css';
import { Link, useNavigate } from 'react-router-dom';
import * as Icon from 'react-feather';
import { Calendar as PrimeCalendar } from 'primereact/calendar';
import { useDispatch } from 'react-redux';
import apiService from '../../../../api/authentication-service';
import { setGuestDetails, setUserDetails } from '../../../../core/data/redux/action';
import { Nullable } from 'primereact/ts-helpers';
import { validateMobileNumber } from '../../../../helper/mobileNumberValidatorHelper';
import en from '../../../../en/en';
import { Spinner } from 'react-bootstrap';
import { FormErrors } from '../../../../core/models/interface';
import TKN_modal from '../../../components/modal/modal';

const BookingLogin = () => {
    const routes = all_routes;
    const dispatch = useDispatch();
    const navigateTo = useNavigate();
    const [email, setEmail] = useState<string>('');
    const [guestEmail, setGuestEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
    const [errors, setErrors] = useState<FormErrors>({});
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [gender, setGender] = useState<string>('');
    const [countryCode, setCountryCode] = useState<string>('');
    const [phone, setPhone] = useState('');
    const [countryName, setCountryName] = useState<string>('');
    const [countryDetails, setCountryDetails] = useState<string>('');
    const [birthDate, setBirthDate] = useState<Nullable<Date>>(null);
    const [isLoginLoading, setIsLoginLoading] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const guestValidateForm = (): boolean => {
        const newErrors: FormErrors = {};

        if (!guestEmail) {
            newErrors.guestEmail = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(guestEmail)) {
            newErrors.guestEmail = 'Email is invalid';
        }

        if (!firstName) {
            newErrors.firstName = 'First name is required';
        }

        if (!lastName) {
            newErrors.lastName = 'Last name is required';
        }

        if (!phone) {
            newErrors.phone = 'Phone number is required';
        }
        else if (!validateMobileNumber(countryName, phone)) {
            newErrors.phone = 'Invalid phone number for the selected country';
        }

        if (!gender) {
            newErrors.gender = 'Gender is required';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const validateForm = (): boolean => {

        const newErrors: FormErrors = {};

        if (!email) {

            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(email)) {

            newErrors.email = 'Email is invalid';
        }

        if (!password) {

            newErrors.password = 'Password is required';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleLoginClick = async (e: any) => {

        e.preventDefault();
        if (validateForm()) {
            setIsLoginLoading(true);
            sendLoginRequest();
        }
    };

    const sendLoginRequest = () => {

        const payload = loginRequest();
        apiService.login(payload)
            .then((response: any) => {
                setIsLoginLoading(false);
                getLoginResponse(response?.data);
            })
            .catch((error: any) => {

                console.error('Error fetching User details', error);
                setErrors({ general: 'Failed to fetch user details' });
            });
    };

    const loginRequest = () => ({

        email: email.toLowerCase(),
        password: password,
        userrole: 2
    });

    const getLoginResponse = (response: any) => {

        if (response) {
            const status = response?.status;

            if (status === true) {
                const responseData = response?.data;
                const responseUser = responseData.user_details;
                const otpverified = responseData.user_details.otp_verified;
                const ProfileVerified = responseData.user_details.profile_verified;

                if (responseData) {

                    if (ProfileVerified == 1 && otpverified == 1) {

                        if (responseUser) {

                            dispatch(setUserDetails(responseData.user_details));
                            navigateTo(routes.bookingAppointment, { replace: true });
                        } else {

                            console.error('No Customer');
                            setErrors({ general: "Invalid User" });
                        }
                    } else {

                        console.error('Registration incomplete');
                        setShowModal(true);
                    }
                } else {

                    console.error('Login failed:', responseData.error);
                    setErrors(responseData.error);
                }
            } else {

                console.error('ERROR MSG:', response?.message);
                setErrors({ general: "Invalid Username or Password" });
            }
        } else {

            console.error('ERROR MSG:', response?.message);
            setErrors({ general: response?.message });
        }
    };

    const handleGuestSubmit = (e: any) => {
        e.preventDefault();

        if (guestValidateForm()) {

            const guestDetails = {

                user: "guest",
                lastName: lastName,
                firstName: firstName,
                email: guestEmail,
                phone: phone,
                gender: gender,
                country: countryName,
                country_code: countryCode,
                country_details: countryDetails,
                dob: birthDate
            };

            if (guestDetails) {
                dispatch(setGuestDetails(guestDetails));
                setTimeout(() => {

                    navigateTo(routes.bookingAppointment, { replace: true });
                }, 0);
            }
        }
    };

    const handlePhonechange = (value: any, formattedDialCode: any, countryname: any, countrydetails: any) => {

        setPhone(value);
        setCountryCode(formattedDialCode);
        setCountryName(countryname);
        setCountryDetails(countrydetails);
    };

    const handleKeyDown = (event: any) => {

        if (event.key === 'Enter') {

            handleLoginClick;
        }
    };

    const handleGuestKeyDown = (event: any) => {

        if (event.key === 'Enter') {

            handleGuestSubmit;
        }
    };

    return (
        <div className="container d-flex justify-content-center align-items-center" style={{ minHeight: "100vh", padding: "20px" }}>
            <div className="col-lg-7 col-md-10 col-sm-12" style={{ height: "80vh", maxWidth: "100%" }}>
                <div className="main-booking-form d-flex flex-column w-100">
                    <div className="rechedule-calender w-100">
                        <div className="book-title">
                            <h5>Enter Information</h5>
                        </div>
                        <div className="card booking-info-tab w-100">
                            <ul className="nav nav-pills justify-content-center" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation" style={{ flex: 1 }}>
                                    <button className="nav-link active" id="pills-guest-tab" data-bs-toggle="pill" data-bs-target="#pills-guest"
                                        type="button" role="tab" aria-controls="pills-guest" aria-selected="true">Book as Guest</button>
                                </li>
                                <li className="nav-item" role="presentation" style={{ flex: 1 }}>
                                    <button className="nav-link" id="pills-user-tab" data-bs-toggle="pill" data-bs-target="#pills-user"
                                        type="button" role="tab" aria-controls="pills-user" aria-selected="false">Already have an
                                        account?</button>
                                </li>
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-guest" role="tabpanel" aria-labelledby="pills-guest-tab">
                                    <form>
                                        <div className="row">
                                            <div className="col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <label className="col-form-label">
                                                        First Name <span className="text-danger">*</span>
                                                    </label>
                                                    <TKN_TextInputreqired
                                                        placeholder='First name'
                                                        maxLength={100}
                                                        onChange={(value: string) => setFirstName(value)}
                                                    />
                                                    {errors.firstName && <div className="error" style={{ color: 'red' }}>{errors.firstName}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <label className="col-form-label">
                                                        Last Name <span className="text-danger">*</span>
                                                    </label>
                                                    <TKN_TextInputreqired
                                                        placeholder='Last name'
                                                        maxLength={100}
                                                        onChange={(value: string) => setLastName(value)}
                                                    />{errors.lastName && <div className="error" style={{ color: 'red' }}>{errors.lastName}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="col-form-label">
                                                        Email <span className="text-danger">*</span>
                                                    </label>
                                                    <TKN_EmailInputwithcheck label='' onChange={(value: string) => setGuestEmail(value)} />
                                                    {errors.guestEmail && <div className="error" style={{ color: 'red' }}>{errors.guestEmail}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group" style={{ display: "flex", justifyContent: "left" }}>
                                                    <div style={{ maxWidth: "100%" }}>
                                                        <TKN_PhoneInputComponent onChange={handlePhonechange} required={true} />
                                                        {errors.phone && <div className="error" style={{ color: 'red' }}>{errors.phone}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="col-form-label">
                                                        Gender <span className="text-danger">*</span>
                                                    </label>
                                                    <select className="form-control mb-2" value={gender} onChange={(e) => setGender(e.target.value)}>
                                                        <option value="">Select gender</option>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                    </select>
                                                    {errors.gender && <div className="error" style={{ color: 'red' }}>{errors.gender}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="col-form-label">
                                                        Date of Birth
                                                    </label>
                                                    <div className='calendar-container p-0'>
                                                        <PrimeCalendar
                                                            value={birthDate}
                                                            onChange={(e) => setBirthDate(e.value)}
                                                            placeholder="DD/MM/YYYY"
                                                            showIcon
                                                            inputClassName="p-inputtext"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="field-bottom-btns select-timing mt-3">
                                                <div className="field-btns w-100">
                                                    <button
                                                        className="btn btn-primary w-100 login-btn backgroundcolor-new"
                                                        type="submit"
                                                        onKeyDown={handleGuestKeyDown}
                                                        onClick={handleGuestSubmit}
                                                    >
                                                        {en.SUBMIT}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="tab-pane fade guest-user-tab" id="pills-user" role="tabpanel" aria-labelledby="pills-user-tab">
                                    {errors.general && <div className="error">{errors.general}</div>}
                                    <div className="sub-title">
                                        <h5>Login</h5>
                                    </div>
                                    <div className="row">
                                        <form>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Email"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                    />
                                                    {errors.email && <div className="error" style={{ color: 'red' }}>{errors.email}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <div className='position-relative'>
                                                        <input
                                                            type={passwordVisible ? 'text' : 'password'}
                                                            className="form-control pass-input"
                                                            placeholder="*************"
                                                            value={password}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                        />
                                                        <span
                                                            className="toggle-password"
                                                            onClick={togglePasswordVisibility}
                                                        >
                                                            {passwordVisible ? <Icon.EyeOff className="react-feather-custom" /> : <Icon.Eye className="react-feather-custom" />}
                                                        </span>
                                                    </div>
                                                    {errors.password && <div className="error" style={{ color: 'red' }}>{errors.password}</div>}
                                                </div>
                                                <div className="get-new-password">
                                                    <Link
                                                        className="forgot-link color-new"
                                                        to={routes.passwordRecovery}
                                                    >
                                                        Forgot password?
                                                    </Link>
                                                </div>
                                                <div className="form-group" style={{ marginTop: "50px" }}>
                                                    <button
                                                        className="btn btn-primary w-100 login-btn"
                                                        type="submit"
                                                        onKeyDown={handleKeyDown}
                                                        onClick={handleLoginClick}
                                                        disabled={isLoginLoading}
                                                    >
                                                        {isLoginLoading ? <Spinner style={{ width: '1.5rem', height: '1.5rem' }} /> : en.LOGIN}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            {showModal && (
                <TKN_modal
                    title={en.REGISTRATION_INCOMPLETE}
                    description={en.ACCOUNT_REGISTRATION_INCOMPLETE_BOOKINGLOGIN}
                    okbutton={en.CONTINUE}
                    handleshowModal={showModal}
                    handleSuccessButton={() => navigateTo(routes.login, { state: { email, from: location.pathname } })}
                    cancelbutton={en.CANCEL}
                    handleCancelButton={() => setShowModal(false)}
                />
            )}
        </div >
    );

};

export default BookingLogin;