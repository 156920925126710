import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ImageWithBasePath } from '../../../../core/img/ImageWithBasePath';
import PagesAuthHeader from './common/header';
import { all_routes } from '../../../../core/data/routes/all_routes';
import './style/bookinglogin.css';
import { WEB_USER_ROLE_CUSTOMER, WEB_USER_ROLE_VENDOR } from '../../../../constants/constants';
import { useDispatch } from 'react-redux';
import { setRole } from '../../../../core/data/redux/action';

const ChooseSignup = () => {
  const [activeButton, setActiveButton] = useState<string | null>(null);
  const [activeUser, setActiveUser] = useState<number | null>(null);
  const dispatch = useDispatch();
  const routes = all_routes;
  const navigateTo = useNavigate();

  const handleClick = (buttonId: string) => {

    if (activeButton === buttonId) {
      setActiveButton(null);
    } else {
      setActiveButton(buttonId);
    }
    if (buttonId === 'button2') {
      setActiveUser(WEB_USER_ROLE_CUSTOMER);
    } else {
      setActiveUser(WEB_USER_ROLE_VENDOR);
    }
  };

  const handleLoginClick = async (e: any) => {
    e.preventDefault();  
    if (activeUser == 2) {
      dispatch(setRole(WEB_USER_ROLE_CUSTOMER));
      navigateTo(routes.userSignup);
    } else {
      dispatch(setRole(WEB_USER_ROLE_VENDOR));
      navigateTo(routes.vendorSignup);
    }

  };

  useEffect(() => {
    if (activeButton === 'button2') {
      setActiveUser(WEB_USER_ROLE_CUSTOMER);
    } else if (activeButton) {
      setActiveUser(WEB_USER_ROLE_VENDOR);
    }
  }, [activeButton]);

  return (
    <>
      <PagesAuthHeader />
      <div className="content">
        <div className="container">
          <div className="row">
            {/* Choose Signup */}
            <div className="col-md-6 col-lg-6 mx-auto">
              <div className="login-wrap">
                <div className="login-header">
                  <h3>Sign Up</h3>
                  <p>Signup as</p>
                </div>
                <div className="row mb-4">
                  {/* Provider Signup */}
                  <div className="col-md-6 d-flex">
                    <div className="choose-signup flex-fill transition-new-style" style={{ outline: activeButton === 'button2' ? '2px solid #0445CF' : 'none' }} onClick={() => { handleClick('button2'); }}>
                      <h6>Consumer</h6>
                      <div className="choose-img">
                        <ImageWithBasePath src="assets/img/user.png" alt="image" />
                      </div>
                      {/* <Link
                        to={routes.userSignup}
                        className="btn btn-secondary w-100"
                      >
                        Sign Up
                        <Icon.ArrowRightCircle className="standard-feather ms-1" />
                      </Link> */}
                    </div>
                  </div>
                  {/* /Provider Signup */}
                  {/* User Signup */}
                  <div className="col-md-6 d-flex" >
                    <div className="choose-signup flex-fill mb-0 transition-new-style" style={{ outline: activeButton === 'button1' ? '2px solid #0445CF' : 'none' }} onClick={() => { handleClick('button1'); }}>
                      <h6>Vendor</h6>
                      <div className="choose-img">
                        <ImageWithBasePath src="assets/img/vendor.png" alt="image" />
                      </div>
                      {/* <Link
                        to={routes.vendorSignup}
                        className="btn btn-secondary w-100"
                      >
                        Sign Up
                        <Icon.ArrowRightCircle className="standard-feather ms-1" />
                      </Link> */}
                    </div>
                  </div>



                  {/* /User Signup */}
                </div>
                {/* ================================================================== */}
                {(activeButton === 'button1' || activeButton === 'button2') && (<form   /* action={routes.customerDashboard} */>


                  <div className="log-form paddingbt-10-new">
                    <div className="vendor-signup flex-fill" >
                      <Link to=""
                        className="btn btn-primary w-100 login-btn backgroundcolor-new"
                        type="submit" onClick={handleLoginClick} >Continue </Link>
                    </div></div>

                </form>)}

                <p className="no-acc margint-0-new">
                  Already have an account?{' '}
                  <Link to={routes.login} className='color-new'>Sign In</Link>
                </p>

                {/* ================================================================ */}
              </div>
            </div>
            {/* /Choose Signup */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChooseSignup;
