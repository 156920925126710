import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ButtonTextOnly_Colored } from '../buttons/button';
import { ImageWithBasePathWithUrl } from '../../../core/img/ImageWithBasePath';
import { IconWithDate, IconWithText } from './iconwithtext';
import { customerBookingProps } from '../../../core/models/interface';
import './customerBookingCardStyle.css';
import en from '../../../en/en';
import { apiClient } from '../../../api/apiClient';
import apiService from '../../../api/authentication-service';
import TKN_modal from '../modal/modal';

const CustomerBookingCard: React.FC<customerBookingProps> = (Props) => {
    const [showmodal, setshowmodal] = useState(false)
    const sendGetCancelUserBookings = () => {
        apiService.cancelUserBookings({ user_id: Props.user_id, booking_id: Props.bookingid }).then((response) => {
            const responseData = response?.data;
            if (responseData) {
                const status = responseData?.status;
                if (status) {
                    window.location.reload()
                    setshowmodal(false)
                }
            }
        }).catch((error) => {
            console.log('error =', error)
        });
    };



    const handlecancelbutton = () => {
        setshowmodal(true)
    }
    const handleOkbutton = () => {
        setshowmodal(false)
        sendGetCancelUserBookings()
    }
    const handleCloseButton = () => {
        setshowmodal(false)
    }
    return (
        <div className="booking-details-card d-flex">
            <div className="booking-img-section">
                <div className="d-flex ps-3 py-3 h-100 img-link-container">
                    <ImageWithBasePathWithUrl
                        src={Props.Imgsrc}
                        alt="Service Booking Image"
                        className="booking-img"
                    />
                </div>
            </div>
            <div className="booking-info d-flex flex-column flex-shrink-1 padding-booking-info ">
                <div className="status-container d-flex w-97">
                    <h1 className="flex-grow-1 mb-0 heading-personname-customer ">{Props.ServiceName}</h1>
                    <div className="provider-card5-customer">
                        <p className={`provider-card6 ${Props.statusClassName}`}>{Props.statusText}</p>
                    </div>
                </div>
                <div className='mb-0 align-self-space-between'>
                    <p className='booking-details-style'>Booking Details:</p>
                    <IconWithText iconclassName={'fa-solid fa-mobile'} label1={Props.BusinessServiceName} />
                    <IconWithText iconclassName={'fa-solid fa-user'} label1={`${Props.Bookedfor} | ${Props?.tokennumber?.substring(4)}`} />
                    <IconWithDate iconclassName={'fa-solid fa-calendar-days'} label1={Props.Date} label2={Props.Time} />
                </div>
                <div className="cancel-container">
                    {Props.statusText === 'No Show' || Props.statusText === 'Checked Out' || Props.statusText === 'Cancelled' ? ('') :
                        (<ButtonTextOnly_Colored label={en.CANCEL} fullWidth={false} style={{ backgroundColor: '#f8f8f8', color: '#0445cf', borderColor: '#f8f8f8', minWidth: '124px', justifyContent: 'center', height: '36px' }} onClick={handlecancelbutton} />)}
                </div>
            </div>
            <div className='person-walking-icon-customer'>
                <i className={Props.iconclassname} ></i>
                {Props.svgImage && Props.svgImage}
            </div>
            <TKN_modal title={'Cancel Booking'} description={"Are you sure you want to cancel booking?"} okbutton={'OK'} handleshowModal={showmodal} cancelbutton={'Cancel'} closeButton={false} handleCancelButton={handleCloseButton} handleSuccessButton={handleOkbutton} />
        </div >

    )
}

export { CustomerBookingCard };